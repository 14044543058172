
:root{
  /*공통*/
  --main_color: #315ae9;
  --deep_color: #2d3a58;
  --white: #ffffff;
  --gray10: #FAFAFC;
  --gray20: #F4F6FA;
  --gray30: #EAEDF4;
  --gray40: #D7DCE5;
  --gray50: #B4C0D3;
  --gray60: #96A0B5;
  --gray70: #8491A7;
  --gray90: #5C667B;
  --gray100: #475067;
  --gray110: #373F57;
  --gray120: #292E41;
  --blue10: #F7FAFE;
  --blue20: #F3F7FF;
  --blue30: #E9F3FF;
  --blue40: #D1E6FF;
  --blue80: #4876EF;
  --blue100: #315AE9;
  --red20: #FFF5F7;
  --red40: #FFD1D1;
  --red90: #FF5656;
  --bg01: #FAFAFC;
}

.loader {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  position: relative;
  animation: loader-uba7e1836 0.9s ease alternate infinite;
  animation-delay: 0.36s;
  top: 50%;
  margin:0 auto; }
.loader::after, .loader::before {
  content: '';
  position: absolute;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  animation: loader-uba7e1836 0.9s ease alternate infinite; }
.loader::before {
  left: -20px;
  animation-delay: 0.18s; }
.loader::after {
  right: -20px;
  animation-delay: 0.54s; }

@keyframes loader-uba7e1836 {
  0% {
    box-shadow: 0 14px 0 -14px #0052ec;
    opacity: 0;
  }
  100% {
    box-shadow: 0 14px 0 #0052ec;
    opacity: 1;
  } }

/*margin*/
.mgl0{ margin-left: 0;}
.mgl1{ margin-left: 1px;}
.mgl2{ margin-left: 2px;}
.mgl3{ margin-left: 3px;}
.mgl4{ margin-left: 4px;}
.mgl5{ margin-left: 5px;}
.mgl6{ margin-left: 6px;}
.mgl7{ margin-left: 7px;}
.mgl8{ margin-left: 8px;}
.mgl9{ margin-left: 9px;}
.mgl10{ margin-left: 10px;}
.mgl11{ margin-left: 11px;}
.mgl12{ margin-left: 12px;}
.mgl13{ margin-left: 13px;}
.mgl14{ margin-left: 14px;}
.mgl15{ margin-left: 15px;}
.mgl16{ margin-left: 16px;}
.mgl17{ margin-left: 17px;}
.mgl18{ margin-left: 18px;}
.mgl19{ margin-left: 19px;}
.mgl20{ margin-left: 20px;}
.mgl25{ margin-left: 25px;}
.mgl30{ margin-left: 30px;}
.mgl35{ margin-left: 35px;}
.mgl40{ margin-left: 40px;}
.mgl45{ margin-left: 55px;}
.mgl50{ margin-left: 50px;}
.mgl55{ margin-left: 55px;}
.mgl60{ margin-left: 60px;}

.mgr0{ margin-right: 0;}
.mgr1{ margin-right: 1px;}
.mgr2{ margin-right: 2px;}
.mgr3{ margin-right: 3px;}
.mgr4{ margin-right: 4px;}
.mgr5{ margin-right: 5px;}
.mgr6{ margin-right: 6px;}
.mgr7{ margin-right: 7px;}
.mgr8{ margin-right: 8px;}
.mgr9{ margin-right: 9px;}
.mgr10{ margin-right: 10px;}
.mgr11{ margin-right: 11px;}
.mgr12{ margin-right: 12px;}
.mgr13{ margin-right: 13px;}
.mgr14{ margin-right: 14px;}
.mgr15{ margin-right: 15px;}
.mgr16{ margin-right: 16px;}
.mgr17{ margin-right: 17px;}
.mgr18{ margin-right: 18px;}
.mgr19{ margin-right: 19px;}
.mgr20{ margin-right: 20px;}
.mgr25{ margin-right: 25px;}
.mgr30{ margin-right: 30px;}
.mgr35{ margin-right: 35px;}
.mgr40{ margin-right: 40px;}
.mgr45{ margin-right: 55px;}
.mgr50{ margin-right: 50px;}
.mgr55{ margin-right: 55px;}
.mgr60{ margin-right: 60px;}

.mgt0{ margin-top: 0;}
.mgt1{ margin-top: 1px;}
.mgt2{ margin-top: 2px;}
.mgt3{ margin-top: 3px;}
.mgt4{ margin-top: 4px;}
.mgt5{ margin-top: 5px;}
.mgt6{ margin-top: 6px;}
.mgt7{ margin-top: 7px;}
.mgt8{ margin-top: 8px;}
.mgt9{ margin-top: 9px;}
.mgt10{ margin-top: 10px;}
.mgt11{ margin-top: 11px;}
.mgt12{ margin-top: 12px;}
.mgt13{ margin-top: 13px;}
.mgt14{ margin-top: 14px;}
.mgt15{ margin-top: 15px;}
.mgt16{ margin-top: 16px;}
.mgt17{ margin-top: 17px;}
.mgt18{ margin-top: 18px;}
.mgt19{ margin-top: 19px;}
.mgt20{ margin-top: 20px;}
.mgt25{ margin-top: 25px;}
.mgt30{ margin-top: 30px;}
.mgt35{ margin-top: 35px;}
.mgt40{ margin-top: 40px;}
.mgt45{ margin-top: 55px;}
.mgt50{ margin-top: 50px;}
.mgt55{ margin-top: 55px;}
.mgt60{ margin-top: 60px;}

.mgb0{ margin-bottom: 0;}
.mgb1{ margin-bottom: 1px;}
.mgb2{ margin-bottom: 2px;}
.mgb3{ margin-bottom: 3px;}
.mgb4{ margin-bottom: 4px;}
.mgb5{ margin-bottom: 5px;}
.mgb6{ margin-bottom: 6px;}
.mgb7{ margin-bottom: 7px;}
.mgb8{ margin-bottom: 8px;}
.mgb9{ margin-bottom: 9px;}
.mgb10{ margin-bottom: 10px;}
.mgb11{ margin-bottom: 11px;}
.mgb12{ margin-bottom: 12px;}
.mgb13{ margin-bottom: 13px;}
.mgb14{ margin-bottom: 14px;}
.mgb15{ margin-bottom: 15px;}
.mgb16{ margin-bottom: 16px;}
.mgb17{ margin-bottom: 17px;}
.mgb18{ margin-bottom: 18px;}
.mgb19{ margin-bottom: 19px;}
.mgb20{ margin-bottom: 20px;}
.mgb25{ margin-bottom: 25px;}
.mgb30{ margin-bottom: 30px;}
.mgb35{ margin-bottom: 35px;}
.mgb40{ margin-bottom: 40px;}
.mgb45{ margin-bottom: 55px;}
.mgb50{ margin-bottom: 50px;}
.mgb55{ margin-bottom: 55px;}
.mgb60{ margin-bottom: 60px;}

/*padding*/
.pdl0{ padding-left: 0;}
.pdl1{ padding-left: 1px;}
.pdl2{ padding-left: 2px;}
.pdl3{ padding-left: 3px;}
.pdl4{ padding-left: 4px;}
.pdl5{ padding-left: 5px;}
.pdl6{ padding-left: 6px;}
.pdl7{ padding-left: 7px;}
.pdl8{ padding-left: 8px;}
.pdl9{ padding-left: 9px;}
.pdl10{ padding-left: 10px;}
.pdl11{ padding-left: 11px;}
.pdl12{ padding-left: 12px;}
.pdl13{ padding-left: 13px;}
.pdl14{ padding-left: 14px;}
.pdl15{ padding-left: 15px;}
.pdl16{ padding-left: 16px;}
.pdl17{ padding-left: 17px;}
.pdl18{ padding-left: 18px;}
.pdl19{ padding-left: 19px;}
.pdl20{ padding-left: 20px;}
.pdl25{ padding-left: 25px;}
.pdl30{ padding-left: 30px;}
.pdl35{ padding-left: 35px;}
.pdl40{ padding-left: 40px;}
.pdl45{ padding-left: 55px;}
.pdl50{ padding-left: 50px;}
.pdl55{ padding-left: 55px;}
.pdl60{ padding-left: 60px;}

.pdr0{ padding-right: 0;}
.pdr1{ padding-right: 1px;}
.pdr2{ padding-right: 2px;}
.pdr3{ padding-right: 3px;}
.pdr4{ padding-right: 4px;}
.pdr5{ padding-right: 5px;}
.pdr6{ padding-right: 6px;}
.pdr7{ padding-right: 7px;}
.pdr8{ padding-right: 8px;}
.pdr9{ padding-right: 9px;}
.pdr10{ padding-right: 10px;}
.pdr11{ padding-right: 11px;}
.pdr12{ padding-right: 12px;}
.pdr13{ padding-right: 13px;}
.pdr14{ padding-right: 14px;}
.pdr15{ padding-right: 15px;}
.pdr16{ padding-right: 16px;}
.pdr17{ padding-right: 17px;}
.pdr18{ padding-right: 18px;}
.pdr19{ padding-right: 19px;}
.pdr20{ padding-right: 20px;}
.pdr25{ padding-right: 25px;}
.pdr30{ padding-right: 30px;}
.pdr35{ padding-right: 35px;}
.pdr40{ padding-right: 40px;}
.pdr45{ padding-right: 55px;}
.pdr50{ padding-right: 50px;}
.pdr55{ padding-right: 55px;}
.pdr60{ padding-right: 60px;}

.pdt0{ padding-top: 0;}
.pdt1{ padding-top: 1px;}
.pdt2{ padding-top: 2px;}
.pdt3{ padding-top: 3px;}
.pdt4{ padding-top: 4px;}
.pdt5{ padding-top: 5px;}
.pdt6{ padding-top: 6px;}
.pdt7{ padding-top: 7px;}
.pdt8{ padding-top: 8px;}
.pdt9{ padding-top: 9px;}
.pdt10{ padding-top: 10px;}
.pdt11{ padding-top: 11px;}
.pdt12{ padding-top: 12px;}
.pdt13{ padding-top: 13px;}
.pdt14{ padding-top: 14px;}
.pdt15{ padding-top: 15px;}
.pdt16{ padding-top: 16px;}
.pdt17{ padding-top: 17px;}
.pdt18{ padding-top: 18px;}
.pdt19{ padding-top: 19px;}
.pdt20{ padding-top: 20px;}
.pdt25{ padding-top: 25px;}
.pdt30{ padding-top: 30px;}
.pdt35{ padding-top: 35px;}
.pdt40{ padding-top: 40px;}
.pdt45{ padding-top: 55px;}
.pdt50{ padding-top: 50px;}
.pdt55{ padding-top: 55px;}
.pdt60{ padding-top: 60px;}

.pdb0{ padding-bottom: 0;}
.pdb1{ padding-bottom: 1px;}
.pdb2{ padding-bottom: 2px;}
.pdb3{ padding-bottom: 3px;}
.pdb4{ padding-bottom: 4px;}
.pdb5{ padding-bottom: 5px;}
.pdb6{ padding-bottom: 6px;}
.pdb7{ padding-bottom: 7px;}
.pdb8{ padding-bottom: 8px;}
.pdb9{ padding-bottom: 9px;}
.pdb10{ padding-bottom: 10px;}
.pdb11{ padding-bottom: 11px;}
.pdb12{ padding-bottom: 12px;}
.pdb13{ padding-bottom: 13px;}
.pdb14{ padding-bottom: 14px;}
.pdb15{ padding-bottom: 15px;}
.pdb16{ padding-bottom: 16px;}
.pdb17{ padding-bottom: 17px;}
.pdb18{ padding-bottom: 18px;}
.pdb19{ padding-bottom: 19px;}
.pdb20{ padding-bottom: 20px;}
.pdb25{ padding-bottom: 25px;}
.pdb30{ padding-bottom: 30px;}
.pdb35{ padding-bottom: 35px;}
.pdb40{ padding-bottom: 40px;}
.pdb45{ padding-bottom: 55px;}
.pdb50{ padding-bottom: 50px;}
.pdb55{ padding-bottom: 55px;}
.pdb60{ padding-bottom: 60px;}

/*text-align*/
.tgnl{ text-align: left;}
.tgnc{ text-align: center;}
.tgnr{ text-align: right;}












