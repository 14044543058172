.ant-pagination .ant-pagination-item{font-family: 'Pretendard',sans-serif; font-size: 16px; font-weight: 600; color: var(--gray70); border-radius: 8px !important; width:36px !important; height: 36px !important; line-height: 36px;}
.ant-pagination .ant-pagination-item a{color: var(--gray70); padding: 0 !important; line-height: 36px !important;}
.ant-pagination .ant-pagination-item-active{ font-weight: 600; border-color: var(--gray30);}
.ant-pagination .ant-pagination-item-active a{ color: var(--gray100);}
.ant-pagination .ant-pagination-item-active:hover{ border-color: var(--gray30) !important;}
.ant-pagination .ant-pagination-item-active:hover a{ color: var(--gray100) !important;}
.ant-pagination .ant-pagination-next{ border-radius: 8px !important; width:36px !important; height: 36px !important; line-height: 36px;}
.ant-pagination .ant-pagination-prev{ border-radius: 8px !important; width:36px !important; height: 36px !important; line-height: 36px;}
.ant-pagination .ant-pagination-mini.ant-pagination-item{ line-height: 36px !important;}
:where(.css-d2lrxs).ant-pagination.ant-pagination-mini .ant-pagination-jump-prev, :where(.css-d2lrxs).ant-pagination.ant-pagination-mini .ant-pagination-jump-next{ line-height: 36px !important;}

.reportPicker{ width:110px;}
@media(max-width:480px) {
    .reportPicker{ width:100%;}
    .ant-picker-dropdown{ left: 20px !important; width:100% !important; min-width: 100% !important;}
    :where(.css-dev-only-do-not-override-d2lrxs).ant-pagination.ant-pagination-mini .ant-pagination-item{line-height: 36px !important;}
    :where(.css-dev-only-do-not-override-d2lrxs).ant-pagination.ant-pagination-mini .ant-pagination-jump-prev, :where(.css-dev-only-do-not-override-d2lrxs).ant-pagination.ant-pagination-mini .ant-pagination-jump-next{line-height: 36px !important;}
}
.reportPicker input{font-family: 'Pretendard',sans-serif; font-size: 14px; font-weight: 600;}
.reportPicker input::placeholder{font-family: 'Pretendard',sans-serif; font-size: 14px; font-weight: 600;}
.reportPicker{ padding: 8px 8px 8px 8px !important; border: solid 0 var(--gray40); outline: none; box-shadow: none !important; background-color:transparent !important;}
.ant-picker-disabled{ background-color:transparent !important; border: solid 0 var(--gray40) !important; outline: none; box-shadow: none !important;}

.ant-picker-dropdown{ font-family: 'Pretendard',sans-serif; }
.ant-picker-dropdown button{font-family: 'Pretendard',sans-serif; }
.ant-picker-content tr th,.ant-picker-content tr td{ font-size: 12px;}
.ant-picker-content tr td:nth-child(7n+1) { color: var(--red90) !important;}
.ant-picker-content tr th:nth-child(7n+1) { color: var(--red90) !important;}
.ant-picker-content .ant-picker-cell-today .ant-picker-cell-inner{ border: 0 !important; background-color: var(--blue30); color: var(--blue100);}
.ant-picker-content .ant-picker-cell-today .ant-picker-cell-inner::before{ display: none;}
.ant-picker-dropdown .ant-picker-header-super-prev-btn{ display: none !important;}
.ant-picker-dropdown .ant-picker-header-super-next-btn{ display: none !important;}

div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm:focus {
    box-shadow: 0 0 0 0 rgba(65,90,147,0);
}
div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
    background-color: #2d3a58;
}
div:where(.swal2-container) {
    z-index: 5000;
}
.swal2-container button{ font-family: 'Pretendard',sans-serif;}

.chartTooltip1{ position: relative; background-color: var(--gray120); color: #fff; padding: 12px; border-radius: 12px; min-width: 100px;}
.chartTooltip1 ul:nth-child(1){ font-weight: 600; line-height: 21px; margin-bottom: 4px;}
.chartTooltip1 ul:nth-child(2){ text-align: right; font-size: 12px; color: var(--gray40); line-height: 16px;}
.chartTooltip1 .botArw { position: absolute; bottom: -7px; left: 50%; transform: translateX(-50%); width: 0; height: 0; border-left: 7px solid transparent; border-right: 7px solid transparent; border-top: 7px solid var(--gray120); }














