@charset "utf-8";

@font-face {
  font-family: 'Pretendard';
  font-weight: 200;
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-ExtraLight.woff') format('woff');
}
@font-face {
  font-family: 'Pretendard';
  font-weight: 400;
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
}
@font-face {
  font-family: 'Pretendard';
  font-weight: 500;
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Medium.woff') format('woff');
}
@font-face {
  font-family: 'Pretendard';
  font-weight: 600;
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Medium.woff') format('woff');
}
@font-face {
  font-family: 'Pretendard';
  font-weight: 700;
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-SemiBold.woff') format('woff');
}
@font-face {
  font-family: 'Pretendard';
  font-weight: 800;
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-ExtraBold.woff') format('woff');
}
@font-face {
  font-family: 'Pretendard';
  font-weight: 900;
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Black.woff') format('woff');
}

*{
  margin: 0;
  padding: 0;
}

body{
  font-family: 'Pretendard',sans-serif;
  font-size: 14px;
  color:var(--gray120);
  background-color: #ffffff;
  font-weight: 400;
}
.notScroll {overflow: hidden;width: 100%;height: 100vh;touch-action:none;}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); /* 하이라이트 효과를 제거 */
}

label{font-family: 'Pretendard',sans-serif !important; font-size: 15px !important; color: var(--gray90) !important;}
html::-webkit-scrollbar {width: 5px;}
html::-webkit-scrollbar-track {background: #eceff3;}
html::-webkit-scrollbar-thumb {background: #a1acbb;}
html::-webkit-scrollbar-thumb:hover {background: #7e94b4;}

body,ul,dl,ol,li,dl,dt{margin:0; padding:0; list-style-type:none;}
a{ text-decoration:none; color:var(--gray120);}
.fend{clear:both;}
img{ vertical-align:top; border:0; -webkit-user-drag: none; -moz-user-select: none; -ms-user-select: none; user-select: none;}
input,select{font-family: 'Pretendard',sans-serif; color:var(--gray120); outline: none;}
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.loadingLayer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 900;
  animation: showLoading 0s 0.5s forwards;
  opacity: 0;
}
.loadingLayer .spinner {
  position: absolute;
  top: calc(50% - 28px);
  left: calc(50% - 28px);
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background: radial-gradient(farthest-side, #206ed4 95%, #0000) 50% 1px/13px 13px no-repeat,
  radial-gradient(farthest-side, #0000 calc(100% - 16px), rgba(32, 110, 212, 0.1) 0);
  animation: spinner-aur408 0.7s infinite linear 0.5s; /* 로딩 애니메이션 시작 0.5초 후에 */
}

@keyframes spinner-aur408 {
  to {
    transform: rotate(1turn);
  }
}

@keyframes showLoading {
  to {
    opacity: 1;
  }
}

.skeletonStyle {
  background: linear-gradient(120deg, #f9f9fb 30%, #ffffff 38%, #ffffff 40%, #f9f9fb 48%);
  background-size: 200% 100%;
  background-position: 100% 0;
  animation: skeletonMv 1s infinite;
}
@keyframes skeletonMv {
  100% {
    background-position: -100% 0;
  }
}


.moveTop{ position: fixed; bottom: 20px; right: 20px; z-index: 750; display: none; opacity: 0; transition: 0.5s;}
.moveTop img{ width:40px;}

.topWrap{ background-color: #fff;}
.topWrap .naviDc{ position: relative; width:1200px; margin: 0 auto;}
.topWrap .naviDc .navi{ display: flex; justify-content: flex-end; padding: 4px 0 4px 0;}
.topWrap .naviDc .navi ul{ font-size: 11px; font-weight: 500; color: var(--gray90); height: 24px; line-height: 16px; padding: 4px 8px; margin-left: 4px; cursor:pointer;}
.topWrap .top{ display: flex; align-items: center; position: relative; width:1200px; height: 76px; margin: 0 auto;}
.topWrap .top .logo{ cursor: pointer;}
.topWrap .top .section{ display: flex; font-size: 24px; font-weight: 600; margin-left: 2px;}
.topWrap .top .section ul{line-height: 36px; padding: 0 4px 0 4px; margin: 0 0 0 22px; color: var(--gray120); cursor: pointer;}
.topWrap .top .section ul.on{ color: var(--blue100);}
.topWrap .top .pc{ position: absolute; top:20px; right: 0; display: flex; align-items: center;}
.topWrap .top .pc .search{ position: relative; width:36px; text-align: center; height: 36px; align-items: center;}
.topWrap .top .pc .search .inp{ position: absolute; top: 0; right: 0; width:284px; height: 32px; border: solid 2px var(--blue100); background-color: #fff; border-radius: 8px; z-index: 100; display: none;}
.topWrap .top .pc .search .inp.visible{ display: block;}
.topWrap .top .pc .search .inp input{ position: absolute; top:0; left: 0; width:calc(100% - 36px); height: 32px; background-color: transparent; border: 0; border-radius: 8px; outline: none; text-indent:8px;}
.topWrap .top .pc .search .inp input::placeholder{ color: var(--gray50);}
.topWrap .top .pc .search .inp .clickSearch{ position: absolute; top:4px; right: 8px; line-height: 32px; text-align: center; cursor: pointer;}
.topWrap .top .pc .searched{ position: absolute; top:0; right: 0; width:284px; height:calc(100% - 4px); z-index: 200; display: none;}
.topWrap .top .pc .searched .bg{ position: absolute; top:6px; left: 2px; width:calc(100% - 52px); height: calc(100% - 8px); border-radius: 8px; background-color: #fff; text-align: left;}
.topWrap .top .pc .searched .bg .keyword{ background-color: var(--gray30); border-radius: 4px; display: inline-flex; line-height: 24px; padding: 0 6px 0 8px; align-items: center; font-size: 12px; color: var(--gray90); font-weight: 600;}
.topWrap .top .pc .searched .bg .keyword img{ width:16px; margin: 0 0 0 4px; cursor: pointer;}
.topWrap .top .pc .search .openSearch{ margin: 6px 8px 0 0; cursor: pointer;}
.topWrap .top .pc .search .suggestion{ position: absolute; top:40px; right: 0; padding: 4px; width:278px; background-color: #fff; border: solid 1px var(--gray30); border-radius: 8px; box-shadow: 0 4px 12px -4px #10182808, 0 12px 20px -8px #10182814; z-index: 750; transition: 0.3s;}
.topWrap .top .pc .search .suggestion ul{ padding: 8px 16px; text-align: left; cursor: pointer;}
.topWrap .top .pc .search .suggestion ul:hover{ background-color: var(--gray10);}
.topWrap .top .pc .search .suggestion ul li:nth-child(1){ color: var(--gray90); line-height: 21px;}
.topWrap .top .pc .search .suggestion ul li:nth-child(2){ font-size: 11px; line-height: 16px; font-weight: 500; color: var(--gray50);}
.topWrap .top .pc .btn{ display: flex; align-items: center; padding: 0 12px 0 12px; margin: 0 0 0 16px; height: 36px; border-radius: 8px; color: var(--blue100); background-color: var(--blue30); text-align: center; font-weight: 600;}
.topWrap .top .pc .btn img{ margin-right: 4px;}
.topWrap .top .pc .myVote{display: flex; align-items: center; padding: 0 12px 0 12px; margin: 0 0 0 16px; height: 34px; border-radius: 8px; color: var(--gray90); border: solid 1px var(--gray30); text-align: center; font-weight: 600;}
.topWrap .top .pc .myVote span{ color: var(--gray120); margin-left:4px;}
.topWrap .top .pc .login,.topWrap .top .pc .logout{ padding: 8px 12px 8px 12px; color: var(--gray100); font-weight: 600; line-height: 20px; margin-left: 24px; cursor: pointer;}
.topWrap .top .mo{ display: none;}

.topWrap .menuBox{ border-top: solid 1px var(--gray30);}
.topWrap .menuBox .dep1{ border-bottom: solid 1px var(--gray30);}
.topWrap .menuBox .dep1 .menu{ width:1200px; margin: 0 auto; display: flex; height: 58px;}
.topWrap .menuBox .dep1 li dl{ position: relative; line-height: 58px; font-size: 20px; font-weight: 600; margin-right: 32px; cursor: pointer;}
.topWrap .menuBox .dep1 li dl.on{ color: var(--blue100);}
.topWrap .menuBox .dep1 li dl.on::before{ content: ''; position: absolute; bottom: -1px; left: 0; width:100%; height: 3px; background-color: var(--blue100);}
.topWrap .menuBox .dep2{ background-color: var(--bg01);}
.topWrap .menuBox .dep2 .menu{ width:1200px; margin: 0 auto; height: 56px;}
.topWrap .menuBox .dep2 li dl{ display: none; cursor: pointer;}
.topWrap .menuBox .dep2 li dl.on{ display: flex;}
.topWrap .menuBox .dep2 li dl dt{ font-size: 16px; line-height: 56px; margin-right: 40px; font-weight: 500; color: var(--gray90);}
.topWrap .menuBox .dep2 li dl dt.on{ color: var(--gray120); font-weight: 700;}


.newsSubMenu{ padding: 40px 0 0 0;}
.newsSubMenu .menu{ width:1200px; display: flex; margin: 0 auto; height: 45px; align-items: center;}
.newsSubMenu ul li.tit{  font-size: 32px; line-height: 48px; font-weight: 700; margin-right: 24px;}
.newsSubMenu ul li.list{ display: none;}
.newsSubMenu ul li.list.on{ display: flex;}
.newsSubMenu ul li.list dl{ line-height: 48px; font-size: 18px; font-weight: 500; color: var(--gray90); cursor: pointer; padding: 0 16px 0 16px;}
.newsSubMenu ul li.list dl.on{ color: var(--blue100); font-weight: 700;}
.newsSubMenu ul li.list dl:hover{ font-weight: 600; color: var(--gray120);}

.stickyWrap{ position: fixed; top:-65px; left: 0; width:100%; height: 60px; background-color: #fff; border-bottom: solid 1px var(--gray30); z-index: 700; transition: 0.3s;}
.stickyWrap.on{ top:0;}
.stickyWrap .inBox{ position: relative; display: flex; align-items: center; width:1200px; height: 60px; margin: 0 auto;}
.stickyWrap .inBox .logo{ padding: 0 0 6px 0; cursor: pointer;}
.stickyWrap .inBox .menu{ display: none;}
.stickyWrap .inBox .menu.on{ display: flex; overflow-x: auto; width:100%;}
.stickyWrap .inBox .menu::-webkit-scrollbar {
  display: none; /* 웹킷 기반 브라우저에서 스크롤바 숨기기 */
}
.stickyWrap .inBox .menu ul{ font-size: 18px; font-weight: 600; color: var(--gray90); line-height: 28px; margin-left: 32px; cursor: pointer; flex-shrink: 0;}
.stickyWrap .inBox .menu ul a{ color: var(--gray90);}
.stickyWrap .inBox .menu ul.on a{ color: var(--blue100);}
.stickyWrap .inBox .articleTitle{ width:100%; text-align: center; font-size: 18px; font-weight: 600;}
.stickyWrap .scrollProcess{ position: absolute; bottom: -4px; left: 0; height: 4px; background-color: var(--blue100); transition: 0.3s;}
.stickyWrap .inBox .pc{margin-left: auto; display: flex; align-items: center; flex-shrink: 0;}
.stickyWrap .inBox .pc .search{ position: relative; width:36px; text-align: center; height: 36px; align-items: center;}
.stickyWrap .inBox .pc .search .inp{ position: absolute; top: 0; right: 0; width:284px; height: 32px; border: solid 2px var(--blue100); background-color: #fff; border-radius: 8px; z-index: 100; display: none;}
.stickyWrap .inBox .pc .search .inp.visible{ display: block;}
.stickyWrap .inBox .pc .search .inp input{ position: absolute; top:0; left: 0; width:calc(100% - 36px); height: 32px; background-color: transparent; border: 0; border-radius: 8px; outline: none; text-indent:8px;}
.stickyWrap .inBox .pc .search .inp input::placeholder{ color: var(--gray50);}
.stickyWrap .inBox .pc .search .inp .clickSearch{ position: absolute; top:4px; right: 8px; line-height: 32px; text-align: center; cursor: pointer;}
.stickyWrap .inBox .pc .search .openSearch{ margin: 6px 8px 0 0; cursor: pointer;}
.stickyWrap .inBox .pc .searched{ position: absolute; top:0; right: 0; width:284px; height:calc(100% - 4px); z-index: 200; display: none;}
.stickyWrap .inBox .pc .searched .bg{ position: absolute; top:6px; left: 2px; width:calc(100% - 52px); height: calc(100% - 8px); border-radius: 8px; background-color: #fff; text-align: left;}
.stickyWrap .inBox .pc .searched .bg .keyword{ background-color: var(--gray30); border-radius: 4px; display: inline-flex; line-height: 24px; padding: 0 6px 0 8px; align-items: center; font-size: 12px; color: var(--gray90); font-weight: 600;}
.stickyWrap .inBox .pc .searched .bg .keyword img{ width:16px; margin: 0 0 0 4px; cursor: pointer;}
.stickyWrap .inBox .pc .btn{ display: flex; align-items: center; padding: 0 12px 0 12px; margin: 0 0 0 16px; height: 36px; border-radius: 8px; color: var(--blue100); background-color: var(--blue30); text-align: center; font-weight: 600;}
.stickyWrap .inBox .pc .btn img{ margin-right: 4px;}
.stickyWrap .inBox .pc .myVote{display: flex; align-items: center; padding: 0 12px 0 12px; margin: 0 0 0 16px; height: 34px; border-radius: 8px; color: var(--gray90); border: solid 1px var(--gray30); text-align: center; font-weight: 600;}
.stickyWrap .inBox .pc .myVote span{ color: var(--gray120); margin-left:4px;}
.stickyWrap .inBox .pc .login,.stickyWrap .inBox .pc .logout{ padding: 8px 12px 8px 12px; color: var(--gray100); font-weight: 600; line-height: 20px; margin-left: 24px; cursor: pointer;}
.stickyWrap .inBox .mo{ display: none;}

.stickyArticleWrap{ position: fixed; top:-65px; left: 0; width:100%; height: 60px; background-color: #fff; border-bottom: solid 1px var(--gray30); z-index: 700; transition: 0.3s;}
.stickyArticleWrap.on{ top:0;}
.stickyArticleWrap .inBox{ position: relative; display: flex; align-items: center; width:1200px; height: 60px; margin: 0 auto;}
.stickyArticleWrap .inBox .logo{ padding: 0 0 6px 0; cursor: pointer;}
.stickyArticleWrap .inBox .articleTitle{ width:100%; text-align: center; font-size: 18px; font-weight: 600;}
.stickyArticleWrap .scrollProcess{ position: absolute; bottom: -4px; left: 0; height: 4px; background-color: var(--blue100);}
.stickyArticleWrap .inBox .pc{margin-left: auto; display: flex; align-items: center; flex-shrink: 0;}
.stickyArticleWrap .inBox .pc ul{ cursor: pointer;}
.stickyArticleWrap .inBox .pc .search{ width:36px; text-align: center; height: 36px; align-items: center; display: none;}
.stickyArticleWrap .inBox .pc .search img{ margin: 6px 8px 0 0;}
.stickyArticleWrap .inBox .pc .btn{ display: flex; align-items: center; padding: 0 12px 0 12px; margin: 0 0 0 16px; height: 36px; border-radius: 8px; color: var(--blue100); background-color: var(--blue30); text-align: center; font-weight: 600;}
.stickyArticleWrap .inBox .pc .btn img{ margin-right: 4px;}
.stickyArticleWrap .inBox .pc .myVote{display: flex; align-items: center; padding: 0 12px 0 12px; margin: 0 0 0 16px; height: 34px; border-radius: 8px; color: var(--gray90); border: solid 1px var(--gray30); text-align: center; font-weight: 600;}
.stickyArticleWrap .inBox .pc .myVote span{ color: var(--gray120); margin-left:4px;}
.stickyArticleWrap .inBox .pc .login,.stickyArticleWrap .inBox .pc .logout{ padding: 8px 12px 8px 12px; color: var(--gray100); font-weight: 600; line-height: 20px; margin-left: 24px;}
.stickyArticleWrap .inBox .mo{ display: none;}

.footer{ padding: 40px 0 40px 0; border-top: solid 1px var(--gray30); text-align: center; font-size: 12px; margin-top: 130px; background-color: #fff;}
.footer .items{ display: flex; justify-content: center; align-items: center; font-weight: 500;}
.footer .items li{ position: relative; line-height: 18px; margin: 0 12px 0 12px; cursor: pointer;}
.footer .items li::after{ content:''; position: absolute; top:3px; right: -12px; width:1px; height: 12px; background-color: var(--gray30);}
.footer .items li:last-child::after{ display: none;}
.footer .items li span{ font-weight: 600;}
.footer .copyright{ color: var(--gray90); padding: 12px 0 0 0;}
.footer .info{ font-size: 12px; line-height: 18px; color: var(--gray70); padding: 16px 0 0 0;}
.footer .info a{ font-weight: 600; text-decoration: underline; color: var(--gray70);}
.footer .btnBox{ display: none;}

.slideMenu{ position: fixed; top:0; right: -100%; width:100%; height: 100%; background-color: #fff; transition: 0.5s; z-index: 760;}
.slideMenu.on{ right: 0;}
.slideMenu .top{ display: flex; align-items: center; width:calc(100% - 40px); padding: 0 20px 0 20px; height: 56px;}
.slideMenu .top .logo img{ height: 18px;}
.slideMenu .top .close{ margin-left: auto;}
.slideMenu .info{ display: flex; width:calc(100% - 40px); padding: 0 20px 0 20px; height: 36px; align-items: center; margin-bottom: 24px;}
.slideMenu .info .login{ font-size: 18px; line-height: 27px; font-weight: 600; color: var(--gray70);}
.slideMenu .info .login .signIn{ display: flex; align-items: center;}
.slideMenu .info .login .name{ color: var(--gray100);}
.slideMenu .info .vote{ display: inline-flex; margin-left: auto;}
.slideMenu .info .vote .myVote{display: flex; align-items: center; padding: 0 12px 0 12px; height: 34px; border-radius: 8px; color: var(--gray90); border: solid 1px var(--gray30); text-align: center; font-weight: 600;}
.slideMenu .info .vote .myVote span{ color: var(--gray120); margin-left:4px;}
.slideMenu .info .vote .btn{ display: flex; align-items: center; padding: 0 12px 0 12px; margin: 0 0 0 12px; height: 36px; border-radius: 8px; color: var(--blue100); background-color: var(--blue30); text-align: center; font-weight: 600;}
.slideMenu .info .vote .btn img{ margin-right: 4px;}
.slideMenu .scroll{ position: absolute; top:116px; left: 0; height: calc(100% - 196px); width:calc(100% - 40px); padding: 0 20px 0 20px; overflow-y: auto;}
.slideMenu .searchSlide{ position: relative;}
.slideMenu .searchSlide .suggestion{ position: absolute; top:52px; right: 0; padding: 4px; width:calc(100% - 10px); background-color: #fff; border: solid 1px var(--gray30); border-radius: 8px; box-shadow: 0 4px 12px -4px #10182808, 0 12px 20px -8px #10182814; z-index: 750; transition: 0.3s;}
.slideMenu .searchSlide .suggestion ul{ padding: 8px 16px; text-align: left; cursor: pointer;}
.slideMenu .searchSlide .suggestion ul:hover{ background-color: var(--gray10);}
.slideMenu .searchSlide .suggestion ul li:nth-child(1){ color: var(--gray90); line-height: 21px;}
.slideMenu .searchSlide .suggestion ul li:nth-child(2){ font-size: 11px; line-height: 16px; font-weight: 500; color: var(--gray50);}
.slideMenu .search{ display: flex; align-items: center; height:48px; border-radius: 8px; background-color: var(--gray20); overflow: hidden; margin-bottom: 24px;}
.slideMenu .search img{ margin: 0 8px 0 0;}
.slideMenu .search input{ margin-left: auto; height: 48px; border: 0; width:calc(100% - 40px); background: transparent; font-size: 16px; line-height: 40px; font-weight: 500; outline: none; text-indent: 8px;}
.slideMenu .search input::placeholder{ color: var(--gray50);}
.slideMenu .tab{ display: flex; width:100%; border-bottom: solid 1px var(--gray30);}
.slideMenu .tab ul{ position: relative; width:100%; font-size: 18px; line-height: 27px; font-weight: 600; padding: 0 0 12px 0; text-align: center;}
.slideMenu .tab ul.on{ color: var(--blue100);}
.slideMenu .tab ul.on::before{ content: ''; position: absolute; bottom: -1px; left: 0; width:100%; height: 3px; background-color: var(--blue100);}
.slideMenu .menu{ padding: 24px 0 0 0; display: none;}
.slideMenu .menu.on{ display: block;}
.slideMenu .menu ul{ margin-bottom: 32px;}
.slideMenu .menu ul:last-child{ margin-bottom: 0;}
.slideMenu .menu ul li a{ display: flex;}
.slideMenu .menu ul li:nth-child(1){ display: inline-flex; align-items: center; font-size: 18px; line-height: 27px; font-weight: 700;}
.slideMenu .menu ul li:nth-child(1) .arw{ margin-left: 4px;}
.slideMenu .menu ul li:nth-child(2){ display: flex; flex-wrap: wrap; margin-top: 16px;}
.slideMenu .menu ul li:nth-child(2) dl{ width:50%; font-size: 16px; line-height: 32px; color: var(--gray100); margin-top: 10px;}
.slideMenu .menu ul li:nth-child(2) dl:nth-child(1),.slideMenu .menu ul li:nth-child(2) dl:nth-child(2){ margin-top: 0;}
.slideMenu .logout{ position: absolute; bottom: 0; left: 0; width:calc(100% - 40px); padding: 20px;}
.slideMenu .logout .btn{ display: inline-flex; height: 40px; align-items: center; cursor: pointer;}
.slideMenu .logout .btn img{ margin-right: 8px;}

.botMenu{ display: none;}


@media(max-width:800px) {
  .moveTop{ display: block; opacity: 0; transition: 0.5s;}
  .topWrap{ border-bottom: solid 0 var(--gray30);}
  .topWrap .naviDc{ display: none;}
  .topWrap .top{ position: relative; width:calc(100% - 40px); padding: 0 20px 0 20px; height: 56px;}
  .topWrap .top .logo img{ height: 18px;}
  .topWrap .top .section{ position: absolute; top:13px; right: 64px; display: flex; font-size: 20px; font-weight: 600; margin-left: 0;}
  .topWrap .top .section ul{line-height: 30px; padding: 0 0 0 0; margin: 0 0 0 16px; color: var(--gray120); cursor: pointer;}
  .topWrap .top .section ul.on{ color: var(--blue100);}
  .topWrap .top .pc{ display: none;}
  .topWrap .top .mo{ display: block; position: absolute; top:15px; right: 20px; height: 30px; line-height: 30px;}

  .topWrap .menuBox{ border-top: 0;}
  .topWrap .menuBox .dep1{ display: none;}
  .topWrap .menuBox .dep1 .menu{ width:calc(100% - 40px); padding: 0 20px 0 20px; margin: 0 auto; display: flex; height: 40px; overflow-x: auto; overflow-y: hidden; white-space: nowrap; -webkit-overflow-scrolling: touch;}
  .topWrap .menuBox .dep1 .menu::-webkit-scrollbar {
    display: none; /* 웹킷 기반 브라우저에서 스크롤바 숨기기 */
  }
  .topWrap .menuBox .dep1 li dl{ position: relative; line-height: 40px; font-size: 18px; font-weight: 600; margin-right: 40px; white-space: nowrap; cursor: pointer;}
  .topWrap .menuBox .dep1 li a:last-child dl{ margin-right: 0;}
  .topWrap .menuBox .dep2{ background-color: #fff; border-bottom: solid 1px var(--gray30);}
  .topWrap .menuBox .dep2 .menu{ width:calc(100% - 40px); padding: 0 20px 0 20px; margin: 0 auto; height: 40px;}
  .topWrap .menuBox .dep2 li dl{ display: none; cursor: pointer; overflow-x: auto; overflow-y: hidden; white-space: nowrap; -webkit-overflow-scrolling: touch;}
  .topWrap .menuBox .dep2 li dl::-webkit-scrollbar {
    display: none; /* 웹킷 기반 브라우저에서 스크롤바 숨기기 */
  }
  .topWrap .menuBox .dep2 li dl.on{ display: flex;}
  .topWrap .menuBox .dep2 li dl dt{ position: relative; font-size: 18px; line-height: 40px; margin-right: 32px; font-weight: 600; color: var(--gray90); white-space: nowrap;}
  .topWrap .menuBox .dep2 li dl a:last-child dt{ margin-right: 0;}
  .topWrap .menuBox .dep2 li dl dt.on{ color: var(--blue100); font-weight: 600;}
  .topWrap .menuBox .dep2 li dl dt.on::before{ content: ''; position: absolute; bottom: -1px; left: 0; width:100%; height: 3px; background-color: var(--blue100);}

  .newsSubMenu{ padding: 0 20px 0 20px; width:calc(100% - 40px); background-color: var(--bg01);}
  .newsSubMenu .menu{ width:100%; display: flex; margin: 0 auto; height: 45px; align-items: center;}
  .newsSubMenu ul li.tit{ display: none;  font-size: 32px; line-height: 48px; font-weight: 700; margin-right: 24px;}
  .newsSubMenu ul li.list{ display: none;}
  .newsSubMenu ul li.list.on{ display: flex; overflow-x: auto; overflow-y: hidden; white-space: nowrap; -webkit-overflow-scrolling: touch;}
  .newsSubMenu ul li.list.on::-webkit-scrollbar {
    display: none; /* 웹킷 기반 브라우저에서 스크롤바 숨기기 */
  }
  .newsSubMenu ul li.list a{ margin-right: 32px;}
  .newsSubMenu ul li.list dl{ line-height: 45px; font-size: 14px; font-weight: 500; color: var(--gray90); cursor: pointer; padding: 0 0 0 0;}
  .newsSubMenu ul li.list dl.on{ color: var(--gray120); font-weight: 700;}
  .newsSubMenu ul li.list dl:hover{ font-weight: 600; color: var(--gray120);}

  .stickyWrap{ position: fixed; top:-56px; left: 0; width:100%; height: 56px; background-color: #fff; border-bottom: solid 1px var(--gray30); z-index: 700; transition: 0.3s;}
  .stickyWrap .inBox{ position: relative; display: flex; align-items: center; width:calc(100% - 40px); padding: 0 20px 0 20px; height: 56px; margin: 0 auto;}
  .stickyWrap .inBox .logo img{ height: 18px;}
  .stickyWrap .inBox .menu ul{ font-size: 14px; font-weight: 600; color: var(--gray90); line-height: 21px; margin-left: 0; margin-right:8px;  cursor: pointer;}
  .stickyWrap .inBox .menu ul.on{ color: var(--blue100);}
  .stickyWrap .inBox .pc{ display: none;}
  .stickyWrap .inBox .mo{ display: block; position: absolute; top:15px; right: 20px; height: 30px; line-height: 30px;}

  .stickyArticleWrap{ position: fixed; top:-60px; left: 0; width:100%; height: 56px; background-color: #fff; border-bottom: solid 0 var(--gray30); z-index: 700; transition: 0.3s;}
  .stickyArticleWrap .inBox{ position: relative; display: flex; align-items: center; width:calc(100% - 40px); padding: 0 20px 0 20px; height: 56px; margin: 0 auto;}
  .stickyArticleWrap .inBox .logo{ display: none;}
  .stickyArticleWrap .inBox .articleTitle{ width:100%; font-size: 16px; font-weight: 600; text-align: left; overflow: hidden; display: -webkit-box;  -webkit-box-orient: vertical; word-break: break-all; -webkit-line-clamp: 1; text-overflow: ellipsis;}
  .stickyArticleWrap .scrollProcess{ position: absolute; bottom: -4px; left: 0; height: 4px; background-color: var(--blue100);}
  .stickyArticleWrap .inBox .pc{ display: none;}
  .stickyArticleWrap .inBox .mo{ display: inline-flex; margin-left: auto;}
  .stickyArticleWrap .inBox .mo img{ margin-left: 16px;}

  .footer{ padding: 24px 0 0 0; border-top: solid 1px var(--gray30); text-align: center; font-size: 12px; margin-top: 100px;}
  .footer .items{ display: flex; flex-wrap: wrap; justify-content: center; align-items: center; font-weight: 500; padding: 0 24px 0 24px;}
  .footer .items li{ position: relative; line-height: 18px; margin: 0 12px 8px 12px; color: var(--gray70); cursor: pointer;}
  .footer .items li::after{ content:''; position: absolute; top:3px; right: -12px; width:1px; height: 12px; background-color: var(--gray30);}
  .footer .items li.mx{ display: none;}
  .footer .items li span{ font-weight: 600;}
  .footer .copyright{ color: var(--gray70); padding: 8px 0 0 0;}
  .footer .btnBox{ display: flex; justify-content: center; margin-bottom: 20px;}
  .footer .btnBox li{ padding: 4px 8px 4px 8px; border: solid 1px var(--gray40); border-radius: 4px; font-size: 12px; line-height: 18px; font-weight: 500; color: var(--gray90); cursor: pointer;}
  .footer .btnBox li.pc{ margin:0 12px 0 12px;}
  .footer .info{ padding: 24px 24px 70px 24px; margin-top: 24px; background-color: var(--gray10);}

  .botMenu{ position: fixed; bottom: 0; left: 0; width:100%; height: 64px; background-color: #fff; border-radius: 12px 12px 0 0;  box-shadow: 0 0 12px 0 #1018281F; z-index: 700; display: block; transition: 0.3s;}
  .botMenu .inBox{ display: flex; justify-content: space-between; padding: 12px 0 0 0;}
  .botMenu .inBox ul{ display: inline-flex; flex-wrap: wrap; justify-content: center; width:25%;}
  .botMenu .inBox ul li{ width:100%; text-align: center;}
  .botMenu .inBox ul:nth-child(1) li:nth-child(1){ width:24px; height: 24px; background: url("https://cdn.trend.rankify.best/dctrend/front/images/ico_botmenu_news_g.svg"); background-size: contain;}
  .botMenu .inBox ul:nth-child(2) li:nth-child(1){ width:24px; height: 24px; background: url("https://cdn.trend.rankify.best/dctrend/front/images/ico_botmenu_rank_g.svg"); background-size: contain;}
  .botMenu .inBox ul:nth-child(3) li:nth-child(1){ width:24px; height: 24px; background: url("https://cdn.trend.rankify.best/dctrend/front/images/ico_botmenu_vote_g.svg"); background-size: contain;}
  .botMenu .inBox ul:nth-child(4) li:nth-child(1){ width:24px; height: 24px; background: url("https://cdn.trend.rankify.best/dctrend/front/images/ico_botmenu_my_g.svg"); background-size: contain;}
  .botMenu .inBox ul li:nth-child(2){ line-height: 20px; font-size: 11px; font-weight: 600; color: var(--gray70);}
  .botMenu .inBox ul.on li:nth-child(2){ color: var(--gray120);}
  .botMenu .inBox ul.on:nth-child(1) li:nth-child(1){ width:24px; height: 24px; background: url("https://cdn.trend.rankify.best/dctrend/front/images/ico_botmenu_news.svg"); background-size: contain;}
  .botMenu .inBox ul.on:nth-child(2) li:nth-child(1){ width:24px; height: 24px; background: url("https://cdn.trend.rankify.best/dctrend/front/images/ico_botmenu_rank.svg"); background-size: contain;}
  .botMenu .inBox ul.on:nth-child(3) li:nth-child(1){ width:24px; height: 24px; background: url("https://cdn.trend.rankify.best/dctrend/front/images/ico_botmenu_vote.svg"); background-size: contain;}
  .botMenu .inBox ul.on:nth-child(4) li:nth-child(1){ width:24px; height: 24px; background: url("https://cdn.trend.rankify.best/dctrend/front/images/ico_botmenu_my.svg"); background-size: contain;}
}
@media(max-width:480px) {
  .stickyWrap .inBox .menu.on {
    display: flex;
    overflow-x: auto;
    width: calc(100% - 40px);
  }
  .topWrap .menuBox .dep2 li dl dt{ font-size: 16px;}
}

.notFoundWrap{ text-align: center; padding: 80px 0 0 0;}
.notFoundWrap .ani{ width:300px; height: 300px; margin: 0 auto;}
.notFoundWrap .title{ font-weight: 600; font-size: 46px;}
.notFoundWrap .tit{ font-size: 22px;}
.notFoundWrap .txt{ padding: 30px 0 0 0; font-size: 16px; opacity: 0.5;}
@media(max-width:800px) {
  .notFoundWrap .ani{ width:250px; height: 250px; margin: 0 auto;}
  .notFoundWrap .title{ font-size: 40px;}
  .notFoundWrap .tit{ font-size: 18px;}
  .notFoundWrap .txt{ padding: 30px 0 0 0; font-size: 14px; opacity: 0.5;}
}

/*
.hoverImgPt img{ transition: 0.5s;}
.hoverImgPt:hover img{ transform: scale(1.05);}
*/


.contentsWrap{ width:1200px; margin: 0 auto;}

.arw{ width:24px; height: 24px; background: url("https://cdn.trend.rankify.best/dctrend/front/images/ico_arw.svg"); background-size: contain;}
.arw.s16{ width:16px; height: 16px;}
.arwBlue{ width:24px; height: 24px; background: url("https://cdn.trend.rankify.best/dctrend/front/images/ico_arw_blue.svg"); background-size: contain;}
.arwBlue.s16{ width:16px; height: 16px;}

.listMoreWrap{ text-align: center;}
.btnListMore{ display: inline-flex; width:390px; height: 64px; align-items: center; justify-content: center; background-color: var(--gray110); color: #fff; border-radius: 8px; font-size: 18px; font-weight: 600; cursor: pointer;}
.btnListMore img{ margin-left: 4px;}

@media(max-width:800px) {
  .contentsWrap{ width:100%; margin: 0 auto;}
}

@media(max-width:480px) {
  .listMoreWrap{ text-align: center; padding: 30px 20px 0 20px;}
  .btnListMore{ display: inline-flex; width:100%; height: 56px; align-items: center; justify-content: center; background-color: var(--gray110); color: #fff; border-radius: 8px; font-size: 16px; font-weight: 600; cursor: pointer;}
  .btnListMore img{ margin-left: 4px;}
}

.trendNewsWrap{ display: flex; flex-wrap: wrap; margin: 40px 0 0 0;}
.trendNewsWrap .mainNews{ position: relative; width:860px; height: 528px;}
.trendNewsWrap .mainNews .main{ position: absolute; top:0; right: 0; width:532px; border-radius: 8px; overflow: hidden; cursor: pointer;}
.trendNewsWrap .mainNews .main .head{ text-align: center; width:58px; height: 24px; line-height: 24px; background-color: var(--blue100); color: #fff; border-radius: 16px; font-size: 12px; font-weight: 600; margin: 16px 0 8px 0;}
.trendNewsWrap .mainNews .main .title{ font-size: 24px; font-weight: 700; line-height: 34px; overflow: hidden; display: -webkit-box;  -webkit-box-orient: vertical; word-break: break-all; -webkit-line-clamp: 2; text-overflow: ellipsis;}
.trendNewsWrap .mainNews .main .txt{ font-size: 16px; line-height: 24px; color: var(--gray100); margin-top: 8px; overflow: hidden; display: -webkit-box;  -webkit-box-orient: vertical; word-break: break-all; -webkit-line-clamp: 3; text-overflow: ellipsis;}
.trendNewsWrap .mainNews .main .thumb{ position: relative; width:100%; height: 329px; border-radius: 8px; overflow: hidden;}
.trendNewsWrap .mainNews .main .thumb img{ position: absolute; top:0; left: 0; width:100%; height: 100%; object-fit: cover; z-index: 100;}
.trendNewsWrap .mainNews .main:hover .title{ text-decoration: underline;}

.trendNewsWrap .sideNewsTitle{ display: none;}
.trendNewsWrap .sideNews{ position: relative; width:288px; height: 529px;}
.trendNewsWrap .sideNews ul:nth-child(1){ margin-bottom: 24px; cursor: pointer;}
.trendNewsWrap .sideNews ul:nth-child(1) .thumb{ position: relative; width:100%; height: 179px; border-radius: 8px; overflow: hidden; margin-bottom: 16px;}
.trendNewsWrap .sideNews ul:nth-child(1) .thumb img{ position: absolute; top:0; left: 0; width:100%; height: 100%; object-fit: cover; z-index: 100;}
.trendNewsWrap .sideNews ul:nth-child(1) .title{ font-size: 20px; font-weight: 700; line-height: 30px; overflow: hidden; display: -webkit-box;  -webkit-box-orient: vertical; word-break: break-all; -webkit-line-clamp: 2; text-overflow: ellipsis;}
.trendNewsWrap .sideNews ul:nth-child(1) .txt{ font-size: 16px; line-height: 24px; color: var(--gray100); margin-top: 8px; overflow: hidden; display: -webkit-box;  -webkit-box-orient: vertical; word-break: break-all; -webkit-line-clamp: 3; text-overflow: ellipsis;}
.trendNewsWrap .sideNews ul:hover .title{ text-decoration: underline;}

.trendNewsWrap .sideNews ul:nth-child(2){ display: flex; align-items: center; position: relative; height: 56px; cursor: pointer; margin-bottom: 49px;}
.trendNewsWrap .sideNews ul:nth-child(2) .thumb{ position: absolute; top:0; right: 0; width:90px; height: 56px; border-radius: 4px; overflow: hidden;}
.trendNewsWrap .sideNews ul:nth-child(2) .thumb img{ position: absolute; top:0; left: 0; width:100%; height: 100%; object-fit: cover; z-index: 100;}
.trendNewsWrap .sideNews ul:nth-child(2) .title{ margin-right: 98px; font-size: 16px; font-weight: 600; height: 48px; line-height: 24px; overflow: hidden; display: -webkit-box;  -webkit-box-orient: vertical; word-break: break-all; -webkit-line-clamp: 2; text-overflow: ellipsis;}
.trendNewsWrap .sideNews ul:nth-child(2) .txt{ display: none;}
.trendNewsWrap .sideNews ul:nth-child(2)::before{ content: ''; position: absolute; bottom:-24px; left: 0; width:100%; height: 1px; background-color: var(--gray30);}

.trendNewsWrap .sideNews ul:nth-child(3){ display: flex; align-items: center; position: relative; height: 56px; cursor: pointer;}
.trendNewsWrap .sideNews ul:nth-child(3) .thumb{ position: absolute; top:0; right: 0; width:90px; height: 56px; border-radius: 4px; overflow: hidden;}
.trendNewsWrap .sideNews ul:nth-child(3) .thumb img{ position: absolute; top:0; left: 0; width:100%; height: 100%; object-fit: cover; z-index: 100;}
.trendNewsWrap .sideNews ul:nth-child(3) .title{ margin-right: 98px; font-size: 16px; font-weight: 600; height: 48px; line-height: 24px; overflow: hidden; display: -webkit-box;  -webkit-box-orient: vertical; word-break: break-all; -webkit-line-clamp: 2; text-overflow: ellipsis;}
.trendNewsWrap .sideNews ul:nth-child(3) .txt{ display: none;}

.trendNewsWrap .rtNewsTitle{ display: flex; align-items: center; font-size: 24px; font-weight: 700; color: var(--blue100); line-height: 36px; margin: 32px 0 16px 0;}
.trendNewsWrap .rtNewsTitle .arw{ margin-left: 4px; cursor: pointer;}
.trendNewsWrap .rtNews{ position: relative; height: 255px;}
.trendNewsWrap .rtNews ul{ position: relative; cursor: pointer;}
.trendNewsWrap .rtNews ul:nth-child(1), .trendNewsWrap .rtNews ul:nth-child(2){ width:288px; height: 255px;}
.trendNewsWrap .rtNews ul:nth-child(1) .thumb, .trendNewsWrap .rtNews ul:nth-child(2) .thumb{ position: relative; height: 179px; border-radius: 8px; overflow: hidden; margin-bottom: 16px;}
.trendNewsWrap .rtNews ul:nth-child(1) .thumb::before, .trendNewsWrap .rtNews ul:nth-child(2) .thumb::before{ content: ''; position: absolute; top:0; left: 0; width:calc(100% - 2px); height:calc(100% - 2px); border-radius: 8px; border: solid 1px rgba(0,0,0,0.08); z-index: 100;}
.trendNewsWrap .rtNews ul:nth-child(1) .thumb img, .trendNewsWrap .rtNews ul:nth-child(2) .thumb img{ position: absolute; top:0; left: 0; width:100%; height: 100%; object-fit: cover;}
.trendNewsWrap .rtNews ul:nth-child(1) .title, .trendNewsWrap .rtNews ul:nth-child(2) .title{ font-size: 20px; line-height: 30px; font-weight: 600; overflow: hidden; display: -webkit-box;  -webkit-box-orient: vertical; word-break: break-all; -webkit-line-clamp: 2; text-overflow: ellipsis;}
.trendNewsWrap .rtNews ul:nth-child(1) .txt, .trendNewsWrap .rtNews ul:nth-child(2) .txt{ display: none;}
.trendNewsWrap .rtNews ul:nth-child(1){ position: absolute; top:0; left: 0;}
.trendNewsWrap .rtNews ul:nth-child(2){ position: absolute; top:0; left: 304px;}
.trendNewsWrap .rtNews ul:nth-child(3), .trendNewsWrap .rtNews ul:nth-child(4){ width:252px; height: 108px;}
.trendNewsWrap .rtNews ul:nth-child(3) .thumb, .trendNewsWrap .rtNews ul:nth-child(4) .thumb{ position: absolute; top:0; right: 0; width:90px; height: 56px; border-radius: 8px; overflow: hidden; margin-bottom: 16px;}
.trendNewsWrap .rtNews ul:nth-child(3) .thumb::before, .trendNewsWrap .rtNews ul:nth-child(4) .thumb::before{ content: ''; position: absolute; top:0; left: 0; width:calc(100% - 2px); height:calc(100% - 2px); border-radius: 8px; border: solid 1px rgba(0,0,0,0.08); z-index: 100;}
.trendNewsWrap .rtNews ul:nth-child(3) .thumb img, .trendNewsWrap .rtNews ul:nth-child(4) .thumb img{ position: absolute; top:0; left: 0; width:100%; height: 100%; object-fit: cover;}
.trendNewsWrap .rtNews ul:nth-child(3) .title, .trendNewsWrap .rtNews ul:nth-child(4) .title{ padding: 3px 0 0 0; font-size: 16px; line-height: 24px; font-weight: 600; width:146px; overflow: hidden; display: -webkit-box; -webkit-box-orient: vertical; word-break: break-all; -webkit-line-clamp: 2; text-overflow: ellipsis;}
.trendNewsWrap .rtNews ul:nth-child(3) .txt, .trendNewsWrap .rtNews ul:nth-child(4) .txt{ padding: 14px 0 0 0; font-size: 14px; line-height: 21px; color: var(--gray100); overflow: hidden; display: -webkit-box;  -webkit-box-orient: vertical; word-break: break-all; -webkit-line-clamp: 2; text-overflow: ellipsis;}
.trendNewsWrap .rtNews ul:nth-child(3){ position: absolute; top:0; right: 0;}
.trendNewsWrap .rtNews ul:nth-child(3)::before{ content: ''; position: absolute; bottom:-16px; left: 0; width:100%; height: 1px; background-color: var(--gray30);}
.trendNewsWrap .rtNews ul:nth-child(4){ position: absolute; bottom:0; right: 0;}
.trendNewsWrap .rtNews ul:hover .title{ text-decoration: underline;}

.dcTrend{ margin-left: 40px; width:300px;}
.dcTrend .dcTitle{ position: relative; line-height: 36px; font-size: 24px; font-weight: 700; color: var(--gray100);}
.dcTrend .dcTitle .arw{ position: absolute; top:6px; right: 0; cursor: pointer;}
.dcTrend ul{ border: solid 1px var(--gray30); border-radius: 8px; margin-top: 16px; overflow: hidden;}
.dcTrend ul .thumb{ position: relative; height:186px; overflow: hidden;}
.dcTrend ul .thumb img{position: absolute; top:0; left: 0; width:100%; height: 100%; object-fit: cover;}
.dcTrend ul .info{ position: relative; padding: 16px;}
.dcTrend ul .info .date{ font-size: 12px; line-height: 18px; font-weight: 500; color: var(--gray70);}
.dcTrend ul .info .title{ font-size: 16px; line-height: 24px; font-weight: 600; color: var(--gray110);}
.dcTrend ul .info .btn{ position: absolute; top:calc(50% - 18px); right: 16px; border-radius: 8px; background-color: var(--gray20); width:49px; height: 36px; line-height: 36px; text-align: center; font-weight: 600; color: var(--gray90); cursor: pointer;}
.dcTrend .swiper-slide{ height: auto;}

.trendNewsWrap .trendWidgetWrap{ width:100%; padding: 80px 0 0 0;}

@media(max-width:800px) {
  .trendNewsWrap{ display: flex; flex-wrap: wrap; margin: 0 0 0 0;}
  .trendNewsWrap .mainNews{ position: relative; width:100%; height: auto;}
  .trendNewsWrap .mainNews .main{ position: relative; top:0; right: 0; width:100%; border-radius: 0; overflow: hidden; cursor: pointer;}
  .trendNewsWrap .mainNews .main .head{ z-index: 200; position: absolute; bottom:52px; left: 16px;  text-align: center; width:58px; height: 24px; line-height: 24px; background-color: var(--blue100); color: #fff; border-radius: 16px; font-size: 12px; font-weight: 600; margin: 16px 0 8px 0;}
  .trendNewsWrap .mainNews .main .title{ z-index: 200; position: absolute; bottom: 16px; left:16px; width:calc(100% - 32px); color: #fff; font-size: 24px; font-weight: 700; line-height: 36px; overflow: hidden; display: -webkit-box; -webkit-box-orient: vertical; word-break: break-all; -webkit-line-clamp: 1; text-overflow: ellipsis;}
  .trendNewsWrap .mainNews .main .txt{  font-size: 16px; line-height: 24px; color: var(--gray100); margin-top: 8px; overflow: hidden; display: none;  -webkit-box-orient: vertical; word-break: break-all; -webkit-line-clamp: 3; text-overflow: ellipsis;}
  .trendNewsWrap .mainNews .main .thumb{ position: relative; width:100%; height: 417px; border-radius: 0; overflow: hidden;}
  .trendNewsWrap .mainNews .main .thumb img{ position: absolute; top:0; left: 0; width:100%; height: 100%; object-fit: cover; z-index: 100;}
  .trendNewsWrap .mainNews .main:hover .txt{ text-decoration: none;}
  .trendNewsWrap .mainNews .main .mask{ position: absolute; bottom:0; left: 0; width:100%; height: 30%; background: linear-gradient(to bottom, #00000000, #000000); z-index: 100;}

  .trendNewsWrap .sideNewsTitle{ display: block; width:58px; height: 24px; line-height: 24px; font-size: 12px; text-align: center; border-radius: 16px; background: var(--blue30); color: var(--blue100); font-weight: 600; margin: 32px 0 16px 20px;}
  .trendNewsWrap .sideNews{ position: relative; width:calc(100% - 40px); height: auto; padding: 0 20px 0 20px;}
  .trendNewsWrap .sideNews ul:nth-child(1){ position: relative; min-height: 91px; padding-bottom: 16px; border-bottom: solid 1px var(--gray30); margin-bottom: 16px; cursor: pointer;}
  .trendNewsWrap .sideNews ul:nth-child(1) .thumb{ position: absolute; top:0; right: 0; width:147px; height: 91px; border-radius: 8px; overflow: hidden; margin-bottom: 0;}
  .trendNewsWrap .sideNews ul:nth-child(1) .thumb img{ position: absolute; top:0; left: 0; width:100%; height: 100%; object-fit: cover; z-index: 100;}
  .trendNewsWrap .sideNews ul:nth-child(1) .title{ padding:10px 0 0 0; font-size: 16px; line-height: 24px; font-weight: 600; margin-right: 160px; -webkit-line-clamp: 1;}
  .trendNewsWrap .sideNews ul:nth-child(1) .txt{  margin-top: 4px; font-size: 14px; line-height: 21px; color: var(--gray100); margin-right: 160px; overflow: hidden; display: -webkit-box;  -webkit-box-orient: vertical; word-break: break-all; -webkit-line-clamp: 2; text-overflow: ellipsis;}
  .trendNewsWrap .sideNews ul:nth-child(1):hover .txt{ text-decoration: underline;}

  .trendNewsWrap .sideNews ul:nth-child(2){ display: block; position: relative; min-height: 91px; padding-bottom: 16px; border-bottom: solid 1px var(--gray30); margin-bottom: 16px; cursor: pointer;}
  .trendNewsWrap .sideNews ul:nth-child(2) .thumb{ position: absolute; top:0; right: 0; width:147px; height: 91px; border-radius: 8px; overflow: hidden; margin-bottom: 0;}
  .trendNewsWrap .sideNews ul:nth-child(2) .thumb img{ position: absolute; top:0; left: 0; width:100%; height: 100%; object-fit: cover; z-index: 100;}
  .trendNewsWrap .sideNews ul:nth-child(2) .title{ height: 24px; padding:10px 0 0 0; font-size: 16px; line-height: 24px; font-weight: 600; margin-right: 160px; -webkit-line-clamp: 1;}
  .trendNewsWrap .sideNews ul:nth-child(2) .txt{  margin-top: 4px; font-size: 14px; line-height: 21px; color: var(--gray100); margin-right: 160px; overflow: hidden; display: -webkit-box;  -webkit-box-orient: vertical; word-break: break-all; -webkit-line-clamp: 2; text-overflow: ellipsis;}
  .trendNewsWrap .sideNews ul:nth-child(2):hover .txt{ text-decoration: underline;}
  .trendNewsWrap .sideNews ul:nth-child(2)::before{ display: none;}

  .trendNewsWrap .sideNews ul:nth-child(3){ display: block; position: relative; min-height: 91px; padding-bottom: 16px; border-bottom: solid 0 var(--gray30); margin-bottom: 16px; cursor: pointer;}
  .trendNewsWrap .sideNews ul:nth-child(3) .thumb{ position: absolute; top:0; right: 0; width:147px; height: 91px; border-radius: 8px; overflow: hidden; margin-bottom: 0;}
  .trendNewsWrap .sideNews ul:nth-child(3) .thumb img{ position: absolute; top:0; left: 0; width:100%; height: 100%; object-fit: cover; z-index: 100;}
  .trendNewsWrap .sideNews ul:nth-child(3) .title{ height: 24px; padding:10px 0 0 0; font-size: 16px; line-height: 24px; font-weight: 600; margin-right: 160px; -webkit-line-clamp: 1;}
  .trendNewsWrap .sideNews ul:nth-child(3) .txt{  margin-top: 4px; font-size: 14px; line-height: 21px; color: var(--gray100); margin-right: 160px; overflow: hidden; display: -webkit-box;  -webkit-box-orient: vertical; word-break: break-all; -webkit-line-clamp: 2; text-overflow: ellipsis;}
  .trendNewsWrap .sideNews ul:nth-child(3):hover .txt{ text-decoration: underline;}

  .trendNewsWrap .rtNewsTitle{ position: relative; width:calc(100% - 40px); padding: 0 20px 0 20px; display: flex; align-items: center; font-size: 20px; font-weight: 700; line-height: 30px; margin: 32px 0 16px 0;}
  .trendNewsWrap .rtNewsTitle .arw{ position: absolute; top:3px; right: 20px; margin-left: 4px; cursor: pointer;}
  .trendNewsWrap .rtNews{ position: relative; height: auto !important; width:calc(100% - 40px); padding: 0 20px 0 20px;}

  .trendNewsWrap .rtNews ul:nth-child(1), .trendNewsWrap .rtNews ul:nth-child(2){ width:100%; height: auto; min-height: 91px; padding-bottom: 16px; border-bottom: solid 1px var(--gray30); margin-bottom: 16px;}
  .trendNewsWrap .rtNews ul:nth-child(1) .thumb, .trendNewsWrap .rtNews ul:nth-child(2) .thumb{ position: absolute; top:0; right: 0; width:147px; height: 91px; border-radius: 8px; overflow: hidden; margin-bottom: 0;}
  .trendNewsWrap .rtNews ul:nth-child(1) .thumb::before, .trendNewsWrap .rtNews ul:nth-child(2) .thumb::before{ content: ''; position: absolute; top:0; left: 0; width:calc(100% - 2px); height:calc(100% - 2px); border-radius: 8px; border: solid 1px rgba(0,0,0,0.08); z-index: 100;}
  .trendNewsWrap .rtNews ul:nth-child(1) .thumb img, .trendNewsWrap .rtNews ul:nth-child(2) .thumb img{ position: absolute; top:0; left: 0; width:100%; height: 100%; object-fit: cover;}
  .trendNewsWrap .rtNews ul:nth-child(1) .title, .trendNewsWrap .rtNews ul:nth-child(2) .title{ padding:10px 0 0 0; font-size: 16px; line-height: 24px; font-weight: 600; margin-right: 160px; -webkit-line-clamp: 1;}
  .trendNewsWrap .rtNews ul:nth-child(1) .txt, .trendNewsWrap .rtNews ul:nth-child(2) .txt{ display: block; margin-top: 4px; font-size: 14px; line-height: 21px; color: var(--gray100); margin-right: 160px; overflow: hidden; display: -webkit-box;  -webkit-box-orient: vertical; word-break: break-all; -webkit-line-clamp: 2; text-overflow: ellipsis;}
  .trendNewsWrap .rtNews ul:nth-child(1){ position: relative; top:0; left: 0;}
  .trendNewsWrap .rtNews ul:nth-child(2){ position: relative; top:0; left: 0;}
  .trendNewsWrap .rtNews ul:nth-child(3), .trendNewsWrap .rtNews ul:nth-child(4){ width:100%; height: auto; min-height: 91px; padding-bottom: 16px; border-bottom: solid 1px var(--gray30); margin-bottom: 16px;}
  .trendNewsWrap .rtNews ul:nth-child(3) .thumb, .trendNewsWrap .rtNews ul:nth-child(4) .thumb{ position: absolute; top:0; right: 0; width:147px; height: 91px; border-radius: 8px; overflow: hidden; margin-bottom: 0;}
  .trendNewsWrap .rtNews ul:nth-child(3) .thumb::before, .trendNewsWrap .rtNews ul:nth-child(4) .thumb::before{ content: ''; position: absolute; top:0; left: 0; width:calc(100% - 2px); height:calc(100% - 2px); border-radius: 8px; border: solid 1px rgba(0,0,0,0.08); z-index: 100;}
  .trendNewsWrap .rtNews ul:nth-child(3) .thumb img, .trendNewsWrap .rtNews ul:nth-child(4) .thumb img{ position: absolute; top:0; left: 0; width:100%; height: 100%; object-fit: cover;}
  .trendNewsWrap .rtNews ul:nth-child(3) .title, .trendNewsWrap .rtNews ul:nth-child(4) .title{ width:auto; padding:10px 0 0 0; font-size: 16px; line-height: 24px; font-weight: 600; margin-right: 160px; overflow: hidden; display: -webkit-box; -webkit-box-orient: vertical; word-break: break-all; -webkit-line-clamp: 1; text-overflow: ellipsis;}
  .trendNewsWrap .rtNews ul:nth-child(3) .txt, .trendNewsWrap .rtNews ul:nth-child(4) .txt{ padding: 0 0 0 0;  margin-top: 4px; font-size: 14px; line-height: 21px; color: var(--gray100); margin-right: 160px; overflow: hidden; display: -webkit-box;  -webkit-box-orient: vertical; word-break: break-all; -webkit-line-clamp: 2; text-overflow: ellipsis;}
  .trendNewsWrap .rtNews ul:nth-child(3){ position: relative; top:0; right: 0;}
  .trendNewsWrap .rtNews ul:nth-child(3)::before{display: none;}
  .trendNewsWrap .rtNews ul:nth-child(4){ position: relative; bottom:0; right: 0; border-bottom: 0;}
  .trendNewsWrap .rtNews ul:nth-child(3):hover .txt, .trendNewsWrap .rtNews ul:nth-child(4):hover .txt{ text-decoration: underline;}

  .dcTrend{ margin-left: 0; width:100%; padding: 0 0 0 0;}
  .dcTrend .dcTitle{ position: relative; line-height: 30px; font-size: 20px; font-weight: 700; color: var(--gray100); padding-left: 20px;}
  .dcTrend .dcTitle .arw{ position: absolute; top:3px; right: 20px; cursor: pointer;}
  .dcTrend ul{ width:280px; border: solid 1px var(--gray30); border-radius: 8px; margin-top: 16px; overflow: hidden;}
  .dcTrend ul .thumb{ position: relative; height:186px; overflow: hidden;}
  .dcTrend ul .thumb img{position: absolute; top:0; left: 0; width:100%; height: 100%; object-fit: cover;}
  .dcTrend ul .info{ position: relative; padding: 16px;}
  .dcTrend ul .info .date{ font-size: 12px; line-height: 18px; font-weight: 500; color: var(--gray70);}
  .dcTrend ul .info .title{ font-size: 16px; line-height: 24px; font-weight: 600; color: var(--gray110);}
  .dcTrend ul .info .btn{ position: absolute; top:calc(50% - 18px); right: 16px; border-radius: 8px; background-color: var(--gray20); width:49px; height: 36px; line-height: 36px; text-align: center; font-weight: 600; color: var(--gray90); cursor: pointer;}
  .dcTrend .swiper-slide{ height: auto; width:auto;}
  .dcTrend .swiper-slide:first-child{ margin-left: 20px;}
  .dcTrend .swiper-slide:last-child{ margin-right: 20px;}

  .trendNewsWrap .trendWidgetWrap{ width:calc(100% - 40px); padding: 40px 20px 0 20px;}

}

@media(max-width:480px) {
  .trendNewsWrap{ display: flex; flex-wrap: wrap; margin: 0 0 0 0;}
  .trendNewsWrap .mainNews{ position: relative; width:100%; height: auto;}
  .trendNewsWrap .mainNews .main{ position: relative; top:0; right: 0; width:100%; border-radius: 0; overflow: hidden; cursor: pointer;}
  .trendNewsWrap .mainNews .main .head{ z-index: 200; position: absolute; bottom:72px; left: 16px;  text-align: center; width:58px; height: 24px; line-height: 24px; background-color: var(--blue100); color: #fff; border-radius: 16px; font-size: 12px; font-weight: 600; margin: 16px 0 8px 0;}
  .trendNewsWrap .mainNews .main .title{ z-index: 200; position: absolute; bottom: 16px; left:16px; width:calc(100% - 32px); color: #fff; font-size: 20px; font-weight: 700; line-height: 30px; overflow: hidden; display: -webkit-box; -webkit-box-orient: vertical; word-break: break-all; -webkit-line-clamp: 2; text-overflow: ellipsis;}
  .trendNewsWrap .mainNews .main .txt{  font-size: 16px; line-height: 24px; color: var(--gray100); margin-top: 8px; overflow: hidden; display: none;  -webkit-box-orient: vertical; word-break: break-all; -webkit-line-clamp: 3; text-overflow: ellipsis;}
  .trendNewsWrap .mainNews .main .thumb{ position: relative; width:100%; height: 232px; border-radius: 0; overflow: hidden;}
  .trendNewsWrap .mainNews .main .thumb img{ position: absolute; top:0; left: 0; width:100%; height: 100%; object-fit: cover; z-index: 100;}
  .trendNewsWrap .mainNews .main:hover .txt{ text-decoration: none;}
  .trendNewsWrap .mainNews .main .mask{ position: absolute; bottom:0; left: 0; width:100%; height: 30%; background: linear-gradient(to bottom, #00000000, #000000); z-index: 100;}

  .trendNewsWrap .sideNewsTitle{ display: none; width:58px; height: 24px; line-height: 24px; font-size: 12px; text-align: center; border-radius: 16px; background: var(--blue30); color: var(--blue100); font-weight: 600; margin: 32px 0 16px 20px;}
  .trendNewsWrap .sideNews{ position: relative; width:calc(100% - 40px); height: auto; padding: 24px 20px 0 20px;}
  .trendNewsWrap .sideNews ul:nth-child(1){ position: relative; min-height: 91px; padding-bottom: 16px; border-bottom: solid 1px var(--gray30); margin-bottom: 16px; cursor: pointer;}
  .trendNewsWrap .sideNews ul:nth-child(1) .thumb{ position: absolute; top:0; right: 0; width:90px; height: 56px; border-radius: 4px; overflow: hidden; margin-bottom: 0;}
  .trendNewsWrap .sideNews ul:nth-child(1) .thumb img{ position: absolute; top:0; left: 0; width:100%; height: 100%; object-fit: cover; z-index: 100;}
  .trendNewsWrap .sideNews ul:nth-child(1) .title{ height: 48px; padding:4px 0 0 0; font-size: 16px; line-height: 24px; font-weight: 600; margin-right: 110px; -webkit-line-clamp: 2;}
  .trendNewsWrap .sideNews ul:nth-child(1) .txt{ margin-top: 14px; font-size: 14px; line-height: 21px; color: var(--gray100); margin-right: 0; overflow: hidden; display: -webkit-box;  -webkit-box-orient: vertical; word-break: break-all; -webkit-line-clamp: 2; text-overflow: ellipsis;}
  .trendNewsWrap .sideNews ul:nth-child(1):hover .txt{ text-decoration: underline;}

  .trendNewsWrap .sideNews ul:nth-child(2){ position: relative; min-height: auto; padding-bottom: 16px; border-bottom: solid 1px var(--gray30); margin-bottom: 16px; cursor: pointer;}
  .trendNewsWrap .sideNews ul:nth-child(2) .thumb{ position: absolute; top:0; right: 0; width:90px; height: 56px; border-radius: 4px; overflow: hidden; margin-bottom: 0;}
  .trendNewsWrap .sideNews ul:nth-child(2) .thumb img{ position: absolute; top:0; left: 0; width:100%; height: 100%; object-fit: cover; z-index: 100;}
  .trendNewsWrap .sideNews ul:nth-child(2) .title{ height: 48px; padding:4px 0 0 0; font-size: 16px; line-height: 24px; font-weight: 600; margin-right: 110px; -webkit-line-clamp: 2;}
  .trendNewsWrap .sideNews ul:nth-child(2) .txt{ margin-top: 14px; font-size: 14px; line-height: 21px; color: var(--gray100); margin-right: 0; overflow: hidden; display: none;  -webkit-box-orient: vertical; word-break: break-all; -webkit-line-clamp: 2; text-overflow: ellipsis;}
  .trendNewsWrap .sideNews ul:nth-child(2):hover .txt{ text-decoration: underline;}

  .trendNewsWrap .sideNews ul:nth-child(3){ position: relative; min-height: auto; padding-bottom: 16px; border-bottom: solid 0 var(--gray30); margin-bottom: 0; cursor: pointer;}
  .trendNewsWrap .sideNews ul:nth-child(3) .thumb{ position: absolute; top:0; right: 0; width:90px; height: 56px; border-radius: 4px; overflow: hidden; margin-bottom: 0;}
  .trendNewsWrap .sideNews ul:nth-child(3) .thumb img{ position: absolute; top:0; left: 0; width:100%; height: 100%; object-fit: cover; z-index: 100;}
  .trendNewsWrap .sideNews ul:nth-child(3) .title{ height: 48px; padding:4px 0 0 0; font-size: 16px; line-height: 24px; font-weight: 600; margin-right: 110px; -webkit-line-clamp: 2;}
  .trendNewsWrap .sideNews ul:nth-child(3) .txt{ margin-top: 14px; font-size: 14px; line-height: 21px; color: var(--gray100); margin-right: 0; overflow: hidden; display: none;  -webkit-box-orient: vertical; word-break: break-all; -webkit-line-clamp: 2; text-overflow: ellipsis;}
  .trendNewsWrap .sideNews ul:nth-child(3):hover .txt{ text-decoration: underline;}

  .trendNewsWrap .rtNews ul:nth-child(1), .trendNewsWrap .rtNews ul:nth-child(2){ width:100%; height: auto; min-height: 91px; padding-bottom: 16px; border-bottom: solid 1px var(--gray30); margin-bottom: 16px;}
  .trendNewsWrap .rtNews ul:nth-child(1) .thumb, .trendNewsWrap .rtNews ul:nth-child(2) .thumb{ position: absolute; top:0; right: 0; width:90px; height: 56px; border-radius: 4px; overflow: hidden; margin-bottom: 0;}
  .trendNewsWrap .rtNews ul:nth-child(1) .thumb::before, .trendNewsWrap .rtNews ul:nth-child(2) .thumb::before{ content: ''; position: absolute; top:0; left: 0; width:calc(100% - 2px); height:calc(100% - 2px); border-radius: 8px; border: solid 1px rgba(0,0,0,0.08); z-index: 100;}
  .trendNewsWrap .rtNews ul:nth-child(1) .thumb img, .trendNewsWrap .rtNews ul:nth-child(2) .thumb img{ position: absolute; top:0; left: 0; width:100%; height: 100%; object-fit: cover;}
  .trendNewsWrap .rtNews ul:nth-child(1) .title, .trendNewsWrap .rtNews ul:nth-child(2) .title{ height: 48px; padding:4px 0 0 0; font-size: 16px; line-height: 24px; font-weight: 600; margin-right: 110px; -webkit-line-clamp: 2;}
  .trendNewsWrap .rtNews ul:nth-child(1) .txt, .trendNewsWrap .rtNews ul:nth-child(2) .txt{ display: block; margin-top: 14px; font-size: 14px; line-height: 21px; color: var(--gray100); margin-right: 0; overflow: hidden; display: -webkit-box;  -webkit-box-orient: vertical; word-break: break-all; -webkit-line-clamp: 2; text-overflow: ellipsis;}
  .trendNewsWrap .rtNews ul:nth-child(1){ position: relative; top:0; left: 0;}
  .trendNewsWrap .rtNews ul:nth-child(2){ position: relative; top:0; left: 0;}
  .trendNewsWrap .rtNews ul:nth-child(3), .trendNewsWrap .rtNews ul:nth-child(4){ width:100%; height: auto; min-height: 91px; padding-bottom: 16px; border-bottom: solid 1px var(--gray30); margin-bottom: 16px;}
  .trendNewsWrap .rtNews ul:nth-child(3) .thumb, .trendNewsWrap .rtNews ul:nth-child(4) .thumb{ position: absolute; top:0; right: 0; width:90px; height: 56px; border-radius: 4px; overflow: hidden; margin-bottom: 0;}
  .trendNewsWrap .rtNews ul:nth-child(3) .thumb::before, .trendNewsWrap .rtNews ul:nth-child(4) .thumb::before{ content: ''; position: absolute; top:0; left: 0; width:calc(100% - 2px); height:calc(100% - 2px); border-radius: 8px; border: solid 1px rgba(0,0,0,0.08); z-index: 100;}
  .trendNewsWrap .rtNews ul:nth-child(3) .thumb img, .trendNewsWrap .rtNews ul:nth-child(4) .thumb img{ position: absolute; top:0; left: 0; width:100%; height: 100%; object-fit: cover;}
  .trendNewsWrap .rtNews ul:nth-child(3) .title, .trendNewsWrap .rtNews ul:nth-child(4) .title{ height: 48px; width:auto; padding:4px 0 0 0; font-size: 16px; line-height: 24px; font-weight: 600; margin-right: 110px; overflow: hidden; display: -webkit-box; -webkit-box-orient: vertical; word-break: break-all; -webkit-line-clamp: 2; text-overflow: ellipsis;}
  .trendNewsWrap .rtNews ul:nth-child(3) .txt, .trendNewsWrap .rtNews ul:nth-child(4) .txt{ padding: 0 0 0 0;  margin-top: 14px; font-size: 14px; line-height: 21px; color: var(--gray100); margin-right: 0; overflow: hidden; display: -webkit-box;  -webkit-box-orient: vertical; word-break: break-all; -webkit-line-clamp: 2; text-overflow: ellipsis;}
  .trendNewsWrap .rtNews ul:nth-child(3){ position: relative; top:0; right: 0;}
  .trendNewsWrap .rtNews ul:nth-child(3)::before{display: none;}
  .trendNewsWrap .rtNews ul:nth-child(4){ position: relative; bottom:0; right: 0; border-bottom: 0;}
  .trendNewsWrap .rtNews ul:nth-child(3):hover .txt, .trendNewsWrap .rtNews ul:nth-child(4):hover .txt{ text-decoration: underline;}

  .dcTrend{ margin-left: 0; width:calc(100% - 40px); padding: 0 20px 0 20px; overflow: auto; -webkit-overflow-scrolling: touch;}
  .dcTrend .dcTitle{ position: relative; line-height: 30px; font-size: 20px; font-weight: 700; color: var(--gray100); padding-left: 0;}
  .dcTrend .dcTitle .arw{ position: absolute; top:3px; right: 0; cursor: pointer;}
  .dcTrend ul{ width:calc(100% - 2px); border: solid 1px var(--gray30); border-radius: 8px; margin-top: 16px; overflow: hidden;}
  .dcTrend ul .thumb{ position: relative; height:186px; overflow: hidden;}
  .dcTrend ul .thumb img{position: absolute; top:0; left: 0; width:100%; height: 100%; object-fit: cover;}
  .dcTrend ul .info{ position: relative; padding: 16px;}
  .dcTrend ul .info .date{ font-size: 12px; line-height: 18px; font-weight: 500; color: var(--gray70);}
  .dcTrend ul .info .title{ font-size: 16px; line-height: 24px; font-weight: 600; color: var(--gray110);}
  .dcTrend ul .info .btn{ position: absolute; top:calc(50% - 18px); right: 16px; border-radius: 8px; background-color: var(--gray20); width:49px; height: 36px; line-height: 36px; text-align: center; font-weight: 600; color: var(--gray90); cursor: pointer;}
  .dcTrend .swiper-slide{ height: auto; width:auto;}
  .dcTrend .swiper-slide:first-child{ margin-left: 0;}
  .dcTrend .swiper-slide:last-child{ margin-right: 0;}
}


.sectionTitle{ position: relative; width:100%; padding: 40px 0 0 0; margin: 40px 0 20px 0; border-top:solid 1px var(--gray20); color: var(--blue100);}
.sectionTitle .title{ height: 36px; line-height: 36px; font-size: 24px; font-weight: 700;}
.sectionTitle .more{ display: flex; align-items: center; position: absolute; top:40px; right: 0; line-height: 36px; font-weight: 600; color: var(--gray90); cursor: pointer;}
.sectionTitle .more .arw{ margin-left: 4px;}

.sectionTitleSub{ position: relative; width:100%; margin: 60px 0 20px 0;}
.sectionTitleSub .title{ height: 36px; line-height: 36px; font-size: 24px; font-weight: 700;}
.sectionTitleSub .more{ display: flex; align-items: center; position: absolute; top:0; right: 0; line-height: 36px; font-weight: 600; color: var(--gray90); cursor: pointer;}
.sectionTitleSub .more .arw{ margin-left: 4px;}

.rankChart{ display: flex; flex-wrap: wrap;}
.rankChart .rankBox{ width:calc(33.3333333333333333333% - 16px); margin-left: 24px;}
.rankChart .rankBox:nth-child(1){ margin-left: 0;}
.rankChart .outWrap{ width:calc(33.3333333333333333333% - 11px);}
.rankChart .rankTitle{ display: flex; align-items: center; font-size: 18px; line-height: 27px; font-weight: 600; margin-bottom: 16px;}
.rankChart .rankTitle .arw{ cursor: pointer;}
.rankChart .box{ border: solid 1px var(--gray30); border-radius: 8px; overflow: hidden; box-shadow: 0 4px 2px 0 #10182805; margin-bottom: 10px;}
.rankChart .box .rank{ position: relative; height: 56px; background-color: var(--blue10); overflow: hidden;}
.rankChart .box .rank ul{ position: absolute; top:56px; left: 0; width:calc(100% - 32px); display: flex; height: 56px; align-items: center; padding: 0 16px 0 16px; transition: 0.5s; opacity: 0;}
.rankChart .box .rank ul.on{ top:0; opacity: 1;}
.rankChart .box .rank ul.off{ top:-48px; opacity: 0;}
.rankChart .box .rank ul .num{ width:12px; text-align: center; font-size: 18px; line-height: 24px; font-weight: 700; color: var(--blue100); margin-right: 16px;}
.rankChart .box .rank ul .thumb{ position: relative; margin-right: 8px; width:24px; height: 24px; border-radius: 8px; overflow: hidden;}
.rankChart .box .rank ul .thumb img{position: absolute; top:0; left: 0; width:100%; height: 100%; object-fit: cover;}
.rankChart .box .rank ul .title{ font-size: 16px; font-weight: 600;}
.rankChart .box .rank ul .vote{ margin-left: auto; font-size: 16px; font-weight: 700; color: #221E1F;}
.rankChart .box .news ul{ position: relative; display: flex; align-items: center; height: 88px; margin: 0 16px 0 16px; border-bottom: solid 1px var(--gray20); cursor: pointer;}
.rankChart .box .news a:last-child ul{ border-bottom: 0;}
.rankChart .box .news ul .num{ width:12px; text-align: center; font-size: 18px; font-weight: 700; color: var(--gray70); margin-right: 16px;}
.rankChart .box .news ul.on .num{ color: var(--blue100);}
.rankChart .box .news ul .title{ font-size: 16px; line-height: 24px; font-weight: 600; margin-right: 106px; overflow: hidden; display: -webkit-box;  -webkit-box-orient: vertical; word-break: break-all; -webkit-line-clamp: 2; text-overflow: ellipsis;}
.rankChart .box .news ul .thumb{ position: absolute; top:16px; right: 0; width:90px; height: 56px; border-radius: 8px; overflow: hidden;}
.rankChart .box .news ul .thumb img{position: absolute; top:0; left: 0; width:100%; height: 100%; object-fit: cover;}
.rankChart .box .news ul .thumb::before{ content: ''; position: absolute; top:0; left: 0; width:calc(100% - 2px); height:calc(100% - 2px); border-radius: 8px; border: solid 1px rgba(0,0,0,0.08); z-index: 100;}
.rankChart .box .news ul:hover .title{ text-decoration: underline;}

.rankChartWarp{ padding: 40px 0 0 0;}
.rankChartWarp .rankChart .rankBox{ width:calc(33.333333333333% - 11px); margin: 0 0 0 16px;}
.rankChartWarp .rankChart .rankBox:nth-child(3n+1){ margin: 0 0 0 0;}


.newsCategory{ display: flex;}
.newsCategory ul{ width:100%; margin-left: 16px; cursor: pointer;}
.newsCategory ul:nth-child(1){ margin-left: 0;}
.newsCategory ul .thumb{ position: relative; height: 179px; border-radius: 8px; margin-bottom: 16px; overflow: hidden;}
.newsCategory ul .thumb img{position: absolute; top:0; left: 0; width:100%; height: 100%; object-fit: cover;}
.newsCategory ul .thumb::before{ content: ''; position: absolute; top:0; left: 0; width:calc(100% - 2px); height:calc(100% - 2px); border-radius: 8px; border: solid 1px rgba(0,0,0,0.08); z-index: 100;}
.newsCategory ul .title{ margin-bottom: 4px; font-size: 20px; line-height: 30px; font-weight: 600; overflow: hidden; display: -webkit-box;  -webkit-box-orient: vertical; word-break: break-all; -webkit-line-clamp: 2; text-overflow: ellipsis;}
.newsCategory ul .txt{ font-size: 16px; line-height: 24px; color: #4E5058; overflow: hidden; display: -webkit-box;  -webkit-box-orient: vertical; word-break: break-all; -webkit-line-clamp: 2; text-overflow: ellipsis;}
.newsCategory ul:hover .title{ text-decoration: underline;}
.newsCategory .swiper-slide{ width: calc(25% - 12px);}

.keywordRank{ width:100%;}
.keywordRank .keyword{ width:100%; height: 39px;}
.keywordRank .keyword .items{ }
.keywordRank .keyword .items ul{ border: solid 1px var(--gray30); color: var(--gray70); padding: 8px 12px; border-radius: 8px; font-size: 14px; line-height: 21px; font-weight: 600; cursor: pointer; user-select: none;}
.keywordRank .keyword .items ul.on{ background-color: var(--blue100); border: solid 1px var(--blue100); color: #ffffff;}
.keywordRank .keyword .swiper-slide{ width: auto; height: auto;}

.keywordRank .result{ margin: 20px 0 0 0; height: 242px;}
.keywordRank .result ul{ width:180px; height: 240px; border: solid 1px var(--gray30); border-radius: 8px; text-align: center; user-select: none;}
.keywordRank .result ul:hover{ border: solid 1px var(--blue100);}
.keywordRank .result ul .thumb{ position: relative; width:74px; height: 74px; border-radius: 30px; background-color: var(--gray20); overflow: hidden; margin: 20px auto 12px;}
.keywordRank .result ul .thumb img{position: absolute; top:0; left: 0; width:100%; height: 100%; object-fit: cover;}
.keywordRank .result ul .thumb::before{ content: ''; position: absolute; top:0; left: 0; width:calc(100% - 2px); height:calc(100% - 2px); border-radius: 30px; border: solid 1px rgba(0,0,0,0.08); z-index: 100;}
.keywordRank .result ul .info{ display: flex; font-size: 16px; line-height: 24px; font-weight: 600; align-items: center; justify-content: center;}
.keywordRank .result ul .info .rank{ position: relative; color: var(--blue100); font-weight: 700; margin:0 4px 0 0; padding: 0 0 0 12px;}
.keywordRank .result ul .info .rank.up::before{ content: ''; position: absolute; top:0; left: 0; width:8px; height: 100%; background: url("https://cdn.trend.rankify.best/dctrend/front/images/ico_rank_up.svg") no-repeat center center; background-size: contain;}
.keywordRank .result ul .info .rank.same::before{ content: ''; position: absolute; top:0; left: 0; width:8px; height: 100%; background: url("https://cdn.trend.rankify.best/dctrend/front/images/ico_rank_same.svg") no-repeat center center; background-size: contain;}
.keywordRank .result ul .info .rank.down::before{ content: ''; position: absolute; top:0; left: 0; width:8px; height: 100%; background: url("https://cdn.trend.rankify.best/dctrend/front/images/ico_rank_down.svg") no-repeat center center; background-size: contain;}
.keywordRank .result ul .cate{ font-size: 12px; font-weight: 500; color: var(--gray60); margin-bottom: 4px;}
.keywordRank .result ul .vote{ font-size: 14px; font-weight: 600; line-height: 20px; color: var(--gray90); margin-bottom: 12px;}
.keywordRank .result ul .btn{ width:80px; line-height: 36px; border-radius: 8px; background-color: var(--gray20); font-size: 14px; font-weight: 600; color: var(--gray90); margin: 0 auto; cursor: pointer;}
.keywordRank .result .swiper-slide{ width: auto; text-align: center; background-color: #ffffff;}
.keywordRank .result .more{ display: flex; justify-content: center; align-items: center; border: solid 1px #ffffff;}
.keywordRank .result .more:hover{ border: solid 1px #ffffff;}
.keywordRank .result .more .all{ display: flex; height: 24px; align-items: center; justify-content: center; color: var(--blue100); font-weight: 600; cursor: pointer;}

.keywordRank .swiper-button-next {
  position: absolute;
  top: 50%;
  right: 5px;
  width: 40px;
  height: 40px;
  background: url("https://cdn.trend.rankify.best/dctrend/front/images/btn_page_next.svg");
  background-size: contain;
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.keywordRank .swiper-button-prev {
  position: absolute;
  top: 50%;
  left: 5px;
  width: 40px;
  height: 40px;
  background: url("https://cdn.trend.rankify.best/dctrend/front/images/btn_page_prev.svg");
  background-size: contain;
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.keywordRank .swiper-button-prev.swiper-button-disabled,.keywordRank .swiper-button-next.swiper-button-disabled {
  opacity: 0;
  cursor: auto;
  pointer-events: none;
}
.keywordRank .swiper-button-next::after{ display: none;}
.keywordRank .swiper-button-prev::after{ display: none;}

@media(max-width:800px) {
  .sectionTitle{ position: relative; width:calc(100% - 40px); padding: 40px 20px 0 20px; margin: 0 0 20px 0; border-top:0;}
  .sectionTitle .title{ height: 30px; line-height: 30px; font-size: 20px; font-weight: 700;}
  .sectionTitle .more{ display: flex; align-items: center; position: absolute; top:40px; right: 20px; line-height: 30px; font-weight: 600; color: var(--gray90); cursor: pointer;}
  .sectionTitle .more .arw{ margin-left: 4px;}

  .sectionTitleSub{ position: relative; width:calc(100% - 40px); padding: 0 20px 0 20px; margin: 40px 0 20px 0;}
  .sectionTitleSub .title{ height: 30px; line-height: 30px; font-size: 20px; font-weight: 700;}
  .sectionTitleSub .more{ display: flex; align-items: center; position: absolute; top:0; right: 20px; line-height: 30px; font-weight: 600; color: var(--gray90); cursor: pointer;}
  .sectionTitleSub .more .arw{ margin-left: 4px;}

  .inScrollRank{ position: relative; width:100%; overflow-x: auto;}
  .inScrollRank::-webkit-scrollbar {
    display: none; /* 웹킷 기반 브라우저에서 스크롤바 숨기기 */
  }
  .rankChart{ display: flex; flex-wrap: wrap; width:1340px; padding: 0 0 0 0;}
  .rankChart.no700{ display: flex; flex-wrap: wrap; width:100%; padding: 0 0 0 0;}
  .rankChart .rankBox{ width:417px; margin-left: 24px;}
  .rankChart .rankBox:nth-child(1){ margin-left: 20px;}
  .rankChart .swiper-slide:first-child{ margin-left: 20px;}
  .rankChart .swiper-slide:last-child{ margin-right: 20px;}

  .rankChartWarp{ padding: 20px 20px 0 20px;}
  .rankChartWarp .rankChart .rankBox{ width:100%; margin: 0 0 32px 0;}
  .rankChartWarp .rankChart .rankBox:nth-child(3n+1){ margin: 0 0 32px 0;}
  .rankChartWarp .rankChart .rankBox:last-child{ margin: 0 0 0 0;}
  .rankChartWarp .rankChart .box .news ul{ position: relative; display: flex; align-items: center; height: 70px; margin: 0 16px 0 16px; border-bottom: solid 1px var(--gray20); cursor: pointer;}
  .rankChartWarp .rankChart .box .news ul:last-child{ border-bottom: 0;}
  .rankChartWarp .rankChart .box .news ul .num{ width:12px; text-align: center; font-size: 18px; line-height: 24px; font-weight: 700; color: var(--gray70); margin-right: 16px;}
  .rankChartWarp .rankChart .box .news ul.on .num{ color: var(--blue100);}
  .rankChartWarp .rankChart .box .news ul .title{ font-size: 16px; line-height: 24px; font-weight: 600; margin-right: 93px; overflow: hidden; display: -webkit-box;  -webkit-box-orient: vertical; word-break: break-all; -webkit-line-clamp: 2; text-overflow: ellipsis;}
  .rankChartWarp .rankChart .box .news ul .thumb{ position: absolute; top:11px; right: 0; width:77px; height: 48px; border-radius: 8px; overflow: hidden;}

  .newsCategory{ display: flex; width:100%; padding: 0 0 0 0;}
  .newsCategory ul .title{ margin-bottom: 4px; font-size: 18px; line-height: 27px; font-weight: 600; overflow: hidden; display: -webkit-box;  -webkit-box-orient: vertical; word-break: break-all; -webkit-line-clamp: 2; text-overflow: ellipsis;}
  .newsCategory ul .txt{ font-size: 16px; line-height: 24px; color: #4E5058; overflow: hidden; display: -webkit-box;  -webkit-box-orient: vertical; word-break: break-all; -webkit-line-clamp: 2; text-overflow: ellipsis;}
  .newsCategory .swiper-slide{ width: 280px;}
  .newsCategory .swiper-slide:first-child{ margin-left: 20px;}
  .newsCategory .swiper-slide:last-child{ margin-right: 20px;}

  .keywordRank{ width:100%; padding: 0 0 0 0;}
  .keywordRank.noPd{ width:100%; padding: 0 0 0 0;}
  .keywordRank.noPd .swiper-wrapper{ width:calc(100% - 40px); padding: 0 0 0 20px;}
  .keywordRank.noPd .result .swiper-wrapper .swiper-slide:last-child{ margin-right: 40px;}
  .keywordRank.noPd .result .swiper-wrapper .swiper-slide:last-child{ margin-right: 40px;}
  .keywordRank .result{ margin: 20px 0 0 0; height: 242px;}
  .keywordRank.noPd .result{ margin: 20px 0 40px 0;}
  .keywordRank .swiper-button-next, .keywordRank .swiper-button-prev { display: none;}
  .keywordRank .swiper-slide:first-child{ margin-left: 20px;}
  .keywordRank .swiper-slide:last-child{ margin-right: 20px;}
}

@media(max-width:480px) {
  .rankChart .swiper-wrapper{ display: block; width:100%;}
  .rankChart{ display: block; flex-wrap: wrap; width:calc(100% - 40px); padding: 0 20px 0 20px;}
  .rankChart.no700{ width:calc(100% - 40px); padding: 0 20px 0 20px;}
  .rankChart .swiper-slide:first-child{ margin-left: 0;}
  .rankChart .swiper-slide:last-child{ margin-right: 0;}

  .inScrollRank{ position: relative; width:100%; overflow-x: auto;}
  .inScrollRank::-webkit-scrollbar {
    display: none; /* 웹킷 기반 브라우저에서 스크롤바 숨기기 */
  }

  .rankChart .rankBox{ width:100%; margin-left: 0; margin-bottom: 32px;}
  .rankChart .rankBox:nth-child(1){ margin-left: 0;}
  .rankChart .rankBox:last-child{ margin-bottom: 0;}

  .rankChartWarp{ padding: 20px 0 0 0;}
  .rankChartWarp .rankChart .rankBox{ width:100%; margin: 0 0 32px 0;}
  .rankChartWarp .rankChart .rankBox:nth-child(3n+1){ margin: 0 0 32px 0;}
  .rankChartWarp .rankChart .rankBox:last-child{ margin: 0 0 0 0;}
  .rankChartWarp .rankChart .box .news ul{ position: relative; display: flex; align-items: center; height: 88px; margin: 0 16px 0 16px; border-bottom: solid 1px var(--gray20); cursor: pointer;}
  .rankChartWarp .rankChart .box .news ul:last-child{ border-bottom: 0;}
  .rankChartWarp .rankChart .box .news ul .num{ width:12px; text-align: center; font-size: 18px; line-height: 24px; font-weight: 700; color: var(--gray70); margin-right: 16px;}
  .rankChartWarp .rankChart .box .news ul.on .num{ color: var(--blue100);}
  .rankChartWarp .rankChart .box .news ul .title{ font-size: 16px; line-height: 24px; font-weight: 600; margin-right: 106px; overflow: hidden; display: -webkit-box;  -webkit-box-orient: vertical; word-break: break-all; -webkit-line-clamp: 2; text-overflow: ellipsis;}
  .rankChartWarp .rankChart .box .news ul .thumb{ position: absolute; top:16px; right: 0; width:90px; height: 56px; border-radius: 8px; overflow: hidden;}

}

.newsLayout{ display: flex; padding: 40px 0 0 0;}
.newsLayout .leftWrap{ width:836px;}
.newsLayout .leftWrap .newsTitle{ position: relative; height: 48px; padding-bottom: 15px; border-bottom: solid 1px var(--gray120);}
.newsLayout .leftWrap .newsTitle .title{ font-size: 32px; line-height: 48px; font-weight: 700; color: #000000;}
.newsLayout .leftWrap .newsTitle .category{ position: absolute; display: inline-flex; bottom: 15px; right: 0;}
.newsLayout .leftWrap .newsTitle .category ul{ line-height: 35px; margin-left: 24px; font-size: 18px; font-weight: 600; color: var(--gray90); cursor: pointer;}
.newsLayout .leftWrap .newsTitle .category ul.on{ color: var(--blue100);}
.newsList .list{ padding: 0 0 0 0;}
.newsList .list ul{ display: flex; flex-wrap: wrap; align-items: center; position: relative; height: 132px; margin-bottom: 40px; cursor: pointer;}
.newsList .list ul:last-child::before{ display:none;}
.newsList .list ul::before{ content: ''; position: absolute; bottom: -20px; left: 0; width:100%; height: 1px; background-color: var(--gray20);}
.newsList .list ul .thumb{ position: absolute; top:0; left: 0; width:212px; height: 132px; border-radius: 8px; overflow: hidden;}
.newsList .list ul .thumb img{position: absolute; top:0; left: 0; width:100%; height: 100%; object-fit: cover;}
.newsList .list ul .thumb::before{ content: ''; position: absolute; top:0; left: 0; width:calc(100% - 2px); height:calc(100% - 2px); border-radius: 8px; border: solid 1px rgba(0,0,0,0.08); z-index: 100;}
.newsList .list ul a{ width:calc(100% - 228px); margin-left: auto;}
.newsList .list ul li{}
.newsList .list ul .tit{ font-size: 20px; line-height: 30px; font-weight: 600; color: var(--gray120); overflow: hidden; display: -webkit-box;  -webkit-box-orient: vertical; word-break: break-all; -webkit-line-clamp: 1; text-overflow: ellipsis;}
.newsList .list ul .txt{ font-size: 16px; line-height: 24px; color: #4E5058; overflow: hidden; display: -webkit-box;  -webkit-box-orient: vertical; word-break: break-all; -webkit-line-clamp: 2; text-overflow: ellipsis;}
.newsList .list ul .info{ line-height: 21px; color: var(--gray60);}
.newsList .list ul:hover .txt{ text-decoration: underline;}

.newsLayout .rightWrap{ width:300px; margin-left: auto;}
.newsLayout .rightWrap .rightSticky{}
.newsLayout .rightWrap .rightSticky .stickyTitle{ font-size: 20px; line-height: 30px; font-weight: 700; color: #000; margin-bottom: 10px;}
.newsLayout .rightWrap .rightSticky .stickyTitle span{ color: var(--blue100);}
.newsLayout .rightWrap .rightSticky .dcTrend{ margin-left: 0; width:100%; margin-bottom: 40px;}
.newsLayout .rightWrap .rightSticky .dcTrend .dcTitle{ position: relative; line-height: 30px; font-size: 20px; font-weight: 700; color: var(--gray120);}
.newsLayout .rightWrap .rightSticky .dcTrend .dcTitle .arw{ position: absolute; top:6px; right: 0; cursor: pointer;}
.newsLayout .rightWrap .rightSticky .dcTrend .swiper-wrapper{ height: auto !important;}

.rankNews{ position: relative;}
.rankChartRight{ display: flex; flex-wrap: wrap;}
.rankChartRight .rankTitle{ display: flex; align-items: center; font-size: 16px; line-height: 40px; font-weight: 700; padding: 0 16px 0 16px;}
.rankChartRight .rankTitle .arw{ position: absolute; top:10px; right: 8px; cursor: pointer;}
.rankChartRight .box{ border: solid 1px var(--gray30); border-radius: 8px; overflow: hidden; padding-bottom: 36px;}
.rankChartRight .box .rank{ position: relative; height: 48px; background-color: var(--blue10); overflow: hidden;}
.rankChartRight .box .rank ul{ position: absolute; top:48px; left: 0; width:calc(100% - 32px); display: flex; height: 48px; align-items: center; padding: 0 16px 0 16px; transition: 0.5s; opacity: 0;}
.rankChartRight .box .rank ul.on{ top:0; opacity: 1;}
.rankChartRight .box .rank ul.off{ top:-48px; opacity: 0;}
.rankChartRight .box .rank ul .num{ width:12px; text-align: center; font-size: 16px; line-height: 24px; font-weight: 700; color: var(--blue100); margin-right: 16px;}
.rankChartRight .box .rank ul .thumb{ position: relative; margin-right: 8px; width:24px; height: 24px; border-radius: 8px; overflow: hidden;}
.rankChartRight .box .rank ul .thumb img{position: absolute; top:0; left: 0; width:100%; height: 100%; object-fit: cover;}
.rankChartRight .box .rank ul .title{ font-size: 14px; font-weight: 600;}
.rankChartRight .box .rank ul .vote{ margin-left: auto; font-size: 14px; font-weight: 700; color: #221E1F;}
.rankChartRight .box .news ul{ position: relative; display: flex; align-items: center; height: 70px; margin: 0 16px 0 16px; border-bottom: solid 1px var(--gray20); cursor: pointer;}
.rankChartRight .box .news ul:last-child{ border-bottom: 0;}
.rankChartRight .box .news ul .num{ width:12px; line-height: 21px; text-align: center; font-size: 18px; font-weight: 700; color: var(--gray70); margin-right: 16px;}
.rankChartRight .box .news ul .title{ font-size: 14px; line-height: 21px; font-weight: 600; margin-right: 90px; overflow: hidden; display: -webkit-box;  -webkit-box-orient: vertical; word-break: break-all; -webkit-line-clamp: 2; text-overflow: ellipsis;}
.rankChartRight .box .news ul .thumb{ position: absolute; top:11px; right: 0; width:77px; height: 48px; border-radius: 8px; overflow: hidden;}
.rankChartRight .box .news ul .thumb img{position: absolute; top:0; left: 0; width:100%; height: 100%; object-fit: cover;}
.rankChartRight .box .news ul .thumb::before{ content: ''; position: absolute; top:0; left: 0; width:calc(100% - 2px); height:calc(100% - 2px); border-radius: 8px; border: solid 1px rgba(0,0,0,0.08); z-index: 100;}
.rankChartRight .box .news ul:hover .title{ text-decoration: underline;}
.rankChartRight .box .news ul.on .num{ color: var(--blue100);}
.rankChartRight .swiper-pagination.swiper-pagination-bullets.swiper-pagination-horizontal { position: absolute; bottom: 16px !important;}

.rankChartRight .swiper-button-next {
  position: absolute;
  top: calc(100% - 14px);
  right: 10px;
  width: 24px;
  height: 24px;
  background: url("https://cdn.trend.rankify.best/dctrend/front/images/btn_page_next2.svg");
  background-size: contain;
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.rankChartRight .swiper-button-prev {
  position: absolute;
  top: calc(100% - 14px);
  left: 10px;
  width: 24px;
  height: 24px;
  background: url("https://cdn.trend.rankify.best/dctrend/front/images/btn_page_prev2.svg");
  background-size: contain;
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.rankChartRight .swiper-button-prev.swiper-button-disabled,.rankChartRight .swiper-button-next.swiper-button-disabled {
  opacity: 0.5;
  cursor: auto;
  pointer-events: none;
}
.rankChartRight .swiper-button-next::after{ display: none;}
.rankChartRight .swiper-button-prev::after{ display: none;}

.popularNewsRight{ margin-bottom: 40px;}
.popularNewsRight ul{ display: flex; align-items: center; line-height: 27px; margin-bottom: 11px; padding-bottom: 11px; border-bottom: solid 1px var(--gray20); cursor:pointer;}
.popularNewsRight ul li:nth-child(1){ width:28px; text-align: center; font-size: 16px; font-weight: 700; color: var(--blue100); padding: 0 4px 0 0;}
.popularNewsRight ul li:nth-child(2){ width:calc(100% - 122px); font-size: 14px; line-height: 21px; font-weight: 600; overflow: hidden; display: -webkit-box;  -webkit-box-orient: vertical; word-break: break-all; -webkit-line-clamp: 2; text-overflow: ellipsis;}
.popularNewsRight ul li:nth-child(3){ position: relative; width:77px; height: 48px; border-radius: 8px; overflow: hidden; margin-left: auto;}
.popularNewsRight ul li:nth-child(3) img{position: absolute; top:0; left: 0; width:100%; height: 100%; object-fit: cover;}
.popularNewsRight ul li:nth-child(3)::before{ content: ''; position: absolute; top:0; left: 0; width:calc(100% - 2px); height:calc(100% - 2px); border-radius: 8px; border: solid 1px rgba(0,0,0,0.08); z-index: 100;}
.popularNewsRight ul:hover li:nth-child(2){text-decoration: underline;}
.popularNewsRight a:last-child ul{ border-bottom: 0; margin-bottom: 0; padding-bottom: 0;}

.rtNewsRight{ display: flex; flex-wrap: wrap;}
.rtNewsRight ul{ width:calc(50% - 4px); height: 143px; cursor: pointer;}
.rtNewsRight ul:nth-child(2n+1){ margin: 0 8px 0 0;}
.rtNewsRight ul:nth-child(3),.rtNewsRight ul:nth-child(4){ margin-top: 16px;}
.rtNewsRight ul li:nth-child(1){ position: relative; width:100%; height: 91px; border-radius: 8px; overflow: hidden;}
.rtNewsRight ul li:nth-child(1) img{position: absolute; top:0; left: 0; width:100%; height: 100%; object-fit: cover;}
.rtNewsRight ul li:nth-child(1)::before{ content: ''; position: absolute; top:0; left: 0; width:calc(100% - 2px); height:calc(100% - 2px); border-radius: 8px; border: solid 1px rgba(0,0,0,0.08); z-index: 100;}
.rtNewsRight ul li:nth-child(2){ font-weight: 600; line-height: 21px; margin-top: 8px; overflow: hidden; display: -webkit-box;  -webkit-box-orient: vertical; word-break: break-all; -webkit-line-clamp: 2; text-overflow: ellipsis;}
.rtNewsRight ul:hover li:nth-child(2){ text-decoration: underline;}

.newsLayout .leftWrap .trendWidgetWrap{ width:100%; padding: 80px 0 0 0;}

@media(max-width:800px) {
  .newsLayout{ display: flex; flex-wrap: wrap; padding: 0 0 0 0;}
  .newsLayout .leftWrap{ width:100%}
  .newsLayout .leftWrap .articleWrap{ padding: 20px 20px 0 20px;}
  .newsLayout .leftWrap .newsTitle{ position: relative; height: 36px; padding-bottom: 15px; margin-left: 20px; margin-right: 20px; border-bottom: solid 1px var(--gray120);}
  .newsLayout .leftWrap .newsTitle .title{ font-size: 24px; line-height: 36px; font-weight: 700; color: #000000;}
  .newsLayout .leftWrap .newsTitle .category{ position: absolute; display: inline-flex; bottom: 15px; right: 0;}
  .newsLayout .leftWrap .newsTitle .category ul{ line-height: 35px; margin-left: 24px; font-size: 18px; font-weight: 600; color: var(--gray90); cursor: pointer;}
  .newsLayout .leftWrap .newsTitle .category ul.on{ color: var(--blue100);}
  .newsList .list{ padding: 24px 20px 0 20px;}
  .newsList .list.full{ padding: 24px 0 0 0;}
  .newsList .list ul{ display: flex; flex-wrap: wrap; align-items: center; position: relative; height: 91px; margin-bottom: 32px; cursor: pointer;}
  .newsList .list ul:last-child::before{ display:none;}
  .newsList .list ul::before{ content: ''; position: absolute; bottom: -16px; left: 0; width:100%; height: 1px; background-color: var(--gray20);}
  .newsList .list ul .thumb{ position: absolute; top:0; left: auto; right: 0; width:147px; height: 91px; border-radius: 8px; overflow: hidden;}
  .newsList .list ul .thumb img{position: absolute; top:0; left: 0; width:100%; height: 100%; object-fit: cover;}
  .newsList .list ul .thumb::before{ content: ''; position: absolute; top:0; left: 0; width:calc(100% - 2px); height:calc(100% - 2px); border-radius: 8px; border: solid 1px rgba(0,0,0,0.08); z-index: 100;}
  .newsList .list ul a{ width:calc(100% - 163px); margin-left: 0;}
  .newsList .list ul li{}
  .newsList .list ul .tit{ font-size: 16px; line-height: 24px; font-weight: 600; color: var(--gray120); overflow: hidden; display: -webkit-box;  -webkit-box-orient: vertical; word-break: break-all; -webkit-line-clamp: 1; text-overflow: ellipsis;}
  .newsList .list ul .txt{ font-size: 14px; line-height: 21px; color: #4E5058; overflow: hidden; display: -webkit-box;  -webkit-box-orient: vertical; word-break: break-all; -webkit-line-clamp: 2; text-overflow: ellipsis;}
  .newsList .list ul .info{ line-height: 21px; color: var(--gray60);}
  .newsList .list ul:hover .txt{ text-decoration: underline;}

  .newsLayout .rightWrap{ width:100%; padding: 40px 0 0 0; margin-left: auto;}
  .newsLayout .rightWrap .rightSticky{}
  .newsLayout .rightWrap .rightSticky .stickyTitle{ font-size: 20px; line-height: 30px; font-weight: 700; color: #000; margin-bottom: 10px; padding: 0 20px 0 20px;}
  .rankChartRight{ display: flex; flex-wrap: wrap; padding: 0 20px 0 20px;}
  .popularNewsRight{ padding: 0 20px 0 20px;}
  .rankChartRight .swiper-button-next {right: calc(50% - 150px);}
  .rankChartRight .swiper-button-prev {left: calc(50% - 150px);}
  .newsLayout .rightWrap .rightSticky .dcTrend .swiper-wrapper .swiper-slide:last-child{ margin-right: 20px;}
  .newsLayout .rightWrap .rightSticky .dcTrend .dcTitle{ position: relative; line-height: 30px; font-size: 20px; font-weight: 700; color: var(--gray120);}
  .newsLayout .rightWrap .rightSticky .dcTrend .dcTitle .arw{ position: absolute; top:3px; right: 20px; cursor: pointer;}

  .rtNewsRight{ display: flex; flex-wrap: wrap; margin: 0 20px 0 20px;}
  .rtNewsRight ul{ width:calc(25% - 12px); height: auto; cursor: pointer; margin-left: 16px;}
  .rtNewsRight ul:nth-child(2n+1){ margin: 0 0 0 16px;}
  .rtNewsRight ul:nth-child(1){ margin-left: 0;}
  .rtNewsRight ul:nth-child(3),.rtNewsRight ul:nth-child(4){ margin-top: 0;}
  .rtNewsRight ul li:nth-child(1){ position: relative; width:100%; height: 91px; border-radius: 8px; overflow: hidden;}
  .rtNewsRight ul li:nth-child(1) img{position: absolute; top:0; left: 0; width:100%; height: 100%; object-fit: cover;}
  .rtNewsRight ul li:nth-child(1)::before{ content: ''; position: absolute; top:0; left: 0; width:calc(100% - 2px); height:calc(100% - 2px); border-radius: 8px; border: solid 1px rgba(0,0,0,0.08); z-index: 100;}
  .rtNewsRight ul li:nth-child(2){ font-weight: 600; line-height: 21px; margin-top: 8px; overflow: hidden; display: -webkit-box;  -webkit-box-orient: vertical; word-break: break-all; -webkit-line-clamp: 2; text-overflow: ellipsis;}
  .rtNewsRight ul:hover li:nth-child(2){ text-decoration: underline;}

  .newsLayout .leftWrap .trendWidgetWrap{ width:calc(100% - 40px); padding: 40px 20px 0 20px;}
}

@media(max-width:480px) {
  .newsLayout{ display: flex; flex-wrap: wrap; padding: 0 0 0 0;}
  .newsLayout .leftWrap .newsTitle{ position: relative; height: auto; padding-bottom: 8px; border-bottom: solid 1px var(--gray120);}
  .newsLayout .leftWrap .newsTitle .title{ font-size: 24px; line-height: 36px; font-weight: 700; color: #000000; margin-bottom: 8px;}
  .newsLayout .leftWrap .newsTitle .category{ position: relative; display: inline-flex; bottom: 0; right: 0;}
  .newsLayout .leftWrap .newsTitle .category ul{ line-height: 35px; margin-left: 0; margin-right: 24px;  font-size: 18px; font-weight: 600; color: var(--gray90); cursor: pointer;}
  .newsLayout .leftWrap .newsTitle .category ul.on{ color: var(--blue100);}
  .newsList .list{ padding: 24px 20px 0 20px;}
  .newsList .list.full{ padding: 24px 0 0 0;}
  .newsList .list ul{ display: block; flex-wrap: wrap; position: relative; height: 132px; margin-bottom: 32px; cursor: pointer;}
  .newsList .list ul:last-child::before{ display:none;}
  .newsList .list ul::before{ content: ''; position: absolute; bottom: -16px; left: 0; width:100%; height: 1px; background-color: var(--gray20);}
  .newsList .list ul .thumb{ position: absolute; top:0; left: auto; right: 0; width:90px; height: 56px; border-radius: 8px; overflow: hidden;}
  .newsList .list ul .thumb img{position: absolute; top:0; left: 0; width:100%; height: 100%; object-fit: cover;}
  .newsList .list ul .thumb::before{ content: ''; position: absolute; top:0; left: 0; width:calc(100% - 2px); height:calc(100% - 2px); border-radius: 8px; border: solid 1px rgba(0,0,0,0.08); z-index: 100;}
  .newsList .list ul a{ width:100%; margin-left: 0;}
  .newsList .list ul li{}
  .newsList .list ul .tit{ width:calc(100% - 100px); height: 48px; padding: 4px 0 4px 0; font-size: 16px; line-height: 24px; font-weight: 600; color: var(--gray120); overflow: hidden; display: -webkit-box;  -webkit-box-orient: vertical; word-break: break-all; -webkit-line-clamp: 2; text-overflow: ellipsis;}
  .newsList .list ul .txt{ margin-top: 8px; font-size: 14px; line-height: 21px; color: #4E5058; overflow: hidden; display: -webkit-box;  -webkit-box-orient: vertical; word-break: break-all; -webkit-line-clamp: 2; text-overflow: ellipsis;}
  .newsList .list ul .info{ margin-top: 8px; line-height: 21px; color: var(--gray60);}
  .newsList .list ul:hover .txt{ text-decoration: underline;}
  .rankChartRight .swiper-button-next {right: calc(50% - 100px);}
  .rankChartRight .swiper-button-prev {left: calc(50% - 100px);}
  .newsLayout .rightWrap .rightSticky .dcTrend{  width:calc(100% - 40px); padding: 0 20px 0 20px;}
  .newsLayout .rightWrap .rightSticky .dcTrend .swiper-wrapper .swiper-slide:last-child{ margin-right: 0;}
  .newsLayout .rightWrap .rightSticky .dcTrend .dcTitle .arw{ position: absolute; top:3px; right: 0; cursor: pointer;}

  .rtNewsRight{ display: flex; flex-wrap: wrap; margin: 0 20px 0 20px;}
  .rtNewsRight ul{ width:calc(50% - 4px); height: auto; cursor: pointer; margin-left: 0;}
  .rtNewsRight ul:nth-child(2n+1){ margin: 0 8px 0 0;}
  .rtNewsRight ul:nth-child(1){ margin-left: 0;}
  .rtNewsRight ul:nth-child(3),.rtNewsRight ul:nth-child(4){ margin-top: 16px;}
}


.articleWrap{}
.articleWrap .category{ height: 39px; line-height: 39px; color: var(--gray100); font-weight: 700;}
.articleWrap .articleTitle{ font-weight: 700; color: #000; line-height: 130%; margin: 10px 0 15px 0;}
.articleWrap .ftSz12{ font-size: 12px;}
.articleWrap .ftSz14{ font-size: 14px;}
.articleWrap .ftSz16{ font-size: 16px;}
.articleWrap .ftSz18{ font-size: 18px;}
.articleWrap .ftSz20{ font-size: 20px;}
.articleWrap .ftSz32{ font-size: 32px;}
.articleWrap .ftSz34{ font-size: 34px;}
.articleWrap .ftSz36{ font-size: 36px;}
.articleWrap .ftSz38{ font-size: 38px;}
.articleWrap .info{ display: flex; height: 40px; align-items: center; border-bottom: solid 1px #e9eaed;}
.articleWrap .info .publisher{ position: relative; line-height: 24px; font-weight: 700; color: var(--gray90); margin-right: 21px;}
.articleWrap .info .publisher::before{ content: ''; position: absolute; top:5px; right: -10px; width:1px; height: 14px; background-color:#e2e3e6;}
.articleWrap .info .date{ position: relative; font-size: 13px; line-height: 24px; color: var(--gray90);}
.articleWrap .info .btn{ margin-left: auto;}
.articleWrap .info .btn img{ margin-left: 16px; cursor: pointer;}
.articleWrap .content{ padding: 40px 0 40px 0; line-height: 170%; color: #000000; border-bottom: solid 2px #d3d5db; min-height: 600px;}
.articleWrap .content p{ margin-bottom: 24px;}
.articleWrap .content h2{ position: relative; padding: 0 0 0 20px; margin: 0 0 30px 0;}
.articleWrap .content h2::before{ content: ''; position: absolute; top:0; left: 0; width:4px; height: 100%; background-color: #000;}
.articleWrap .content h2 p{ margin-bottom: 0; line-height: 32px; font-size: 18px; font-weight: 700;}
.articleWrap .content img{ max-width: 100%;}
.articleWrap .content figcaption{ padding: 10px 0; color: var(--gray70);}
.articleWrap .publisherBot{ font-size: 18px; line-height: 32px;}
.articleWrap .tag{ display: flex; flex-wrap: wrap; margin-top: 30px;}
.articleWrap .tag ul{ line-height: 30px; padding: 0 10px 0 10px; border-radius: 4px; background-color: #EBEEF1; color: #5F646F; margin: 0 8px 8px 0;}

.newsVote{ display: flex; justify-content: center; align-items: center; margin: 20px 0 0 0; height: 184px;}
.newsVote ul{ width:96px; text-align: center; margin: 0 0 0 40px; cursor: pointer;}
.newsVote ul:nth-child(1){ margin: 0 0 0 0;}
.newsVote ul li{ margin-bottom: 8px;}
.newsVote ul li:nth-child(2){ font-weight: 600; line-height: 20px; color: var(--gray70);}
.newsVote ul li:nth-child(3){ font-weight: 700; font-size: 16px; line-height: 24px; color: #000;}
.newsVote ul.voted li:nth-child(2){ color: var(--blue100);}
.newsVote ul.voted li:nth-child(3){ color: var(--blue100);}

.articleWrap .layerBox{ position: relative;}
.articleWrap .layerBox .box{ position: absolute; right: 20px; top:20px; width:312px; padding: 22px 24px 20px 24px; border-radius: 16px; background-color: #fff; box-shadow: 0 0 20px 0 #00000033; z-index: 300;}
.articleWrap .layerBox .box .tit{ font-size: 18px; font-weight: 700; line-height: 28px;}
.articleWrap .layerBox .box .close{ position: absolute; top:20px; right: 24px; cursor: pointer;}
.articleWrap .layerBox .box.share .cont{ display: flex; align-items: center; justify-content: center; height: 80px; margin-top: 10px;}
.articleWrap .layerBox .box.share .cont ul{ text-align: center; cursor: pointer; padding: 16px; border-radius: 8px;}
.articleWrap .layerBox .box.share .cont ul .icoUrl{ width:33px; height: 33px; margin: 0 auto; background: url("https://cdn.trend.rankify.best/dctrend/front/images/ico_share_url.svg") no-repeat center center; background-size: contain;}
.articleWrap .layerBox .box.share .cont ul .icoKakao{ width:33px; height: 33px; margin: 0 auto; background: url("https://cdn.trend.rankify.best/dctrend/front/images/ico_share_kakao.svg") no-repeat center center; background-size: contain;}
.articleWrap .layerBox .box.share .cont ul:nth-child(1){ margin-right: 40px;}
.articleWrap .layerBox .box.share .cont ul li:nth-child(2){ font-weight: 600; line-height: 20px; color: var(--gray100); padding-top: 8px;}
.articleWrap .layerBox .box.share .cont ul:hover{ background-color: var(--gray20);}
.articleWrap .layerBox .box.font .cont{ display: flex; align-items: center; justify-content: center; height: 84px; margin-top: 20px;}
.articleWrap .layerBox .box.font .cont ul{ width:32%; text-align: center; cursor: pointer;}
.articleWrap .layerBox .box.font .cont ul li:nth-child(1){ display: inline-flex; align-items: center; justify-content: center; width:54px; height: 54px; border-radius: 54px; border: solid 1px var(--gray40); font-weight: 600;}
.articleWrap .layerBox .box.font .cont ul:nth-child(1) li:nth-child(1){ font-size: 16px;}
.articleWrap .layerBox .box.font .cont ul:nth-child(2) li:nth-child(1){ font-size: 20px;}
.articleWrap .layerBox .box.font .cont ul:nth-child(3) li:nth-child(1){ font-size: 24px;}
.articleWrap .layerBox .box.font .cont ul li:nth-child(2){ font-weight: 600; line-height: 20px; color:var(--gray100); margin-top: 8px;}
.articleWrap .layerBox .box.font .cont ul.on li:nth-child(1){ border: solid 1px var(--blue100); background-color: var(--blue100); color: #fff;}
.articleWrap iframe{border: none !important;}
[data-jodit-temp="1"]{ position: relative; width:100% !important; padding-top: 56% !important; height: auto !important;}
[data-jodit-temp="1"] iframe{ position: absolute; top:0; left: 0; width:100% !important; height: 100% !important;}

@media(max-width:800px) {
  .newsVote{ display: flex; justify-content: center; align-items: center; margin: 20px 0 0 0; height: 184px;}
  .articleWrap .layerBox .box{ position: fixed; right: 20px; top:80px; width:312px; padding: 22px 24px 20px 24px; border-radius: 16px; background-color: #fff; box-shadow: 0 0 20px 0 #00000033; z-index: 700;}
  .articleWrap .info .btn{ display: none;}
  .articleWrap .ftSz12{ font-size: 12px;}
  .articleWrap .ftSz14{ font-size: 14px;}
  .articleWrap .ftSz16{ font-size: 16px;}
  .articleWrap .ftSz18{ font-size: 18px;}
  .articleWrap .ftSz20{ font-size: 20px;}
  .articleWrap .ftSz32{ font-size: 24px;}
  .articleWrap .ftSz34{ font-size: 24px;}
  .articleWrap .ftSz36{ font-size: 24px;}
  .articleWrap .ftSz38{ font-size: 24px;}
}

@media(max-width:480px) {
  .newsVote{ display: flex; justify-content: center; align-items: center; margin: 20px 0 0 0; height: 120px;}
  .newsVote ul{ width:25%; text-align: center; margin: 0 0 0 0; cursor: pointer;}
  .newsVote ul:nth-child(1){ margin: 0 0 0 0;}
  .newsVote ul li{ margin-bottom: 8px;}
  .newsVote ul li:nth-child(1) img{ width:48px;}
  .newsVote ul li:nth-child(2){ font-weight: 600; line-height: 20px; color: var(--gray70);}
  .newsVote ul li:nth-child(3){ font-weight: 700; font-size: 16px; line-height: 24px; color: #000;}
  .articleWrap .layerBox .box{ position: fixed; right: 20px; top:80px; width:calc(100% - 40px); padding: 22px 0 20px 0; border-radius: 16px; background-color: #fff; box-shadow: 0 0 20px 0 #00000033; z-index: 700;}
  .articleWrap .layerBox .box .tit{ padding-left: 20px;}
  .articleWrap .info .btn{ display: none;}
  .articleWrap iframe{ width:100% !important; border: none !important; outline:0 !important;}
}

.searchResult{ margin: 40px 0 0 0;}
.searchResult .resultTitle{ line-height: 48px; font-weight: 700; font-size: 32px; margin-bottom: 24px;}
.searchResult .resultTitle span{ margin-right: 8px; color: var(--blue100);}
.searchResult .profileTitle{ font-size: 24px; line-height: 36px; font-weight: 700; margin-bottom: 16px;}
.searchResult .profile{ display: flex;}
.searchResult .profile .thumb{ position: relative; width:208px; height: 208px; border-radius: 8px; overflow: hidden;}
.searchResult .profile .thumb img{position: absolute; top:0; left: 0; width:100%; height: 100%; object-fit: cover;}
.searchResult .profile .thumb::before{ content: ''; position: absolute; top:0; left: 0; width:calc(100% - 2px); height:calc(100% - 2px); border-radius: 8px; border: solid 1px rgba(0,0,0,0.08); z-index: 100;}
.searchResult .profile .info{ flex-grow: 1; margin-left: 24px;}
.searchResult .profile .info .name{ font-size: 20px; line-height: 30px; font-weight: 700; color:var(--gray100); margin-bottom: 8px;}
.searchResult .profile .info li{ display: flex; margin-bottom: 4px;}
.searchResult .profile .info li dl:nth-child(1){ font-size: 14px; line-height: 21px; font-weight: 600; color: var(--gray70); width:64px; margin-right: 8px;}
.searchResult .profile .info li dl:nth-child(2){ font-size: 14px; line-height: 21px; color: var(--gray90);}
.searchResult .profile .info .source{ font-size: 12px; line-height: 18px; color: var(--gray50); padding-top: 4px;}
.searchResult .another{ margin: 24px 0 0 0; padding: 0 0 24px 0; border-bottom: solid 1px var(--gray20);}
.searchResult .another ul{ display: flex; width:209px; border-radius: 8px; border: solid 1px var(--gray30); padding: 8px; cursor: pointer;}
.searchResult .another ul .thumb{ position: relative; width:64px; height: 64px; border-radius: 8px; overflow: hidden;}
.searchResult .another ul .thumb img{position: absolute; top:0; left: 0; width:100%; height: 100%; object-fit: cover;}
.searchResult .another ul .thumb::before{ content: ''; position: absolute; top:0; left: 0; width:calc(100% - 2px); height:calc(100% - 2px); border-radius: 8px; border: solid 1px rgba(0,0,0,0.08); z-index: 100;}
.searchResult .another ul li:nth-child(2){ padding: 10px 0 0 12px;}
.searchResult .another ul li:nth-child(2) dl:nth-child(1){ line-height: 21px; height: 21px; color: var(--gray60);}
.searchResult .another ul li:nth-child(2) dl:nth-child(2){ font-size: 16px; font-weight: 600; line-height: 24px; height: 24px; color: var(--gray90);}
.searchResult .another .swiper-slide{ width: auto; height: auto;}
.searchResult .another .swiper-wrapper{ height: auto !important;}

.searchResult .relationVote{ position: relative; padding: 0 0 44px 0;}
.searchResult .relationVote .tit{ font-size: 18px; line-height: 27px; font-weight: 700; color: var(--gray90); padding: 24px 0 16px 0;}
.searchResult .relationVote .tit span{ margin-left: 8px; font-weight: 400;}
.searchResult .relationVote .list ul{ width:387px; border: solid 1px var(--gray30); border-radius: 8px; overflow: hidden; margin: 0 0 0 0 !important;}
.searchResult .relationVote .list  ul .thumb{ position: relative; height:240px; overflow: hidden;}
.searchResult .relationVote .list  ul .thumb img{position: absolute; top:0; left: 0; width:100%; height: 100%; object-fit: cover;}
.searchResult .relationVote .list  ul .info{ position: relative; padding: 16px;}
.searchResult .relationVote .list  ul .info .date{ font-size: 12px; line-height: 18px; font-weight: 500; color: var(--gray70);}
.searchResult .relationVote .list  ul .info .title{ font-size: 16px; line-height: 24px; font-weight: 600; color: var(--gray110);}
.searchResult .relationVote .list  ul .info .btn{ position: absolute; top:calc(50% - 18px); right: 16px; border-radius: 8px; background-color: var(--gray20); width:49px; height: 36px; line-height: 36px; text-align: center; font-weight: 600; color: var(--gray90); cursor: pointer;}
.searchResult .relationVote .more .moreBtn{ position: absolute; bottom:0; right: 0;  display: inline-flex; align-items: center; line-height: 28px; color: var(--gray90); font-weight: 600; cursor: pointer;}
.searchResult .relationVote .more .moreBtn .arw{ margin-left: 4px;}
.searchResult .relationVote .swiper-slide{ width: auto; height: auto;}
.searchResult .relationVote .swiper-wrapper{ height: auto !important;}

@media(max-width:800px) {
  .searchResult{ margin: 24px 0 0 0;}
  .searchResult .resultTitle{ line-height: 36px; font-weight: 700; font-size: 24px; margin-bottom: 24px; padding-left: 20px;}
  .searchResult .resultTitle span{ margin-right: 8px; color: var(--blue100);}
  .searchResult .profileTitle{ font-size: 20px; line-height: 30px; font-weight: 700; margin-bottom: 24px; padding-left: 20px;}
  .searchResult .profile{ display: flex; padding: 0 0 0 20px;}
  .searchResult .another{ margin: 24px 0 0 0; padding: 0 0 24px 20px; border-bottom: solid 0 var(--gray20);}
  .searchResult .relationVote .tit{ font-size: 18px; line-height: 27px; font-weight: 700; color: var(--gray90); padding: 0 0 16px 20px;}
  .searchResult .relationVote .list{ padding: 0 0 0 20px;}
  .searchResult .relationVote .list ul{ width:280px; border: solid 1px var(--gray30); border-radius: 8px; overflow: hidden;}
  .searchResult .relationVote .list ul .thumb{ position: relative; height:175px; overflow: hidden;}
  .searchResult .relationVote .list .swiper-wrapper .swiper-slide:last-child{ margin-right: 20px;}
  .searchResult .relationVote .more .moreBtn{ right: 20px;}
}

@media(max-width:480px) {
  .searchResult .profile{ display: block; padding: 0 20px 0 20px;}
  .searchResult .profile .thumb{ position: relative; width:120px; height: 120px; border-radius: 8px; overflow: hidden;}
  .searchResult .profile .thumb img{position: absolute; top:0; left: 0; width:100%; height: 100%; object-fit: cover;}
  .searchResult .profile .thumb::before{ content: ''; position: absolute; top:0; left: 0; width:calc(100% - 2px); height:calc(100% - 2px); border-radius: 8px; border: solid 1px rgba(0,0,0,0.08); z-index: 100;}
  .searchResult .profile .info{ display: flex; flex-wrap: wrap; flex-grow: 1; margin-left: 0; margin-top: 16px;}
  .searchResult .profile .info .name{ width:100%; font-size: 20px; line-height: 30px; font-weight: 700; color:var(--gray100); margin-bottom: 8px;}
  .searchResult .profile .info li{ display: flex; margin-bottom: 4px; width:50%;}
  .searchResult .profile .info li dl:nth-child(1){ font-size: 14px; line-height: 21px; font-weight: 600; color: var(--gray70); width:64px !important; margin-right: 4px;}
  .searchResult .profile .info li dl:nth-child(2){ width:calc(100% - 68px); font-size: 14px; line-height: 21px; color: var(--gray90); overflow: hidden; display: -webkit-box;  -webkit-box-orient: vertical; word-break: break-all; -webkit-line-clamp: 1; text-overflow: ellipsis;}
  .searchResult .profile .info .source{ width:100%; font-size: 12px; line-height: 18px; color: var(--gray50); padding-top: 4px;}

  .searchResult .relationVote{ position: relative; padding: 0 0 0 0;}
  .searchResult .relationVote .tit{ font-size: 18px; line-height: 27px; font-weight: 700; color: var(--gray90); padding: 0 0 16px 20px;}
  .searchResult .relationVote .list{ padding: 0 20px 0 20px;}
  .searchResult .relationVote .list ul{ width:calc(100% - 2px); border: solid 1px var(--gray30); border-radius: 8px; overflow: hidden; margin-bottom: 16px !important;}
  .searchResult .relationVote .list ul .thumb{ position: relative; height:175px; overflow: hidden;}
  .searchResult .relationVote .list .swiper{ width:100%;}
  .searchResult .relationVote .list .swiper-wrapper{ display: flex;}
  .searchResult .relationVote .swiper-slide{ width: 100%; height: auto;}
  .searchResult .relationVote .list .swiper-wrapper .swiper-slide:last-child{ margin-right: 0;}
  .searchResult .relationVote .more .moreBtn{ bottom: auto; top:0; right: 20px;}
}

.relationNews{ position: relative; padding: 56px 0 0 0;}
.relationNews .newsTitle{ position: relative; line-height: 36px; font-size: 24px; font-weight: 700; margin-bottom: 16px;}
.relationNews .tab{ display: flex; border-bottom: solid 1px var(--gray30); margin-bottom: 24px;}
.relationNews .tab ul{ position: relative; display: flex; line-height: 44px; font-weight: 600; font-size: 16px; cursor: pointer; margin-right: 32px;}
.relationNews .tab ul:last-child{ margin-right: 0;}
.relationNews .tab ul li:nth-child(2){ font-weight: 400; margin-left: 4px; color: var(--gray90);}
.relationNews .tab ul.on{ color: var(--blue100);}
.relationNews .tab ul.on li:nth-child(2){ color: var(--blue100);}
.relationNews .tab ul.on::before{ content: ''; position: absolute; bottom: 0; left: 0; width:100%; height: 3px; background-color: var(--blue100);}
.relationNews .tab ul:hover{ color: var(--blue100);}
.relationNews .tab ul:hover li:nth-child(2){ color: var(--blue100);}

.sortBox{ position: absolute; top:56px; right: 0;}
.sortBox .sort{ display: inline-flex; align-items: center; padding: 0 12px 0 12px; line-height: 34px; border: solid 1px var(--gray30); border-radius: 8px; font-weight: 600; color: var(--gray90); cursor: pointer;}
.sortBox .sort .arw{ margin-left: 4px; transform: rotate(90deg);}
.sortBox .sortSelect{ position: absolute; top:40px; right: 0; width:120px; background-color: #fff; border: solid 1px var(--gray30); border-radius: 8px; padding: 4px; z-index: 300; box-shadow: 0 4px 12px -4px #10182808; display: none;}
.sortBox .sortSelect ul{ position: relative; line-height: 48px; text-indent: 16px; color: var(--gray90); border-radius: 4px; cursor: pointer;}
.sortBox .sortSelect ul:hover{ background-color: var(--gray10);}
.sortBox .sortSelect ul.on div{ position: absolute; top:calc(50% - 12px); right: 16px; width:24px; height: 24px; background: url("https://cdn.trend.rankify.best/dctrend/front/images/ico_check.svg") no-repeat;}

@media(max-width:800px) {
  .relationNews{ position: relative; padding: 56px 20px 0 20px;}
  .relationNews .newsTitle{ position: relative; line-height: 30px; font-size: 20px; font-weight: 700; margin-bottom: 16px;}
  .relationNews .tab{ display: flex; border-bottom: solid 1px var(--gray30); margin-bottom: 0;}
  .relationNews .tab ul{ position: relative; display: flex; line-height: 36px; font-weight: 600; font-size: 14px; cursor: pointer; margin-right: 32px;}
  .relationNews .tab ul li:nth-child(2){ font-weight: 600; margin-left: 4px; color: var(--gray90);}
  .relationNews .tab ul.on{ color: var(--blue100);}
  .relationNews .tab ul.on li:nth-child(2){ color: var(--blue100);}
  .relationNews .tab ul.on::before{ content: ''; position: absolute; bottom: 0; left: 0; width:100%; height: 3px; background-color: var(--blue100);}
  .relationNews .tab ul:hover{ color: var(--blue100);}
  .relationNews .tab ul:hover li:nth-child(2){ color: var(--blue100);}

  .sortBox{ position: absolute; top:56px; right: 20px;}
}

@media(max-width:480px) {
  .relationNews .tab{ display: flex; justify-content: space-between; border-bottom: solid 1px var(--gray30);}
  .relationNews .tab ul{ position: relative; display: flex; line-height: 36px; font-weight: 600; font-size: 13px; cursor: pointer; margin-right: 0;}
  .relationNews .tab ul li:nth-child(2){ font-weight: 600; margin-left: 4px; color: var(--gray90); display: none;}
  .relationNews .tab ul.on::before{ content: ''; position: absolute; bottom: 0; left: 0; width:100%; height: 2px; background-color: var(--blue100);}
}

.rankMark{ display: inline-flex; position: relative; font-size: 11px; height: 11px; line-height: 11px !important; font-weight: 600; color: var(--gray70); padding: 0 0 0 12px;}
.rankMark.new{ padding: 0 0 0 0; color: var(--red90);}
.rankMark.up::before{ content: ''; position: absolute; top:0; left: 0; width:8px; height: 100%; background: url("https://cdn.trend.rankify.best/dctrend/front/images/ico_rank_up.svg") no-repeat center center; background-size: contain;}
.rankMark.same::before{ content: ''; position: absolute; top:0; left: 0; width:8px; height: 100%; background: url("https://cdn.trend.rankify.best/dctrend/front/images/ico_rank_same.svg") no-repeat center center; background-size: contain;}
.rankMark.down::before{ content: ''; position: absolute; top:0; left: 0; width:8px; height: 100%; background: url("https://cdn.trend.rankify.best/dctrend/front/images/ico_rank_down.svg") no-repeat center center; background-size: contain;}

.rankingMain{}
.rankingMain .mainTitle{ display: flex; flex-wrap: wrap; align-items: end; margin-top: 40px;}
.rankingMain .mainTitle .tit{ font-size: 32px; line-height: 48px; font-weight: 700;}
.rankingMain .mainTitle .update{ color: var(--gray70); line-height: 34px; padding-left: 16px;}
.rankingMain .mainTitle .info{ display: flex; width:100%; margin-top: 8px; color: var(--gray70); line-height: 24px; align-items: center;}
.rankingMain .mainTitle .info img{ margin-right: 8px;}
.rankingMain .rankingBox{ display: flex; margin-top: 24px; margin-bottom: 80px;}
.rankingMain .rankingBox .box{ flex-grow: 1; position: relative; border-radius: 8px; box-shadow: 0 6px 20px -4px #1018280A; border: 1px solid var(--gray30); padding: 16px; margin-left: 16px; overflow: hidden;}
.rankingMain .rankingBox .box:nth-child(1){ margin-left: 0;}
.rankingMain .rankingBox .box .top{ display: flex;}
.rankingMain .rankingBox .box .top .tit{ font-size: 20px; line-height: 30px; font-weight: 700;}
.rankingMain .rankingBox .box .top .sort{ display: flex; margin-left: auto;}
.rankingMain .rankingBox .box .top .sort li{ position: relative; line-height: 30px; margin-left: 16px; font-weight: 600; color: var(--gray50);}
.rankingMain .rankingBox .box .top .sort li.on{ color: var(--blue100);}
.rankingMain .rankingBox .box .top .sort li.on::before{ content: ''; position: absolute; bottom: 0; left: 0; width:100%; height: 2px; background-color: var(--blue100);}
.rankingMain .rankingBox .box .list{ margin-top: 8px;}
.rankingMain .rankingBox .box .list ul{ display: flex; align-items: center; padding: 16px 0 16px 0; border-bottom: solid 1px var(--gray20);}
.rankingMain .rankingBox .box .list ul:last-child{ border-bottom: 0;}
.rankingMain .rankingBox .box .list ul .rank{ width:32px; text-align: center; margin-right: 12px;}
.rankingMain .rankingBox .box .list ul .rank dl:nth-child(1){ font-size: 18px; line-height: 27px; font-weight: 700; color: var(--gray100);}
.rankingMain .rankingBox .box .list ul:nth-child(1) .rank dl:nth-child(1){ color: var(--blue100);}
.rankingMain .rankingBox .box .list ul:nth-child(2) .rank dl:nth-child(1){ color: var(--blue100);}
.rankingMain .rankingBox .box .list ul:nth-child(3) .rank dl:nth-child(1){ color: var(--blue100);}
.rankingMain .rankingBox .box .list ul .info{ display: flex;}
.rankingMain .rankingBox .box .list ul .info .thumb{ position: relative; width:48px; height: 48px; border-radius: 8px; overflow: hidden; margin-right: 12px;}
.rankingMain .rankingBox .box .list ul .info .thumb img{position: absolute; top:0; left: 0; width:100%; height: 100%; object-fit: cover;}
.rankingMain .rankingBox .box .list ul .info .thumb::before{ content: ''; position: absolute; top:0; left: 0; width:calc(100% - 2px); height:calc(100% - 2px); border-radius: 8px; border: solid 1px rgba(0,0,0,0.08); z-index: 100;}
.rankingMain .rankingBox .box .list ul .info .txt .cate{ display: flex; align-items: center; font-size: 11px; line-height: 16px; font-weight: 500; color: var(--gray60); margin-top: 6px;}
.rankingMain .rankingBox .box .list ul .info .txt .cate .dot{ width:8px; height: 8px; background: url("https://cdn.trend.rankify.best/dctrend/front/images/ico_dot.svg"); margin: 0 4px 2px 4px;}
.rankingMain .rankingBox .box .list ul .info .txt .name{ font-size: 14px; line-height: 21px; font-weight: 600;}
.rankingMain .rankingBox .box .list ul .score{ margin-left: auto; font-weight: 600; color: var(--gray90);}
.rankingMain .rankingBox .box .btn{ margin-top: 16px;}
.rankingMain .rankingBox .box .btn .more{ height: 56px; line-height: 56px; border-radius: 8px; text-align: center; background-color: var(--blue100); color: #fff; font-size: 16px; font-weight: 600; margin-bottom: 12px; cursor: pointer;}
.rankingMain .rankingBox .box .btn .vote{ height: 54px; line-height: 54px; border: solid 1px var(--gray40); border-radius: 8px; text-align: center; background-color: #fff; color: var(--gray70); font-size: 16px; font-weight: 600; cursor: pointer;}

.voteTitle{ position: relative; line-height: 36px; font-size: 24px; font-weight: 700;}
.voteTitle .arw{ position: absolute; top:6px; right: 0; cursor: pointer;}
.voteBox{ margin-top: 24px;}
.voteBox ul{ border: solid 1px var(--gray30); border-radius: 8px; overflow: hidden;}
.voteBox ul .thumb{ position: relative; height:240px; overflow: hidden;}
.voteBox ul .thumb img{position: absolute; top:0; left: 0; width:100%; height: 100%; object-fit: cover;}
.voteBox ul .info{ position: relative; padding: 16px;}
.voteBox ul .info .date{ font-size: 12px; line-height: 18px; font-weight: 500; color: var(--gray70);}
.voteBox ul .info .title{ font-size: 16px; line-height: 24px; font-weight: 600; color: var(--gray110);}
.voteBox ul .info .btn{ position: absolute; top:calc(50% - 18px); right: 16px; border-radius: 8px; background-color: var(--gray20); width:49px; height: 36px; line-height: 36px; text-align: center; font-weight: 600; color: var(--gray90); cursor: pointer;}
.voteBox .swiper-slide{ height: auto;}
.voteBox .swiper-wrapper{ height: auto;}
.rankingMain .blank{ height: 40px;}

.rankSortWrap{ display: flex; margin: 40px 0 0 0;}
.sortItemBox{ position: relative; margin-right: 16px; flex-shrink: 0; user-select: none;}
.sortItemBox .itemBox{ display: flex; align-items: center; height: 40px; border: solid 1px var(--gray30); border-radius: 8px; padding: 0 44px 0 12px; background-color: #fff; cursor: pointer;}
.sortItemBox .itemBox::before{ content: ''; position: absolute; right: 12px; top:14px; width:16px; height: 16px; background: url("https://cdn.trend.rankify.best/dctrend/front/images/ico_arw.svg") no-repeat center center; background-size: contain; transform: rotate(90deg);}
.sortItemBox .itemBox.on::before{ transform: rotate(-90deg)}
.sortItemBox .itemBox .tit{ position: relative; color: var(--gray70); margin-right: 12px; padding-right: 12px;}
.sortItemBox .itemBox .tit::before{ content: ''; position: absolute; top:4px; right: 0; width:1px; height: 10px; background-color: var(--gray30);}
.sortItemBox .selectList{ position: absolute; top:50px; left: 0; width:calc(100% - 10px); background-color: #fff; border: solid 1px var(--gray30); border-radius: 8px; padding: 4px; z-index: 300; box-shadow: 0 4px 12px -4px #10182808;}
.sortItemBox .selectList ul{ position: relative; line-height: 40px; text-indent: 16px; color: var(--gray90); border-radius: 4px; cursor: pointer;}
.sortItemBox .selectList ul:hover{ background-color: var(--gray10);}
.sortItemBox .selectList ul.on::before{ content: ''; position: absolute; top:calc(50% - 12px); right: 16px; width:24px; height: 24px; background: url("https://cdn.trend.rankify.best/dctrend/front/images/ico_check.svg") no-repeat;}
.sortItemBox.on .itemBox{ border: solid 1px var(--blue100);}
.sortItemBox.on .selectList{ display: block;}

.rankAllTable{ margin: 24px 0 0 0;}
.rankAllTable ul{ display: flex;}
.rankAllTable ul li{ text-align: center;}
.rankAllTable ul .rank{ width:164px;}
.rankAllTable ul .thumb{ width:92px;}
.rankAllTable ul .name{ flex-grow: 1; text-align: left; padding-left: 16px;}
.rankAllTable ul .cate{ width:200px;}
.rankAllTable ul .gender{ width:110px;}
.rankAllTable ul .index{ width:200px; text-align: right; padding-right: 16px;}
.rankAllTable ul .btnBox{ width:130px;}
.rankAllTable .head{ background-color: var(--gray20); height: 54px; align-items: center; border-radius: 8px; color: var(--gray70);}

.rankAllTable .tr{ height: 80px; border-bottom: solid 1px var(--gray30); align-items: center; font-size: 16px;}
.rankAllTable .tr .rank{ display: flex; align-items: center; justify-content: center;}
.rankAllTable .tr .rank .num{ font-weight: 600; padding-right:30px; line-height: 24px;}
.rankAllTable .tr .rank .rankMark{ font-size: 12px; line-height: 24px;}
.rankAllTable .tr .thumb .img{ position: relative; width:48px; height: 48px; border-radius: 8px; overflow: hidden; margin: 0 auto;}
.rankAllTable .tr .thumb .img img{position: absolute; top:0; left: 0; width:100%; height: 100%; object-fit: cover;}
.rankAllTable .tr .thumb .img::before{ content: ''; position: absolute; top:0; left: 0; width:calc(100% - 2px); height:calc(100% - 2px); border-radius: 8px; border: solid 1px rgba(0,0,0,0.08); z-index: 100;}
.rankAllTable .tr .name .inCate{ display: none;}
.rankAllTable .tr .name .inIndex{ display: none;}
.rankAllTable .tr .index{ font-weight: 600;}
.rankAllTable .tr .index .arw{ margin-left: 4px;}
.rankAllTable .tr .index .btn{ display: inline-flex; align-items: center;}
.rankAllTable .tr .btnBox .btn{ width:66px; height: 32px; line-height: 32px; border-radius: 8px; font-size: 12px; font-weight: 500; background-color: var(--gray110); color: #fff; margin: 0 auto; cursor: pointer;}

@media(max-width:800px) {
  .rankingMain .mainTitle{ display: flex; flex-wrap: wrap; align-items: end; margin-top: 20px; padding: 0 20px 0 20px;}
  .rankingMain .mainTitle .tit{ font-size: 24px; line-height: 36px; font-weight: 700;}
  .rankingMain .mainTitle .update{ color: var(--gray70); line-height: 21px; padding-left: 8px; padding-bottom: 2px;}
  .rankingMain .mainTitle .info{ display: flex; width:100%; margin-top: 8px; color: var(--gray70); line-height: 21px; align-items: center;}
  .rankingMain .rankingBox{ display: flex; flex-wrap: wrap; margin-top: 24px; margin-left: 20px; margin-right: 20px; margin-bottom: 24px;}
  .rankingMain .rankingBox .box{ flex-grow: 1; width:calc(100% - 34px); position: relative; border-radius: 8px; box-shadow: 0 6px 20px -4px #1018280A; border: 1px solid var(--gray30); padding: 16px; margin-left: 0; overflow: hidden; margin-bottom: 32px;}
  .rankingMain .rankingBox .box:nth-child(1){ margin-left: 0;}
  .voteTitle{ position: relative; line-height: 36px; font-size: 24px; font-weight: 700; padding: 0 20px 0 20px;}
  .voteBox{ margin-top: 24px;}
  .voteBox ul{ width:278px; border: solid 1px var(--gray30); border-radius: 8px; overflow: hidden;}
  .voteBox ul .thumb{ position: relative; height:174px; overflow: hidden;}
  .voteBox .swiper-slide{ width:auto; height: auto;}
  .voteBox .swiper-slide:first-child{ margin-left: 20px;}
  .voteBox .swiper-slide:last-child{ margin-right: 20px;}
  .rankingMain .blank{ height: 56px;}

  .rankSortWrap{ display: flex; margin: 24px 0 0 0; padding-left: 20px;}
  .sortItemBox{ position: relative; margin-right: 16px; flex-shrink: 0;}
  .sortItemBox .itemBox{ display: flex; align-items: center; height: 43px; border: solid 1px var(--gray30); border-radius: 8px; padding: 0 44px 0 12px; background-color: #fff; cursor: pointer;}
  .sortItemBox .itemBox::before{ content: ''; position: absolute; right: 12px; top:14px; width:16px; height: 16px; background: url("https://cdn.trend.rankify.best/dctrend/front/images/ico_arw.svg") no-repeat center center; background-size: contain; transform: rotate(90deg);}
  .sortItemBox .itemBox .tit{ display: none; position: relative; color: var(--gray70); margin-right: 12px; padding-right: 12px;}
  .sortItemBox .selectList{ position: absolute; top:50px; left: 0; width:calc(100% - 10px); background-color: #fff; border: solid 1px var(--gray30); border-radius: 8px; padding: 4px; z-index: 300; box-shadow: 0 4px 12px -4px #10182808;}
  .sortItemBox:nth-child(1) .selectList{ width:130px;}
  .sortItemBox:nth-child(2) .selectList{ width:170px;}
  .sortItemBox:nth-child(3) .selectList{ width:120px;}
  .sortItemBox:nth-child(4) .selectList{ width:120px;}

  .rankAllTable{ margin: 24px 20px 0 20px;}
  .rankAllTable ul{ display: flex;}
  .rankAllTable ul li{ text-align: center;}
  .rankAllTable ul .rank{ width:64px;}
  .rankAllTable ul .thumb{ width:48px;}
  .rankAllTable ul .name{ flex-grow: 1; text-align: left; padding-left: 12px;}
  .rankAllTable ul .cate{ width:64px;}
  .rankAllTable ul .gender{ width:64px;}
  .rankAllTable ul .index{ width:120px; text-align: right;}
  .rankAllTable ul .btnBox{ width:98px;}
  .rankAllTable .head{ background-color: var(--gray20); height: 54px; align-items: center; border-radius: 8px; color: var(--gray70);}
  .rankAllTable .tr .rank{ display: flex; flex-wrap: wrap; align-items: center; justify-content: center; text-align: center;}
  .rankAllTable .tr .num{ width:100%;}
  .rankAllTable .tr .rank .num{ font-weight: 600; padding-right:0; line-height: 24px;}
  .rankAllTable .tr .rank .rankMark{ font-size: 12px; line-height: 24px; text-align: center;}

}

@media(max-width:480px) {
  .voteTitle{ position: relative; line-height: 30px; font-size: 20px; font-weight: 700; padding: 0 20px 0 20px;}
  .voteBox{ margin-top: 0; padding: 0 20px 0 20px;}
  .voteBox ul{ width:calc(100% - 2px); border: solid 1px var(--gray30); border-radius: 8px; overflow: hidden; margin-top: 16px; }
  .voteBox ul .thumb{ position: relative; height:208px; overflow: hidden;}
  .voteBox .swiper-slide:first-child{ margin-left: 0;}
  .voteBox .swiper-slide:last-child{ margin-right: 0;}
  .rankingMain .blank{ height: 40px;}

  .rankSortWrap{ display: flex; flex-wrap: wrap; margin: 16px 0 0 0; padding: 0 20px 0 20px;}
  .sortItemBox{ position: relative; width:calc(50% - 4px); margin-right: 8px; flex-shrink: 0;}
  .sortItemBox:nth-child(2n+2){ margin-right: 0;}
  .sortItemBox:nth-child(1){ margin-bottom: 8px;}
  .sortItemBox:nth-child(2){ margin-bottom: 8px;}
  .sortItemBox .itemBox{ display: flex; align-items: center; height: 35px; border: solid 1px var(--gray30); border-radius: 8px; padding: 0 32px 0 8px; font-size: 13px; background-color: #fff; cursor: pointer;}
  .sortItemBox .itemBox::before{ content: ''; position: absolute; right: 8px; top:10px; width:16px; height: 16px; background: url("https://cdn.trend.rankify.best/dctrend/front/images/ico_arw.svg") no-repeat center center; background-size: contain; transform: rotate(90deg);}
  .sortItemBox .itemBox .tit{ display: none; position: relative; color: var(--gray70); margin-right: 12px; padding-right: 12px;}
  .sortItemBox .selectList{ position: absolute; top:40px; left: 0; width:calc(100% - 10px); background-color: #fff; border: solid 1px var(--gray30); border-radius: 8px; padding: 4px; z-index: 300; box-shadow: 0 4px 12px -4px #10182808;}
  .sortItemBox:nth-child(1) .selectList{ width:calc(100% - 10px);}
  .sortItemBox:nth-child(2) .selectList{ width:calc(100% - 10px);}
  .sortItemBox:nth-child(3) .selectList{ width:calc(100% - 10px);}
  .sortItemBox:nth-child(4) .selectList{ width:calc(100% - 10px);}
  .sortItemBox .selectList ul{ position: relative; line-height: 40px; font-size: 13px; text-indent: 8px; color: var(--gray90); border-radius: 4px; cursor: pointer;}
  .sortItemBox .selectList ul:hover{ background-color: var(--gray10);}
  .sortItemBox .selectList ul.on::before{ content: ''; position: absolute; top:calc(50% - 12px); right: 8px; width:24px; height: 24px; background: url("https://cdn.trend.rankify.best/dctrend/front/images/ico_check.svg") no-repeat;}
  .sortItemBox.on .itemBox{ border: solid 1px var(--blue100);}
  .sortItemBox.on .selectList{ display: block;}

  .rankAllTable{ margin: 24px 20px 0 20px;}
  .rankAllTable ul{ display: flex;}
  .rankAllTable ul li{ text-align: center;}
  .rankAllTable ul .rank{ width:52px;}
  .rankAllTable ul .thumb{ width:48px;}
  .rankAllTable ul .name{ flex-grow: 1; text-align: left; padding-left: 12px;}
  .rankAllTable ul .cate{ width:64px; display: none;}
  .rankAllTable ul .gender{ width:64px; display: none;}
  .rankAllTable ul .index{ width:120px; text-align: right; display: none;}
  .rankAllTable ul .btnBox{ width:82px;}

  .rankAllTable .tr{ height: 96px;}
  .rankAllTable .tr .name .inCate{ display: block; font-size: 12px; color: var(--gray60);}
  .rankAllTable .tr .name .inName{ line-height: 28px;}
  .rankAllTable .tr .name .inIndex{ display: block; font-weight: 600;}
  .rankAllTable .tr .name .inIndex .btn{ display: inline-flex; align-items: center;}
  .rankAllTable .tr .name .inIndex .arw{ margin-left: 4px;}
}

.chartWrap{ width:calc(100% - 48px); height: 340px; background-color: #fff; border: solid 1px var(--gray30); border-radius: 8px; margin-top: 24px; padding: 24px;}
.chartWrap #top10{ width:100%; height: 100%;}
.chartTable{ background-color: #fff; border: solid 1px var(--gray30); border-radius: 8px; margin-top: 24px; overflow: hidden;}
.chartTable .tr{ display: flex; border-bottom: solid 1px var(--gray30);}
.chartTable .tr:last-child{ border-bottom: 0;}
.chartTable .tr li{ display: flex; align-items: center; justify-content: center; flex-grow: 0; flex-shrink: 0; line-height: 56px; text-align: center; color: var(--gray100);}
.chartTable.addImg .tr li{ line-height: 70px;}
.chartTable .tr.head{ background-color: var(--gray20);}
.chartTable .tr.head li{ font-weight: 600; color: var(--gray90);}
.chartTable .tr li.rank{ width:14%; font-weight: 600;}
.chartTable .tr li.rank .num{ width:65%;}
.chartTable .tr li.rank .rankMark{ width:35%;}
.chartTable .tr li.name{ width:20%;}
.chartTable.addImg .tr li.rank{ width:8%;}
.chartTable.addImg .tr li.thumb{ width:70px;}
.chartTable.addImg .tr li.thumb .img{ position: relative; width:48px; height: 48px; border-radius: 8px; overflow: hidden; margin: 0 auto;}
.chartTable.addImg .tr li.thumb .img img{position: absolute; top:0; left: 0; width:100%; height: 100%; object-fit: cover;}
.chartTable.addImg .tr li.thumb .img::before{ content: ''; position: absolute; top:0; left: 0; width:calc(100% - 2px); height:calc(100% - 2px); border-radius: 8px; border: solid 1px rgba(0,0,0,0.08); z-index: 100;}
.chartTable.addImg .tr li.name{ width:calc(26% - 70px);}
.chartTable.addImg .tr li.name div{ width:100%; text-align: left; padding-left: 16px;}
.chartTable .tr li.cate{ width:13%;}
.chartTable .tr li.index{ width:20%;}
.chartTable .tr li.chart{ width:20%;}
.chartTable .tr li.btnBox{ width:13%;}
.chartTable .tr li .rt1{ display: none;}
.chartTable .tr li .rt2{ display: none;}
.chartTable .tr li .btn{ width:66px; height: 32px; line-height: 32px; border-radius: 8px; font-size: 12px; font-weight: 500; background-color: var(--gray110); color: #fff; cursor: pointer;}
.chartTable .tr li .miniChart{ width:102px; height: 26px;}
.chartTable .tr li .miniChart canvas{ width:100%; height: 100%;}
.chartTable .tr li .drop{ width:24px; height: 24px; border: solid 1px var(--gray30); border-radius: 4px; margin-left: 8px; cursor: pointer; background: url("https://cdn.trend.rankify.best/dctrend/front/images/ico_arw.svg") no-repeat center center; background-size: 70%; transform: rotate(90deg);}
.chartTable .tr li .drop.open{ transform: rotate(-90deg);}
.chartTable .detail{}
.chartTable .detail .layerTitle{ display: none;}
.chartTable .detail .close{ display: none; position: absolute; top:16px; right: 16px; width:24px; height: 24px;}
.chartTable .detail .inBox{ display: flex; background-color: var(--gray30); padding: 16px;}
.chartTable .detail .left{ width:752px; height: 273px; background-color: #fff; border-radius: 8px; padding: 16px;}
.chartTable .detail .left .openChart{ width:100%; height: 230px; margin-top: 10px;}
.chartTable .detail .left .openChart canvas{ width:100%; height: 100%;}
.chartTable .detail .right{ flex-grow: 1; margin-left: 16px;}
.chartTable .detail .right .box{ padding: 16px; background-color: #fff; border-radius: 8px;}
.chartTable .detail .right .box:nth-child(1){ margin-bottom: 16px;}
.chartTable .detail .tit{ font-size: 16px; line-height: 24px; font-weight: 600;}
.chartTable .detail .gender{ display: flex; margin-top: 10px;}
.chartTable .detail .gender dl{ display: flex; flex-grow: 1; line-height: 45px; border-radius: 8px; padding: 0 16px 0 16px; font-weight: 600;}
.chartTable .detail .gender dl.male{ margin-right: 12px; background-color: var(--blue20); color: var(--blue100);}
.chartTable .detail .gender dl.female{ background-color: var(--red20); color: var(--red90);}
.chartTable .detail .gender dl dt:nth-child(2){ margin-left: auto;}
.chartTable .detail .age{ display: flex; flex-wrap: wrap; border-top: solid 1px var(--gray120); margin-top: 10px;}
.chartTable .detail .age dl{ display: flex; width:50%;}
.chartTable .detail .age dl dt{ width:50%; line-height: 36px; text-align: center; border-bottom: solid 1px var(--gray30);}
.chartTable .detail .age dl dt:nth-child(1){ background-color: var(--gray10); color: var(--gray70); border-right: solid 1px var(--gray30);}
.chartTable .detail .age dl dt:nth-child(2){ color: var(--gray90);}
.chartTable .detail .age dl:nth-child(2n+1) dt:nth-child(2){ border-right: solid 1px var(--gray30);}

@media(max-width:800px) {
  .chartWrap{ width:calc(100% - 88px); height: 340px; background-color: #fff; border: solid 1px var(--gray30); border-radius: 8px; margin-top: 24px; padding: 24px; margin-left: 20px;}
  .chartTableRt{}
  .chartTable{width:calc(100% - 42px); background-color: #fff; border: solid 1px var(--gray30); border-radius: 8px; margin-top: 24px; margin-left: 20px; margin-right: 20px;}
  .chartTable .tr li.rank{ position: relative; width:13%; font-weight: 600;}
  .chartTable .tr li.rank .num{ width:100%; line-height: 14px; text-align: center; padding-bottom: 16px;}
  .chartTable .tr li.rank .rankMark{ position: absolute; top:34px; left:50%; transform: translate(-50%, 0); width:auto; line-height: 11px; text-align: center;}
  .chartTable .tr li.name{ width:20%;}
  .chartTable .tr li.cate{ width:14%;}
  .chartTable .tr li.index{ width:20%;}
  .chartTable .tr li.chart{ width:20%;}
  .chartTable .tr li.btnBox{ width:13%;}
  .chartTable .tr li .btn{ width:56px; height: 32px; line-height: 32px; border-radius: 8px; font-size: 12px; font-weight: 500; background-color: var(--gray110); color: #fff; cursor: pointer;}
  .chartTable .tr li .miniChart{ width:70px; height: 20px;}
  .chartTable .detail .inBox{ display: flex; flex-wrap: wrap; background-color: var(--gray30); padding: 16px;}
  .chartTable .detail .left{ width:calc(100% - 32px); height: auto; background-color: #fff; border-radius: 8px; padding: 16px;}
  .chartTable .detail .left .openChart{ width:100%; height: 200px; margin-top: 10px;}
  .chartTable .detail .left .openChart canvas{ width:100%; height: 100%;}
  .chartTable .detail .right{ display: flex; flex-grow: 1; margin-left: 0; margin-top: 16px;}
  .chartTable .detail .right .box{ padding: 16px; background-color: #fff; border-radius: 8px;}
  .chartTable .detail .right .box:nth-child(1){ margin-bottom: 0; margin-right: 16px; width:30%;}
  .chartTable .detail .right .box:nth-child(2){ width:calc(70% - 16px);}
  .chartTable .detail .gender{ display: flex; flex-wrap: wrap; margin-top: 10px;}
  .chartTable .detail .gender dl{ display: flex; flex-grow: 1; width:100%; line-height: 40px; border-radius: 8px; padding: 0 16px 0 16px; font-weight: 600;}
  .chartTable .detail .gender dl.male{ margin-right: 0; margin-bottom: 8px; background-color: var(--blue20); color: var(--blue100);}
  .chartTable .detail .gender dl.female{ background-color: var(--red20); color: var(--red90);}
  .chartTable .detail .gender dl dt:nth-child(2){ margin-left: auto;}
  .chartTable .detail .age{ display: flex; flex-wrap: wrap; border-top: solid 1px var(--gray120); margin-top: 10px;}
  .chartTable .detail .age dl{ display: flex; width:33.3333333333%;}
  .chartTable .detail .age dl dt{ width:50%; line-height: 42px; text-align: center; border-bottom: solid 1px var(--gray30);}
  .chartTable .detail .age dl dt:nth-child(1){ background-color: var(--gray10); color: var(--gray70); border-right: solid 1px var(--gray30);}
  .chartTable .detail .age dl dt:nth-child(2){ color: var(--gray90);}
  .chartTable .detail .age dl dt:nth-child(2){ border-right: solid 1px var(--gray30);}
  .chartTable .detail .age dl:nth-child(3n+3) dt:nth-child(2){ border-right: solid 0 var(--gray30) !important;}
}

@media(max-width:480px) {
  .chartWrap{ width:calc(100% - 60px); height: 460px; background-color: #fff; border: solid 1px var(--gray30); border-radius: 8px; margin-top: 24px; padding: 24px 10px 24px 10px;}
  .chartTable{width:calc(100% - 42px); background-color: transparent; border: solid 0 var(--gray30); border-radius: 0; margin-top: 24px; margin-left: 20px; margin-right: 20px;}
  .chartTable .tr.head{ display: none;}
  .chartTable .tr{ display: block; border: solid 1px var(--gray30); background-color: #fff; border-radius: 8px; margin-bottom: 12px;}
  .chartTable .tr:last-child{ border: solid 1px var(--gray30);}
  .chartTable .tr li{ display: block; align-items: normal; justify-content: normal; flex-grow: 0; flex-shrink: 0; line-height: 16px !important; border-left: solid 0 var(--gray30); text-align: left; color: var(--gray100);}
  .chartTable .tr li.rank .rt1{ display: inline-table; color: var(--gray120);}
  .chartTable .tr li.rank .rt1 span{ color: var(--blue100);}
  .chartTable .tr li.rank{ position: relative; width:100% !important; font-weight: 600; color: var(--blue100); font-size: 16px;}
  .chartTable .tr li.rank .num{ width:auto; line-height: 24px; text-align: center; font-size: 16px; display: inline-table; padding: 16px 0 16px 16px;}
  .chartTable .tr li.rank .rankMark{ position: absolute; top:22px; left:auto; right: 16px; transform: translate(0, 0); width:auto; line-height: 11px; text-align: right;}
  .chartTable.addImg .tr li.thumb{ display: none;}
  .chartTable.addImg .tr li.name{ width:100%; display: none;}
  .chartTable .tr li .rt2{ display: block;}
  .chartTable .tr li.name{ width:100%; display: none;}
  .chartTable .tr li.cate{ width:calc(100% - 32px); padding: 0 16px 8px 16px; position: relative; line-height: 21px; text-align: right;}
  .chartTable .tr li.index{ width:calc(100% - 32px); padding: 0 16px 8px 16px; position: relative; line-height: 21px; text-align: right;}
  .chartTable .tr li.chart{ width:calc(100% - 32px); height: 21px; padding: 0 16px 8px 16px; position: relative; line-height: 21px; text-align: right;}
  .chartTable .tr li.btnBox{ width:100%; padding: 16px 0 16px 0;}
  .chartTable .tr li .rt2{ position: absolute; top:0; left: 16px; font-weight: 600; color: var(--gray70);}
  .chartTable .tr li .btn{ width:calc(100% - 32px); height: 40px; line-height: 40px; border-radius: 8px; font-size: 14px; font-weight: 600; background-color: var(--gray110); color: #fff; text-align: center; margin-left: 16px; cursor: pointer;}
  .chartTable .tr li .miniChart{ position: absolute; top:0; right: 48px; width:70px; height: 20px;}
  .chartTable .tr li .miniChart canvas{ width:100%; height: 100%;}
  .chartTable .tr li .drop{ position: absolute; top:0; right: 16px; width:24px; height: 24px; border: solid 1px var(--gray30); border-radius: 4px; margin-left: 0; cursor: pointer; background: url("https://cdn.trend.rankify.best/dctrend/front/images/ico_arw.svg") no-repeat center center; background-size: 70%; transform: rotate(0);}
  .chartTable .tr li .drop.open{ transform: rotate(0deg);}

  .chartTable .detail{ position: fixed; top:0; left: 0; width:100%; height: 100%; background-color: rgba(0,0,0,0.6); z-index: 1000;}
  .chartTable .detail .layerBox{ position:absolute; top:100px; left: 16px; width:calc(100% - 32px); height: calc(100% - 200px); background-color: #fff; border-radius: 16px; overflow: hidden;}
  .chartTable .detail .layerTitle{ display: block; padding: 16px 0 16px 16px; line-height: 24px; font-size: 16px; font-weight: 600;}
  .chartTable .detail .layerTitle span{ color: var(--blue100); margin-right: 4px;}
  .chartTable .detail .close{ display: block; position: absolute; top:16px; right: 16px; width:24px; height: 24px;}
  .chartTable .detail .scrollBox{ position: absolute; top:56px; left: 0; width:100%; height: calc(100% - 56px); overflow-y: auto;}
  .chartTable .detail .inBox{ display: flex; background-color: #ffffff; padding: 0 16px 0 16px;}
  .chartTable .detail .left{ width:calc(100% - 34px); height: auto; background-color: #fff; border: solid 1px var(--gray30); border-radius: 8px; padding: 16px;}
  .chartTable .detail .right .box{ padding: 16px; background-color: #fff; border-radius: 8px; border: solid 1px var(--gray30);}
  .chartTable .detail .right{ display: flex; flex-wrap: wrap; flex-grow: 1; margin-left: 0; margin-top: 16px;}
  .chartTable .detail .right .box{ padding: 16px; background-color: #fff; border-radius: 8px;}
  .chartTable .detail .right .box:nth-child(1){ margin-bottom: 16px; margin-right: 0; width:100%;}
  .chartTable .detail .right .box:nth-child(2){ width:calc(100%); margin-bottom: 16px;}
  .chartTable .detail .age dl{ display: flex; width:50%;}
  .chartTable .detail .age dl dt{ width:50%; line-height: 42px; text-align: center; border-bottom: solid 1px var(--gray30);}
  .chartTable .detail .age dl dt:nth-child(1){ background-color: var(--gray10); color: var(--gray70); border-right: solid 1px var(--gray30);}
  .chartTable .detail .age dl dt:nth-child(2){ color: var(--gray90);}
  .chartTable .detail .age dl dt:nth-child(2){ border-right: solid 1px var(--gray30);}
  .chartTable .detail .age dl:nth-child(3n+3) dt:nth-child(2){ border-right: solid 1px var(--gray30) !important;}
  .chartTable .detail .age dl:nth-child(2n+2) dt:nth-child(2){ border-right: solid 0 var(--gray30) !important;}
}

.relationVote{ padding: 40px 0 0 0;}
.relationVote .rvTitle{ font-size: 24px; line-height: 36px; font-weight: 700;}
.relationVote .rvTitle span{ color: var(--blue100); margin-right: 8px;}
.relationVote .list{ display: flex; flex-wrap: wrap; margin-top: 8px;}
.relationVote .list ul{ width:calc(33.3333333333% - 13px); border: solid 1px var(--gray30); border-radius: 8px; overflow: hidden; margin: 16px 0 0 16px;}
.relationVote .list ul:nth-child(3n+1){ margin: 16px 0 0 0;}
.relationVote .list ul .thumb{ position: relative; height:240px; overflow: hidden;}
.relationVote .list ul .thumb img{position: absolute; top:0; left: 0; width:100%; height: 100%; object-fit: cover;}
.relationVote .list ul .info{ position: relative; padding: 16px;}
.relationVote .list ul .info .date{ font-size: 12px; line-height: 18px; font-weight: 500; color: var(--gray70);}
.relationVote .list ul .info .title{ font-size: 16px; line-height: 24px; font-weight: 600; color: var(--gray110);}
.relationVote .list ul .info .btn{ position: absolute; top:calc(50% - 18px); right: 16px; border-radius: 8px; background-color: var(--gray20); width:49px; height: 36px; line-height: 36px; text-align: center; font-weight: 600; color: var(--gray90); cursor: pointer;}


@media(max-width:800px) {
  .relationVote{ padding: 20px 20px 0 20px;}
  .relationVote .rvTitle{ font-size: 20px; line-height: 30px; font-weight: 700;}
  .relationVote .rvTitle span{ color: var(--blue100); margin-right: 8px;}
  .relationVote .list{ display: flex; flex-wrap: wrap; margin-top: 0;}
  .relationVote .list ul{ width:calc(50% - 10px); border: solid 1px var(--gray30); border-radius: 8px; overflow: hidden; margin: 16px 0 0 16px;}
  .relationVote .list ul:nth-child(3n+1){ margin: 16px 0 0 16px;}
  .relationVote .list ul:nth-child(2n+1){ margin: 16px 0 0 0;}
  .relationVote .list ul .thumb{ position: relative; height:190px; overflow: hidden;}
}

@media(max-width:480px) {
  .relationVote .list ul{ width:calc(100% - 2px); border: solid 1px var(--gray30); border-radius: 8px; overflow: hidden; margin: 16px 0 0 0;}
  .relationVote .list ul:nth-child(3n+1){ margin: 16px 0 0 0;}
  .relationVote .list ul:nth-child(2n+1){ margin: 16px 0 0 0;}
  .relationVote .list ul .thumb{ position: relative; height:190px; overflow: hidden;}
}

.loginLayer{ position: fixed; top:0; left: 0; width:100%; height: 100%; background-color: rgba(0,0,0,0.6); z-index: 950;}
.loginLayer .box{ position: absolute; top:50%; left:50%; transform: translate(-50%, -50%); width:400px; background-color: #fff; border-radius: 16px; padding-bottom: 32px;}
.loginLayer .box .close{ position: absolute; top:16px; right: 16px; width:32px; height: 32px; border-radius: 32px; background-color: var(--gray30); text-align: center; cursor: pointer;}
.loginLayer .box .close img{ vertical-align: middle; margin-top: 4px;}
.loginLayer .box .logo{ text-align: center; padding: 64px 0 0 0;}
.loginLayer .box .title{ font-size: 18px; line-height: 27px; font-weight: 600; text-align: center; padding: 24px 0 40px 0;}
.loginLayer .box .btn{ text-align: center;}
.loginLayer .box .btn ul{ margin-bottom: 12px;}
.loginLayer .box .btn img{ cursor: pointer;}
.loginLayer .box .info{ padding: 28px 32px 0 32px; line-height: 21px; color: var(--gray70);}
.loginLayer .box .info ul{ position: relative; padding: 0 0 0 10px; margin-bottom: 4px;}
.loginLayer .box .info ul::before{ content: ''; position: absolute; top:8px; left: 0; width:3px; height: 3px; border-radius: 3px; background-color: var(--gray40);}
.loginLayer .box .info ul:last-child{ margin-bottom: 0;}

.loginPage .box{ position: absolute; top:50%; left:50%; transform: translate(-50%, -60%); width:400px; background-color: #fff; border-radius: 16px; padding-bottom: 32px;}
.loginPage .box .close{ position: absolute; top:16px; right: 16px; width:32px; height: 32px; border-radius: 32px; background-color: var(--gray30); text-align: center; cursor: pointer;}
.loginPage .box .close img{ vertical-align: middle; margin-top: 4px;}
.loginPage .box .logo{ text-align: center; padding: 64px 0 0 0;}
.loginPage .box .title{ font-size: 18px; line-height: 27px; font-weight: 600; text-align: center; padding: 24px 0 40px 0;}
.loginPage .box .btn{ text-align: center;}
.loginPage .box .btn ul{ margin-bottom: 12px;}
.loginPage .box .btn img{ cursor: pointer;}
.loginPage .box .info{ padding: 28px 32px 0 32px; line-height: 18px; color: var(--gray70);}
.loginPage .box .info ul{ position: relative; padding: 0 0 0 10px; margin-bottom: 4px; font-size: 12px;}
.loginPage .box .info ul a{ font-weight: 600; text-decoration: underline; color: var(--gray100);}
.loginPage .box .info ul span{ font-weight: 600; color: var(--gray100);}
.loginPage .box .info ul::before{ content: ''; position: absolute; top:8px; left: 0; width:3px; height: 3px; border-radius: 3px; background-color: var(--gray40);}
.loginPage .box .info ul:last-child{ margin-bottom: 0;}

@media(max-width:480px) {
  .loginLayer .box{ position: absolute; top:50%; left:50%; transform: translate(-50%, -50%); width:calc(100% - 40px); background-color: #fff; border-radius: 16px; padding-bottom: 32px;}
  .loginLayer .box .btn{ padding: 0 20px 0 20px;}
  .loginLayer .box .btn img{ width:100%;}
  .loginLayer .box .info{ padding: 28px 20px 0 20px; line-height: 18px; color: var(--gray70);}
  .loginPage .box{ width:calc(100% - 40px);}
  .loginPage .box .btn{ padding: 0 20px 0 20px;}
  .loginPage .box .btn img{ width:100%;}
  .loginPage .box .info{ padding: 28px 20px 0 20px; line-height: 21px; color: var(--gray70);}
}

.voteList{ width:592px; margin: 0 auto; padding: 40px 0 0 0; text-align: center;}
.voteList .box{ border: solid 1px var(--gray30); border-radius: 16px; overflow: hidden; background-color: #fff;}
.voteList .box .top{ position: relative; display: flex; align-items: center; justify-content: center; height: 200px;}
.voteList .box .info{ position: relative; color: #fff; z-index: 100;}
.voteList .box .info li{ width:100%; height: auto; text-align: center;}
.voteList .box .info .date{ line-height: 21px; color: rgba(255,255,255,0.7); margin-bottom: 6px;}
.voteList .box .info .title{ font-size: 32px; line-height: 48px; font-weight: 700;}
.voteList .box .top .mask{position: absolute; top:0 ;left: 0; width:100%; height: 100%; background-color: rgba(0,0,0,0.6); z-index: 20;}
.voteList .box .top .bg{ position: absolute; top:0 ;left: 0; width:100%; height: 100%; z-index: 10;}
.voteList .box .top .bg img{position: absolute; top:0; left: 0; width:100%; height: 100%; object-fit: cover;}
.voteList .box .list{ padding: 4px 0 0 0;}
.voteList .box .list ul{ display: flex; align-items: center; padding: 16px 0 16px 0; border-bottom: solid 1px var(--gray20); margin: 0 20px 0 20px;}
.voteList .box .list ul:last-child{ border-bottom: 0;}
.voteList .box .list ul .rank{ width:80px; display: inline-flex; align-items: center; text-align: center; margin-right: 12px; font-size: 18px; line-height: 27px; font-weight: 700; color: var(--gray100);}
.voteList .box .list ul .rank .num{ width:40px;}
.voteList .box .list ul .rank .rankMark{ width:40px; text-align: left;}
.voteList .box .list ul:nth-child(1) .rank{ color: var(--blue100);}
.voteList .box .list ul:nth-child(2) .rank{ color: var(--blue100);}
.voteList .box .list ul:nth-child(3) .rank{ color: var(--blue100);}
.voteList .box .list ul .info{ display: flex; align-items: center;}
.voteList .box .list ul .info .thumb{ position: relative; width:48px; height: 48px; border-radius: 8px; overflow: hidden; margin-right: 12px;}
.voteList .box .list ul .info .thumb img{position: absolute; top:0; left: 0; width:100%; height: 100%; object-fit: cover;}
.voteList .box .list ul .info .thumb::before{ content: ''; position: absolute; top:0; left: 0; width:calc(100% - 2px); height:calc(100% - 2px); border-radius: 8px; border: solid 1px rgba(0,0,0,0.08); z-index: 100;}
.voteList .box .list ul .info .txt .cate{ display: flex; align-items: center; font-size: 11px; line-height: 16px; font-weight: 500; color: var(--gray60); margin-top: 6px;}
.voteList .box .list ul .info .txt .cate .dot{ width:8px; height: 8px; background: url("https://cdn.trend.rankify.best/dctrend/front/images/ico_dot.svg"); margin: 0 4px 2px 4px;}
.voteList .box .list ul .info .txt .name{ font-size: 14px; line-height: 21px; color: #101828; font-weight: 600; text-align: left;}
.voteList .box .list ul .info .txt .scoreIn{ color: var(--gray90); text-align: left; font-weight: 600; display: none;}
.voteList .box .list ul .score{ margin-left: auto; font-weight: 600; color: var(--gray90);}
.voteList .box .list ul .btn{ width:58px; height: 36px; line-height: 36px; border-radius: 8px; text-align: center; background-color: var(--gray20); font-weight: 600; color: var(--gray90); cursor: pointer; margin-left: 12px;}
.voteList .another{ display: inline-table; padding: 16px 20px; font-size: 16px; line-height: 24px; font-weight: 600; color: var(--gray70); margin-top: 16px; cursor: pointer;}

@media(max-width:800px) {
  .voteList{ width:418px;}
  .voteList .box .top{ position: relative; display: flex; align-items: center; justify-content: center; height: 142px;}
  .voteList .box .info .title{ font-size: 24px; line-height: 36px; font-weight: 700;}
  .voteList .box .list ul .rank{ width:45px; display: inline-flex; flex-wrap: wrap; align-items: center; text-align: center; margin-right: 12px; font-size: 18px; line-height: 27px; font-weight: 700; color: var(--gray100);}
  .voteList .box .list ul .rank .num{ width:45px;}
  .voteList .box .list ul .rank .rankMark{ width:auto; margin: 0 auto; text-align: center;}
}
@media(max-width:480px) {
  .voteList{ width:calc(100% - 40px); margin: 0 auto; padding: 20px 0 0 0; text-align: center;}
  .voteList .box .list ul{ display: flex; align-items: center; padding: 16px 0 16px 0; border-bottom: solid 1px var(--gray20); margin: 0 16px 0 16px;}
  .voteList .box .list ul .info .txt .cate{ margin-top: 0;}
  .voteList .box .list ul .info .txt .scoreIn{  display: block;}
  .voteList .box .list ul .score{ display: none;}
  .voteList .box .list ul a{ margin-left: auto;}
}

.voteWrap{ width:592px; margin: 0 auto; padding: 40px 0 0 0;}
.voteWrap .box{ border: solid 1px var(--gray30); border-radius: 16px; background-color: #fff; padding: 20px;}
.voteWrap .box .voteInfo .category{ display: flex; align-items: center; line-height: 16px; color: var(--gray70);}
.voteWrap .box .voteInfo .title{ font-size: 20px; line-height: 30px; padding: 12px 0 0 0; font-weight: 600;}
.voteWrap .box .voteInfo .date{ line-height: 21px; color: var(--gray70);}
.voteWrap .box .voteInfo .item{ display: flex; margin-top: 20px; border: solid 1px var(--gray30); border-radius: 8px; padding: 8px;}
.voteWrap .box .voteInfo .item .thumb{ position: relative; width:64px; height: 64px; border-radius: 8px; overflow: hidden; margin-right: 12px;}
.voteWrap .box .voteInfo .item .thumb img{position: absolute; top:0; left: 0; width:100%; height: 100%; object-fit: cover;}
.voteWrap .box .voteInfo .item .thumb::before{ content: ''; position: absolute; top:0; left: 0; width:calc(100% - 2px); height:calc(100% - 2px); border-radius: 8px; border: solid 1px rgba(0,0,0,0.08); z-index: 100;}
.voteWrap .box .voteInfo .item ul:nth-child(2){ display: flex; flex-wrap: wrap; justify-content: center; align-items: center; padding: 8px 0 8px 0;}
.voteWrap .box .voteInfo .item ul:nth-child(2) li{ width:100%;}
.voteWrap .box .voteInfo .item .cate{ line-height: 21px; color: var(--gray60);}
.voteWrap .box .voteInfo .item .name{ font-size: 16px; line-height: 24px; font-weight: 600; color: var(--gray90);}

.voteWrap .box .ticket{ margin-top: 32px;}
.voteWrap .box .ticket .myTicket{ display: flex;}
.voteWrap .box .ticket .myTicket .my{ line-height: 36px; font-size: 16px; font-weight: 600; color: var(--gray60); margin-right: 16px;}
.voteWrap .box .ticket .myTicket .my span{ color: var(--blue100); font-weight: 700;}
.voteWrap .box .ticket .myTicket .btn{ display: flex; align-items: center; border-radius: 8px; border: solid 1px var(--gray30); line-height: 34px; color: var(--gray90); font-weight: 600; padding: 0 12px 0 12px; cursor: pointer;}
.voteWrap .box .ticket .ticketUse{ display: flex; margin-top: 20px;}
.voteWrap .box .ticket .ticketUse .inpBox{ display: flex; align-items: center; width:calc(100% - 112px); height: 56px; border-radius: 8px; background-color: var(--gray20); margin-right: 16px;}
.voteWrap .box .ticket .ticketUse .inpBox .tit{ padding: 0 0 0 16px; line-height: 56px; font-size: 16px;  color: var(--gray90); font-weight: 600;}
.voteWrap .box .ticket .ticketUse .inpBox .inp{ flex-grow: 1;}
.voteWrap .box .ticket .ticketUse .inpBox .inp input{ border: 0; width:100%; line-height: 56px; background-color: transparent; outline: none; text-align: right; font-size: 16px; font-weight: 600; color: var(--blue100);}
.voteWrap .box .ticket .ticketUse .inpBox .unit{ font-size: 16px; line-height: 56px; font-weight: 600; color: var(--blue100); padding: 0 16px 0 4px;}
.voteWrap .box .ticket .ticketUse .btn{ flex-grow: 1; background-color: var(--gray110); border-radius: 8px; line-height: 56px; text-align: center; color: #fff; font-size: 16px; font-weight: 600; cursor: pointer;}
.voteWrap .box .ticket .all{ margin-top: 16px; text-align: right;}
.voteWrap .box .ticket .bntVote{ line-height: 56px; border-radius: 8px; text-align: center; color: #fff; background-color: var(--blue100); font-size: 16px; font-weight: 600; cursor: pointer; margin-top: 80px;}

.voteWrap .box .voteResult{ margin-top: 32px;}
.voteWrap .box .voteResult .title{ font-size: 20px; line-height: 30px; font-weight: 600; text-align: center; padding: 16px 0 8px 0;}
.voteWrap .box .voteResult .txt{ font-size: 16px; line-height: 24px; text-align: center;}
.voteWrap .box .voteResult .txt span{ font-weight: 600; color: var(--blue100);}
.voteWrap .box .voteResult .list{ padding: 36px 0 48px 0;}
.voteWrap .box .voteResult .list ul{ position: relative; display: flex; align-items: center; padding: 0 12px 0 12px; width:374px; height: 60px; margin: 0 auto; border: solid 1px var(--gray30); border-radius: 8px; margin-bottom: 8px;}
.voteWrap .box .voteResult .list ul:last-child{ margin-bottom: 0;}
.voteWrap .box .voteResult .list .rank{ width:24px; text-align: center; font-size: 16px; font-weight: 700; color: var(--gray70); margin-right: 16px;}
.voteWrap .box .voteResult .list .thumb{ position: relative; width:32px; height: 32px; border-radius: 8px; overflow: hidden; margin-right: 12px;}
.voteWrap .box .voteResult .list .thumb img{position: absolute; top:0; left: 0; width:100%; height: 100%; object-fit: cover;}
.voteWrap .box .voteResult .list .thumb::before{ content: ''; position: absolute; top:0; left: 0; width:calc(100% - 2px); height:calc(100% - 2px); border-radius: 8px; border: solid 1px rgba(0,0,0,0.08); z-index: 100;}
.voteWrap .box .voteResult .list .info{}
.voteWrap .box .voteResult .list .info .cate{ display: flex; align-items: center; font-size: 11px; color: var(--gray60);}
.voteWrap .box .voteResult .list .info .cate .dot{ width:8px; height: 8px; background: url("https://cdn.trend.rankify.best/dctrend/front/images/ico_dot.svg"); margin: 0 2px 2px 2px;}
.voteWrap .box .voteResult .list .info .name{ font-weight: 600; color: var(--gray100);}
.voteWrap .box .voteResult .list .score{ margin-left: auto; font-weight: 600; color: var(--gray90);}
.voteWrap .box .voteResult .btnBox{ display: flex;}
.voteWrap .box .voteResult .btnBox a{ width:calc(50% - 6px);}
.voteWrap .box .voteResult .btnBox span{ width:calc(50% - 6px);}
.voteWrap .box .voteResult .btnBox ul{ width:100%; line-height: 56px; border-radius: 8px; text-align: center; font-weight: 600; font-size: 16px; color: var(--gray90); background-color: var(--gray20); cursor: pointer;}
.voteWrap .box .voteResult .btnBox .report{ margin-left: 12px; background-color: var(--blue100); color: #fff;}
.voteWrap .box .voteResult .list ul.on .score{ margin-left: auto; font-weight: 600; color: var(--blue100);}
.voteWrap .box .voteResult .list ul.on::before{ content: ''; position: absolute; top:-1px; left: -1px; width:calc(100% - 2px); height: calc(100% - 2px); border: solid 2px var(--blue100); border-radius: 8px;}
.voteWrap .backVote{ display: inline-table; padding: 16px 20px; font-size: 16px; line-height: 24px; font-weight: 600; color: var(--gray70); margin-top: 16px; cursor: pointer;}

@media(max-width:800px) {
  .voteWrap{ width:418px; margin: 0 auto; padding: 20px 0 0 0;}
}

@media(max-width:480px) {
  .voteWrap{ width:calc(100% - 40px); margin: 0 auto; padding: 20px 0 0 0;}
  .voteWrap .box .ticket{ position: relative;}
  .voteWrap .box .ticket .ticketUse{ display: flex; flex-wrap: wrap; margin-top: 20px;}
  .voteWrap .box .ticket .ticketUse .inpBox{ display: flex; align-items: center; width:100%; height: 56px; border-radius: 8px; background-color: var(--gray20); margin-right: 0;}
  .voteWrap .box .ticket .ticketUse .inpBox .tit{ padding: 0 0 0 16px; width:120px; line-height: 56px; font-size: 16px;  color: var(--gray90); font-weight: 600;}
  .voteWrap .box .ticket .ticketUse .inpBox .inp{ flex-grow: 1;}
  .voteWrap .box .ticket .ticketUse .inpBox .inp input{ border: 0; width:100%; line-height: 56px; background-color: transparent; outline: none; text-align: right; font-size: 16px; font-weight: 600; color: var(--blue100);}
  .voteWrap .box .ticket .ticketUse .inpBox .unit{ font-size: 16px; line-height: 56px; font-weight: 600; color: var(--blue100); padding: 0 16px 0 4px;}
  .voteWrap .box .ticket .ticketUse .btn{ flex-grow: 0; width:102px; margin-left: auto; margin-top: 12px; background-color: var(--gray110); border-radius: 8px; line-height: 48px; text-align: center; color: #fff; font-size: 16px; font-weight: 600; cursor: pointer;}
  .voteWrap .box .ticket .all{ position: absolute; bottom: 110px; left: 0; margin-top: 16px; text-align: right;}
  .voteWrap .box .ticket .bntVote{ line-height: 56px; border-radius: 8px; text-align: center; color: #fff; background-color: var(--blue100); font-size: 16px; font-weight: 600; cursor: pointer; margin-top: 40px;}
  .voteWrap .box .voteResult .title{ font-size: 20px; line-height: 30px; font-weight: 600; text-align: center; padding: 0 0 8px 0;}
  .voteWrap .box .voteResult .list{ padding: 36px 0 36px 0;}
  .voteWrap .box .voteResult .list ul{ position: relative; display: flex; align-items: center; padding: 0 12px 0 12px; width:calc(100% - 26px); height: 60px; margin: 0 auto; border: solid 1px var(--gray30); border-radius: 8px; margin-bottom: 8px;}
  .voteWrap .box .voteResult .btnBox ul{ width:100%; line-height: 56px; border-radius: 8px; text-align: center; font-weight: 600; font-size: 14px; color: var(--gray90); background-color: var(--gray20); cursor: pointer;}
}

.voteWrap .targetVote{ display: flex;}
.voteWrap .targetVote .thumb{ position: relative; width:77px; height: 48px; border-radius: 4px; overflow: hidden; margin-right: 12px;}
.voteWrap .targetVote .thumb img{position: absolute; top:0; left: 0; width:100%; height: 100%; object-fit: cover;}
.voteWrap .targetVote .thumb::before{ content: ''; position: absolute; top:0; left: 0; width:calc(100% - 2px); height:calc(100% - 2px); border-radius: 8px; border: solid 1px rgba(0,0,0,0.08); z-index: 100;}
.voteWrap .targetVote .info .title{ font-size: 16px; line-height: 24px; font-weight: 600; padding: 4px 0 0 0;}
.voteWrap .targetVote .info .date{ font-size: 12px; line-height: 18px; color: var(--gray70);}
.voteWrap .anotherSearch .title{ text-align: center; font-size: 24px; line-height: 36px; font-weight: 700; padding: 20px 0 0 0;}
.voteWrap .anotherSearch .txt{ text-align: center; font-size: 16px; line-height: 24px; color: var(--gray90);}
.voteWrap .anotherSearch .inpBox{ display: flex; padding: 40px 0 16px 0;}
.voteWrap .anotherSearch .inpBox .inp{ position: relative; flex-grow: 1; display: flex; height: 48px; border-radius: 8px; background-color: var(--gray20);}
.voteWrap .anotherSearch .inpBox .inp img{ width:24px; margin: 0 8px 0 16px;}
.voteWrap .anotherSearch .inpBox .inp input{ flex-grow: 1; border: 0; outline: none; line-height: 48px; font-size: 16px; font-weight: 500; background-color: transparent;}
.voteWrap .anotherSearch .inpBox .inp input::placeholder{ font-size: 16px; font-weight: 500; color: var(--gray50);}
.voteWrap .anotherSearch .inpBox .btn{ width:76px; line-height: 48px; text-align: center; border-radius: 8px; background-color: var(--blue100); margin-left: 8px; color: #fff; font-size: 16px; font-weight: 600;}
.voteWrap .anotherSearch .inpBox .inp .searchKeyword{ position: absolute; display: inline-flex; top:8px; left: 56px; height: 30px; line-height: 30px; border-radius: 30px; border: solid 1px var(--gray30); font-weight: 600; color: var(--gray90); align-items: center; background-color: #fff; padding: 0 10px 0 12px;}
.voteWrap .anotherSearch .inpBox .inp .searchKeyword img{ width:16px; margin: 6px 0 0 0;}
.voteWrap .anotherSearch .inpBox .inp .searchKeyword ul:nth-child(2){ padding: 0 0 0 4px; cursor: pointer;}
.voteWrap .anotherResult{}
.voteWrap .anotherResult .item{ display: flex; align-items: center; border: solid 1px var(--gray30); border-radius: 8px; padding: 8px 16px 8px 8px;}
.voteWrap .anotherResult .item .thumb{ position: relative; width:64px; height: 64px; border-radius: 8px; overflow: hidden; margin-right: 12px;}
.voteWrap .anotherResult .item .thumb img{position: absolute; top:0; left: 0; width:100%; height: 100%; object-fit: cover;}
.voteWrap .anotherResult .item .thumb::before{ content: ''; position: absolute; top:0; left: 0; width:calc(100% - 2px); height:calc(100% - 2px); border-radius: 8px; border: solid 1px rgba(0,0,0,0.08); z-index: 100;}
.voteWrap .anotherResult .item .info{ padding: 4px 0 0 0;}
.voteWrap .anotherResult .item .info .cate{ font-size: 14px; line-height: 21px; color: var(--gray60);}
.voteWrap .anotherResult .item .info .name{ font-size: 16px; line-height: 24px; font-weight: 600; color: var(--gray90); padding: 4px 0 0 0;}
.voteWrap .anotherResult .item .check{ position: relative; width:56px; height: 36px; margin-left: auto; border-radius: 8px; background-color: var(--blue30);}
.voteWrap .anotherResult .item .check::before{ content: ''; position: absolute; top:calc(50% - 12px); left: calc(50% - 12px); width:24px; height: 24px; background: url("https://cdn.trend.rankify.best/dctrend/front/images/another_check.svg");}
.voteWrap .anotherResult .btn{ line-height: 56px; border-radius: 8px; text-align: center; color: #fff; background-color: var(--blue100); font-size: 16px; font-weight: 600; cursor: pointer; margin-top: 40px;}
.voteWrap .anotherResultNone{ padding: 20px 0 64px 0; text-align: center;}
.voteWrap .anotherResultNone ul:nth-child(1){ font-size: 16px; line-height: 24px; font-weight: 600; color: var(--gray70); padding-bottom: 8px;}
.voteWrap .anotherResultNone ul:nth-child(2){ line-height: 21px; color: var(--gray70);}

@media(max-width:480px) {
  .voteWrap .anotherSearch .title{ text-align: center; font-size: 20px; line-height: 30px; font-weight: 700; padding: 20px 0 0 0;}
  .voteWrap .anotherSearch .txt{ text-align: center; font-size: 14px; line-height: 21px; color: var(--gray90);}
  .voteWrap .anotherSearch .inpBox{ display: flex; padding: 32px 0 16px 0;}
  .voteWrap .anotherSearch .inpBox .inp{ display: flex; width:calc(100% - 72px); height: 48px; border-radius: 8px; background-color: var(--gray20);}
  .voteWrap .anotherSearch .inpBox .inp img{ width:24px; margin: 0 8px 0 8px;}
  .voteWrap .anotherSearch .inpBox .inp input{ flex-grow: 1; border: 0; outline: none; line-height: 48px; font-size: 14px; font-weight: 500; background-color: transparent;}
  .voteWrap .anotherSearch .inpBox .inp input::placeholder{ font-size: 14px; font-weight: 500; color: var(--gray50);}
  .voteWrap .anotherSearch .inpBox .btn{ width:64px !important; line-height: 48px; text-align: center; border-radius: 8px; background-color: var(--blue100); margin-left: 8px; color: #fff; font-size: 14px; font-weight: 600;}
  .voteWrap .anotherResult .item .thumb{ position: relative; width:56px; height: 56px; border-radius: 8px; overflow: hidden; margin-right: 12px;}
  .voteWrap .anotherSearch .inpBox .inp .searchKeyword{ left: 40px;}
}

.reportWrap{ padding: 40px 0 0 0;}
.reportWrap .top{ margin-bottom: 32px;}
.reportWrap .top .navi{ font-size: 16px; line-height: 24px; font-weight: 700; color: var(--blue100); margin-bottom: 8px;}
.reportWrap .top .title{ display: flex; align-items: center;}
.reportWrap .top .title .tit{ font-size: 32px; line-height: 48px; font-weight: 700; margin-right: 10px;}
.reportWrap .top .title .rank{ background-color: var(--blue100); line-height: 30px; border-radius: 8px; padding: 0 8px 0 8px; color: var(--blue40); font-size: 14px;}
.reportWrap .top .title .rank span{ font-weight: 600; color: #fff;}
.reportWrap .topIndex{ margin-bottom: 20px;}
.reportWrap .topIndex ul{ width:222px; height: 88px; background-color: #fff; border: solid 1px var(--gray30); border-radius: 8px;}
.reportWrap .topIndex ul li:nth-child(1){ padding: 16px 0 0 20px; font-size: 16px; font-weight: 600; line-height: 24px; color: var(--gray70); margin-bottom: 4px;}
.reportWrap .topIndex ul li:nth-child(2){ padding: 0 20px 16px 0; text-align: right; font-size: 20px; line-height: 30px; font-weight: 600; color: var(--gray100);}
.reportWrap .topIndex .swiper-wrapper{ height: auto;}
.reportWrap .topIndex .swiper-slide{ height: auto; width:auto;}
.reportWrap .topIndex .swiper-wrapper .swiper-slide:nth-child(1) ul{ background-color: var(--blue20); border: solid 1px var(--blue40);}
.reportWrap .topIndex .swiper-wrapper .swiper-slide:nth-child(1) ul li:nth-child(1){ color: var(--blue80);}
.reportWrap .topIndex .swiper-wrapper .swiper-slide:nth-child(1) ul li:nth-child(2){ color: var(--blue100);}
.reportWrap .trend{ display: flex;}
.reportWrap .trend .left{ position: relative; display: flex; flex-wrap: wrap; width:792px; margin-right: 20px;}
.reportWrap .trend .right{ width:386px;}
.reportWrap .dbox{ position: relative; background-color: #fff; border: solid 1px var(--gray30); border-radius: 12px; padding: 20px;}
.reportWrap .dbox .stit{ font-size: 20px; line-height: 30px; font-weight: 600;}
.reportWrap .trend .chart{ width:100%; margin-bottom: 20px;}
.reportWrap .trend .chart .chartBox{ width:100%; height: 240px; margin-top: 20px;}
.reportWrap .trend .chart .chartBox canvas{ width:100%; height: 100%;}
.reportWrap .trend .chart .dateSelect{ position: absolute; top:20px; right: 20px; display: flex;}
.reportWrap .trend .chart .dateSelect .tab{ display: flex; padding: 4px; border-radius: 7px; margin-right: 4px; background-color: var(--gray20);}
.reportWrap .trend .chart .dateSelect .tab ul{ line-height: 32px; padding: 0 12px 0 12px; font-weight: 600; color: var(--gray70); border-radius: 4px; cursor: pointer;}
.reportWrap .trend .chart .dateSelect .tab ul.on{ background-color: #fff; color: var(--gray100); box-shadow: 0 0 4px 0 #5C667B1F;}
.reportWrap .trend .chart .dateSelect .picker{ position: relative; display: flex; align-items: center; color: var(--gray50); background-color: var(--gray20); border-radius: 8px; height: 38px; border: solid 1px var(--gray40); padding-left: 8px;}
.reportWrap .trend .chart .dateSelect .picker.on{ background-color: #fff;}
.reportWrap .trend .chart .dateSelect .picker input{ color: var(--gray90);}
.reportWrap .trend .index{ height: 120px; margin: 0 0 20px 0;}
.reportWrap .trend .index.search{ width:243px;}
.reportWrap .trend .index.viral{ width:243px; margin: 0 20px 20px 20px;}
.reportWrap .trend .index.news{ width:140px;}
.reportWrap .trend .index .list{ margin-top: 14px;}
.reportWrap .trend .index .list ul{ display: flex; line-height: 21px; margin-bottom: 8px;}
.reportWrap .trend .index .list ul:last-child{ margin-bottom: 0;}
.reportWrap .trend .index .list ul li:nth-child(1){ color: var(--gray70);}
.reportWrap .trend .index .list ul li:nth-child(2){ margin-left: auto; font-weight: 600; color: var(--gray100);}
.reportWrap .trend .index .list ul:nth-child(3) li:nth-child(1){ color: #6E778D; font-weight: 600;}
.reportWrap .trend .index .list ul:nth-child(3) li:nth-child(2){ color: var(--blue100);}
.reportWrap .trend .keyword{ width:243px; height: 442px;}
.reportWrap .trend .keyword .list{ margin-top: 14px;}
.reportWrap .trend .keyword .list ul{ display: flex; margin-bottom: 16px; font-size: 16px; line-height: 24px;}
.reportWrap .trend .keyword .list ul:last-child{ margin-bottom: 0;}
.reportWrap .trend .keyword .list ul li:nth-child(1){ width:24px; margin-right: 8px; text-align: center; font-weight: 600; color: var(--blue100);}
.reportWrap .trend .keyword .list ul li:nth-child(2){ flex-grow: 1; color: var(--gray100); overflow: hidden; display: -webkit-box;  -webkit-box-orient: vertical; word-break: break-all; -webkit-line-clamp: 1; text-overflow: ellipsis;}
.reportWrap .trend .gender{ width:445px; height: 93px; margin-left: 20px;}
.reportWrap .trend .gender .list{ display: flex; margin-top: 20px;}
.reportWrap .trend .gender .list ul{ display: flex; align-items: center; width:calc(50% - 6px); height:43px; border-radius: 8px; font-weight: 600;}
.reportWrap .trend .gender .list ul:nth-child(1){ margin-right: 12px; border: solid 1px var(--blue40); background-color: var(--blue20); color: var(--blue100);}
.reportWrap .trend .gender .list ul:nth-child(2){ border: solid 1px var(--red40); background-color: var(--red20); color: var(--red90);}
.reportWrap .trend .gender .list ul li:nth-child(1){ padding-left: 16px;}
.reportWrap .trend .gender .list ul li:nth-child(2){ margin-left: auto; padding-right: 16px;}
.reportWrap .trend .age{ position: absolute; bottom: 0; right: 0; width:445px; height: 287px;}
.reportWrap .trend .age .list{ margin-top: 20px; border-top: solid 2px var(--blue100);}
.reportWrap .trend .age .list ul{ display: flex; height: 46px; border-bottom:  solid 1px var(--gray30);}
.reportWrap .trend .age .list ul:first-child{ margin-top: 4px;}
.reportWrap .trend .age .list ul:last-child{ border-bottom: 0;}
.reportWrap .trend .age .list ul li{ flex-grow: 1; text-align: center; height: 46px; line-height: 46px; font-size: 16px; font-weight: 600; color: var(--gray100);}
.reportWrap .trend .age .list ul li:nth-child(1){ border-right: solid 1px var(--gray30); color: var(--gray70);}

.reportWrap .trend .rankVote .rvCate{ line-height: 21px; color: var(--gray70); margin-bottom: 8px;}
.reportWrap .trend .rankVote .rvDate{ line-height: 21px; color: var(--gray70); margin-top: 4px;}
.reportWrap .trend .rankVote .list{ margin-top: 12px;}
.reportWrap .trend .rankVote .list ul{ position: relative; display: flex; align-items: center; padding: 12px 0 12px 0; border-bottom: solid 1px var(--gray20);}
.reportWrap .trend .rankVote .list ul:last-child{ border-bottom: 0;}
.reportWrap .trend .rankVote .list ul .rank{ width:40px; text-align: center; margin-right: 12px;}
.reportWrap .trend .rankVote .list ul .rank dl:nth-child(1){ font-size: 18px; line-height: 27px; font-weight: 700; color: var(--gray100);}
.reportWrap .trend .rankVote .list ul:nth-child(1) .rank dl:nth-child(1){ color: var(--blue100);}
.reportWrap .trend .rankVote .list ul:nth-child(2) .rank dl:nth-child(1){ color: var(--blue100);}
.reportWrap .trend .rankVote .list ul:nth-child(3) .rank dl:nth-child(1){ color: var(--blue100);}
.reportWrap .trend .rankVote .list ul .info{ display: flex;}
.reportWrap .trend .rankVote .list ul .info .thumb{ position: relative; width:48px; height: 48px; border-radius: 8px; overflow: hidden; margin-right: 12px;}
.reportWrap .trend .rankVote .list ul .info .thumb img{position: absolute; top:0; left: 0; width:100%; height: 100%; object-fit: cover;}
.reportWrap .trend .rankVote .list ul .info .thumb::before{ content: ''; position: absolute; top:0; left: 0; width:calc(100% - 2px); height:calc(100% - 2px); border-radius: 8px; border: solid 1px rgba(0,0,0,0.08); z-index: 100;}
.reportWrap .trend .rankVote .list ul .info .txt .cate{ display: flex; align-items: center; font-size: 11px; line-height: 16px; font-weight: 500; color: var(--gray60); margin-top: 6px;}
.reportWrap .trend .rankVote .list ul .info .txt .cate .dot{ width:8px; height: 8px; background: url("https://cdn.trend.rankify.best/dctrend/front/images/ico_dot.svg"); margin: 0 4px 2px 4px;}
.reportWrap .trend .rankVote .list ul .info .txt .name{ font-size: 14px; line-height: 21px; font-weight: 600;}
.reportWrap .trend .rankVote .list ul .score{ margin-left: auto; font-weight: 600; color: var(--gray90);}
.reportWrap .trend .rankVote .list ul:last-child .rank dl:nth-child(1){ color: var(--blue100);}
.reportWrap .trend .rankVote .list ul:last-child .info .txt .name{ color: var(--blue100);}
.reportWrap .trend .rankVote .list ul:last-child .score{ color: var(--blue100);}
.reportWrap .trend .rankVote .list ul:last-child::before{ content: ''; position: absolute; top:-1px; left: -21px; width:calc(100% + 38px); height: calc(100% - 2px); border: solid 2px var(--blue100); border-radius: 8px;}
.reportWrap .trend .rankVote .btn{ font-size: 16px; line-height: 56px; font-weight: 600; text-align: center; background-color: var(--blue100); color: #fff; border-radius: 8px; margin-top: 24px; cursor: pointer;}

.reportWrap .contents{ display: flex; flex-wrap: wrap; margin-top: 20px;}
.reportWrap .contents .stit{ font-size: 20px; line-height: 30px; font-weight: 600;}
.reportWrap .contents .more{ position: absolute; display: flex; top:20px; right: 20px; height: 30px; align-items: center; cursor: pointer;}
.reportWrap .contents .more .arw{ margin-left: 4px;}
.reportWrap .contents .news{ position: relative; width:750px; height: 420px; padding: 20px; border-radius: 12px; background-color: #fff; border: solid 1px var(--gray30);}
.reportWrap .contents .news .list{ margin-top: 20px;}
.reportWrap .contents .news .list ul{ display: flex; align-items: center; height: 64px; border-bottom: solid 1px var(--gray20); justify-content: center; cursor: pointer; text-align: center;}
.reportWrap .contents .news .list ul:last-child{ border-bottom: 0;}
.reportWrap .contents .news .list ul:first-child { height: 45px; border-radius: 4px; background-color: var(--gray20); font-weight: 600; color: var(--gray70);}
.reportWrap .contents .news .list ul li:nth-child(1){ width:56px; margin: 0 16px 0 16px;flex-shrink: 0;}
.reportWrap .contents .news .list ul li:nth-child(2){ flex-grow: 1;}
.reportWrap .contents .news .list ul li:nth-child(3){ width:160px; margin-right: 16px;flex-shrink: 0;}
.reportWrap .contents .news .list ul .thumb{ position: relative; width:40px !important; height: 40px; border-radius: 8px; overflow: hidden; margin: 0 24px 0 24px !important;}
.reportWrap .contents .news .list ul .thumb img{position: absolute; top:0; left: 0; width:100%; height: 100%; object-fit: cover;}
.reportWrap .contents .news .list ul .thumb::before{ content: ''; position: absolute; top:0; left: 0; width:calc(100% - 2px); height:calc(100% - 2px); border-radius: 8px; border: solid 1px rgba(0,0,0,0.08); z-index: 100;}
.reportWrap .contents .news .list ul .title{ text-align: left; font-size: 16px; font-weight: 600; color: var(--gray100); overflow: hidden; display: -webkit-box;  -webkit-box-orient: vertical; word-break: break-all; -webkit-line-clamp: 1; text-overflow: ellipsis;}
.reportWrap .contents .news .list ul .date{ color: var(--gray100);}

.reportWrap .contents .blogCafe{ position: relative; width:344px; height: 420px; padding: 20px; border-radius: 12px; background-color: #fff; border: solid 1px var(--gray30); margin: 0 0 0 20px;}
.reportWrap .contents .blogCafe .list{ margin-top: 20px;}
.reportWrap .contents .blogCafe .list ul{ position: relative; padding-left: 48px; font-size: 16px; font-weight: 600; color:var(--gray100); height: 60px; line-height: 60px; border-bottom: solid 1px var(--gray20); overflow: hidden; display: -webkit-box;  -webkit-box-orient: vertical; word-break: break-all; -webkit-line-clamp: 1; text-overflow: ellipsis;}
.reportWrap .contents .blogCafe .list ul.blog::before{ content: ''; position: absolute; top:14px; left: 0; width:32px; height: 32px; background: url("https://cdn.trend.rankify.best/dctrend/front/images/ico_blog.svg");}
.reportWrap .contents .blogCafe .list ul.cafe::before{ content: ''; position: absolute; top:14px; left: 0; width:32px; height: 32px; background: url("https://cdn.trend.rankify.best/dctrend/front/images/ico_cafe.svg");}
.reportWrap .contents .blogCafe .list ul:last-child{ border-bottom: 0;}

.reportWrap .contents .youtube{ position: relative; width:calc(100% - 2px); height: 424px; border-radius: 12px; background-color: #fff; border: solid 1px var(--gray30); margin: 20px 0 0 0;}
.reportWrap .contents .youtube .stit{ padding: 20px 0 0 20px;}
.reportWrap .contents .youtube .list{ position: relative; margin-top: 20px;}
.reportWrap .contents .youtube .list ul{ position: relative; width:183px; height: 327px; border-radius: 8px; overflow: hidden;}
.reportWrap .contents .youtube .list ul img{ position: absolute; top:0; left: 0; width:100%; height: 100%; object-fit: cover;}
.reportWrap .contents .youtube .swiper{ position: relative;}
.reportWrap .contents .youtube .swiper-slide{ width:auto; height: auto;}
.reportWrap .contents .youtube .swiper-wrapper{ height: auto;}
.reportWrap .contents .youtube .swiper-slide:first-child{ margin-left: 20px;}
.reportWrap .contents .youtube .swiper-slide:last-child{ margin-right: 20px;}

.reportWrap .contents .youtube .swiper-button-next {
  position: absolute;
  top: 22px;
  right: 0;
  width: 100px;
  height: 100%;
  z-index: 10;
  background: linear-gradient(to left, white 25%, transparent 75%);
  cursor: pointer;
}
.reportWrap .contents .youtube .swiper-button-prev {
  position: absolute;
  top: 22px;
  left: 0;
  width: 100px;
  height: 100%;
  z-index: 10;
  background: linear-gradient(to right, white 25%, transparent 75%);
  cursor: pointer;
}
.reportWrap .contents .youtube .swiper-button-prev.swiper-button-disabled,.reportWrap .contents .youtube .swiper-button-next.swiper-button-disabled {
  opacity: 0;
  cursor: auto;
  pointer-events: none;
}
.reportWrap .contents .youtube .swiper-button-next::after{ display: none;}
.reportWrap .contents .youtube .swiper-button-prev::after{ display: none;}
.reportWrap .contents .youtube .swiper-button-next::before{ content: ''; position: absolute; top: calc(50% - 24px); right: 5px; width: 48px; height: 48px; background: url("https://cdn.trend.rankify.best/dctrend/front/images/btn_page_next.svg"); background-size: contain;}
.reportWrap .contents .youtube .swiper-button-prev::before{ content: ''; position: absolute; top: calc(50% - 24px); left: 5px; width: 48px; height: 48px; background: url("https://cdn.trend.rankify.best/dctrend/front/images/btn_page_prev.svg"); background-size: contain;}


@media(max-width:800px) {
  .reportWrap{ padding: 20px 0 0 0;}
  .reportWrap .top{ margin-bottom: 24px; padding: 0 0 0 20px;}
  .reportWrap .top .navi{ font-size: 14px; line-height: 21px; font-weight: 700; color: var(--blue100); margin-bottom: 8px;}
  .reportWrap .top .title{ display: flex; align-items: center;}
  .reportWrap .top .title .tit{ font-size: 20px; line-height: 30px; font-weight: 700; margin-right: 10px;}
  .reportWrap .top .title .rank{ background-color: var(--blue100); line-height: 28px; border-radius: 28px; padding: 0 8px 0 8px; color: var(--blue40); font-size: 12px;}
  .reportWrap .top .title .rank span{ font-weight: 600; color: #fff;}
  .reportWrap .topIndex ul{ width:158px; height: 86px; background-color: #fff; border: solid 1px var(--gray30); border-radius: 8px;}
  .reportWrap .topIndex ul li:nth-child(1){ padding: 16px 0 0 20px; font-size: 14px; font-weight: 600; line-height: 21px; color: var(--gray70); margin-bottom: 4px;}
  .reportWrap .topIndex ul li:nth-child(2){ padding: 0 20px 16px 0; text-align: right; font-size: 20px; line-height: 30px; font-weight: 600; color: var(--gray100);}
  .reportWrap .topIndex .swiper-slide:first-child{ margin-left: 20px;}
  .reportWrap .topIndex .swiper-slide:last-child{ margin-right: 20px;}
  .reportWrap .trend{ display: flex; flex-wrap: wrap;}
  .reportWrap .trend .left{ position: relative; display: flex; flex-wrap: wrap; width:calc(100% - 40px); margin-right: 0; margin-left: 20px;}
  .reportWrap .trend .index.search{ width:calc(33.333333333333% - 14px);}
  .reportWrap .trend .index.viral{ width:calc(33.333333333333% - 76px); margin: 0 20px 20px 20px;}
  .reportWrap .trend .index.news{ width:calc(33.333333333333% - 76px);}
  .reportWrap .trend .keyword{ width:calc(33.333333333333% - 14px); height: 442px;}
  .reportWrap .trend .gender{ width:calc(66.6666666666666% - 90px); height: 93px; margin-left: 20px;}
  .reportWrap .trend .age{ position: absolute; bottom: 0; right: 0; width:calc(66.6666666666666% - 90px); height: 287px;}
  .reportWrap .trend .right{ width:calc(100% - 40px); margin: 20px 0 0 20px;}

  .reportWrap .contents .news{ position: relative; width:calc(50% - 72px); height: 410px; padding: 20px; border-radius: 12px; background-color: #fff; border: solid 1px var(--gray30); margin-left: 20px}
  .reportWrap .contents .news .list{ margin-top: 16px;}
  .reportWrap .contents .news .list ul{ position: relative; display: flex; align-items:center; height: 74px; border-bottom: solid 1px var(--gray20); justify-content:normal; cursor: pointer; text-align: center;}
  .reportWrap .contents .news .list ul:last-child{ border-bottom: 0;}
  .reportWrap .contents .news .list ul:first-child { display: none;}
  .reportWrap .contents .news .list ul li:nth-child(1){ width:56px; margin: 0 16px 0 16px;flex-shrink: 0;}
  .reportWrap .contents .news .list ul li:nth-child(2){ position: absolute; top:16px; left: 50px; width:calc(100% - 50px); flex-grow: 1;}
  .reportWrap .contents .news .list ul li:nth-child(3){ position: absolute; top:40px; left: 50px; width:calc(100% - 50px); margin-right: 0; text-align: left; flex-shrink: 0;}
  .reportWrap .contents .news .list ul .thumb{ position: relative; width:40px !important; height: 40px; border-radius: 8px; overflow: hidden; margin: 0 0 0 0 !important;}

  .reportWrap .contents .blogCafe{ position: relative; width:calc(50% - 72px); height: 410px; padding: 20px; border-radius: 12px; background-color: #fff; border: solid 1px var(--gray30); margin: 0 0 0 20px;}
  .reportWrap .contents .youtube{ position: relative; width:calc(100% - 42px); height: 308px; border-radius: 12px; background-color: #fff; border: solid 1px var(--gray30); margin: 20px 0 0 20px;}
  .reportWrap .contents .youtube .list ul{ position: relative; width:120px; height: 216px; border-radius: 8px; overflow: hidden;}
  .reportWrap .contents .youtube .swiper-button-next::before{ content: ''; position: absolute; top: calc(50% - 20px); right: 5px; width: 40px; height: 40px; background: url("https://cdn.trend.rankify.best/dctrend/front/images/btn_page_next.svg"); background-size: contain;}
  .reportWrap .contents .youtube .swiper-button-prev::before{ content: ''; position: absolute; top: calc(50% - 20px); left: 5px; width: 40px; height: 40px; background: url("https://cdn.trend.rankify.best/dctrend/front/images/btn_page_prev.svg"); background-size: contain;}
}

@media(max-width:480px) {
  .reportWrap .topIndex ul{ width:150px; height: 86px; background-color: #fff; border: solid 1px var(--gray30); border-radius: 8px;}
  .reportWrap .topIndex ul li:nth-child(1){ padding: 16px 0 0 16px; font-size: 14px; font-weight: 600; line-height: 21px; color: var(--gray70); margin-bottom: 4px;}
  .reportWrap .topIndex ul li:nth-child(2){ padding: 0 16px 16px 0; text-align: right; font-size: 20px; line-height: 30px; font-weight: 600; color: var(--gray100);}
  .reportWrap .trend .chart{ width:100%; margin-bottom: 20px;}
  .reportWrap .trend .chart .chartBox{ width:100%; height: 240px; margin-top: 20px;}
  .reportWrap .trend .chart .chartBox canvas{ width:100%; height: 100%;}
  .reportWrap .trend .chart .dateSelect{ position: relative; top:0; right: 0; display: flex; flex-wrap: wrap; margin-top: 8px;}
  .reportWrap .trend .chart .dateSelect .tab{ display: flex; width:100%; padding: 4px; border-radius: 7px; margin-right: 0; background-color: var(--gray20);}
  .reportWrap .trend .chart .dateSelect .tab ul{ line-height: 32px; padding: 0 0 0 0; text-align: center; width:25%; font-size: 13px; font-weight: 600; color: var(--gray70); border-radius: 4px; cursor: pointer;}
  .reportWrap .trend .chart .dateSelect .tab ul.on{ background-color: #fff; color: var(--gray100); box-shadow: 0 0 4px 0 #5C667B1F;}
  .reportWrap .trend .chart .dateSelect .picker{ width:100%; margin-top: 8px;}
  .reportWrap .trend .index{ height: auto; margin: 0 0 20px 0;}
  .reportWrap .trend .index.search{ width:100%;}
  .reportWrap .trend .index.viral{ width:100%; margin: 0 0 20px 0;}
  .reportWrap .trend .index.news{ width:100%;}
  .reportWrap .trend .keyword{ width:100%; height: 442px;}
  .reportWrap .trend .gender{ width:100%; height: 93px; margin: 20px 0 20px 0;}
  .reportWrap .trend .age{ position: relative; bottom: 0; right: 0; width:100%; height: 287px;}

  .reportWrap .contents .news{ position: relative; width:calc(100% - 82px); height: 410px; padding: 20px; border-radius: 12px; background-color: #fff; border: solid 1px var(--gray30); margin-left: 20px; margin-bottom: 20px;}
  .reportWrap .contents .blogCafe{ position: relative; width:calc(100% - 82px); height: 410px; padding: 20px; border-radius: 12px; background-color: #fff; border: solid 1px var(--gray30); margin: 0 0 0 20px;}
  .reportWrap .contents .youtube{ position: relative; width:calc(100% - 42px); height: 308px; border-radius: 12px; background-color: #fff; border: solid 1px var(--gray30); margin: 20px 0 0 20px;}
  .reportWrap .contents .youtube .list ul{ position: relative; width:120px; height: 216px; border-radius: 8px; overflow: hidden;}
}

.myPage{ width:592px; margin: 0 auto; padding: 40px 0 0 0;}
.myPage .topTitle{ font-size: 32px; line-height: 48px; font-weight: 700; margin-bottom: 24px;}
.myPage .ticketInfo{ margin-bottom: 64px;}
.myPage .ticketInfo .detail{ border: solid 1px var(--gray30); border-radius: 8px;}
.myPage .ticketInfo ul{ display: flex; align-items: center; height: 62px;}
.myPage .ticketInfo ul:nth-child(1){ border-bottom: solid 1px #f4f6fa;}
.myPage .ticketInfo ul li:nth-child(1){ padding: 0 0 0 20px; font-size: 16px; line-height: 24px; font-weight: 600; color: var(--gray70);}
.myPage .ticketInfo ul li:nth-child(2){ display: inline-flex; align-items: center; margin-left: auto; padding-right: 20px; font-size: 14px; line-height: 28px; font-weight: 600; color: var(--gray100);}
.myPage .ticketInfo ul li:nth-child(2) span{ font-size: 20px; margin-right: 4px;}
.myPage .ticketInfo ul.total{ border: solid 1px var(--blue30); background-color: var(--blue10); margin-bottom: 16px; border-radius: 8px;}
.myPage .ticketInfo ul.total.order{ margin-bottom: 0; margin-top: 16px;}
.myPage .ticketInfo ul.total li:nth-child(1){ font-size: 18px; color: var(--gray120);}
.myPage .ticketInfo ul.total li:nth-child(2){ position: relative; font-size: 16px; color: var(--blue100);}
.myPage .ticketInfo ul.total li:nth-child(2) span{ font-size: 24px; color: var(--blue100);}
.myPage .ticketInfo ul.total li:nth-child(2)::before{ content: ''; position: absolute; top:0; left: -30px; width:24px; height: 24px; background: url("https://cdn.trend.rankify.best/dctrend/front/images/ico_ticket.svg");}
.myPage .tab{ display: flex; height: 36px; border-bottom: solid 1px var(--gray30); margin-bottom: 24px;}
.myPage .tab ul{ position: relative; font-size: 16px; line-height: 24px; font-weight: 600; margin-right: 32px; padding-bottom: 12px; cursor: pointer;}
.myPage .tab ul.on{ color: var(--blue100);}
.myPage .tab ul.on::before{ content: ''; position: absolute; bottom: 0; left: 0; width:100%; height: 3px; background-color: var(--blue100);}
.myPage .tab ul:last-child{ margin-right: 0;}
.myPage .orderTicket .tit{ font-size: 20px; line-height: 30px; font-weight: 600;}
.myPage .orderTicket .list{ display: flex; flex-wrap: wrap; margin-bottom: 64px;}
.myPage .orderTicket .list ul{ display: flex; align-items: center; width:calc(50% - 10px); height: 54px; background-color: var(--gray10); border: solid 1px var(--gray30); border-radius: 12px; margin: 16px 0 0 16px; cursor: pointer;}
.myPage .orderTicket .list ul:nth-child(2n+1){ margin: 16px 0 0 0;}
.myPage .orderTicket .list ul li:nth-child(1){ position: relative; padding: 0 0 0 44px; font-size: 16px; line-height: 24px; font-weight: 600; color: var(--gray100);}
.myPage .orderTicket .list ul li:nth-child(2){ margin-left: auto; font-size: 16px; font-weight: 600; color: var(--blue100); padding-right: 16px;}
.myPage .orderTicket .list ul li:nth-child(1)::before{ content: ''; position: absolute; top:0; left: 16px; width:24px; height: 24px; background: url("https://cdn.trend.rankify.best/dctrend/front/images/ico_ticket.svg");}
.myPage .orderTicket .list ul:hover{ background-color: var(--gray30);}

.myPage .useInfo{ background-color: var(--gray10); border-radius: 8px; padding: 0 20px 20px 20px;}
.myPage .useInfo ul{ padding: 20px 0 0 0;}
.myPage .useInfo ul li:nth-child(1){ font-size: 16px; line-height: 24px; font-weight: 600; color: var(--gray90); margin-bottom: 8px;}
.myPage .useInfo ul li:nth-child(2) dl{ position: relative; line-height: 21px; color: var(--gray70); margin-bottom: 8px; padding-left: 11px;}
.myPage .useInfo ul li:nth-child(2) dl:last-child{ margin-bottom: 0;}
.myPage .useInfo ul li:nth-child(2) dl::before{ content: ''; position: absolute; top:8px; left: 0; width:3px; height: 3px; border-radius: 3px; background-color: var(--gray40);}

.myPage .withdraw{ height: 32px; display: inline-flex; align-items: center; font-size: 12px; font-weight: 600; color: var(--gray90); margin-top: 24px; cursor: pointer;}

.myPage .ticketOrder{ margin-bottom: 64px;}
.myPage .ticketOrder .detail{ border: solid 1px var(--gray30); border-radius: 8px;}
.myPage .ticketOrder ul{ display: flex; align-items: center; height: 62px;}
.myPage .ticketOrder ul:nth-child(1){ border-bottom: solid 1px #f4f6fa;}
.myPage .ticketOrder ul li:nth-child(1){ padding: 0 0 0 20px; font-size: 16px; line-height: 24px; font-weight: 600; color: var(--gray70);}
.myPage .ticketOrder ul li:nth-child(2){ position: relative; display: inline-flex; align-items: center; margin-left: auto; padding-right: 20px; font-size: 16px; line-height: 28px; font-weight: 600; color: var(--gray90);}
.myPage .ticketOrder ul li:nth-child(2)::before{ content: ''; position: absolute; top:0; left: -30px; width:24px; height: 24px; background: url("https://cdn.trend.rankify.best/dctrend/front/images/ico_ticket.svg");}
.myPage .ticketOrder ul li:nth-child(2) div{ position: relative; padding-left: 8px; margin-left: 8px;}
.myPage .ticketOrder ul li:nth-child(2) div::before{ content: ''; position: absolute; top:8px; left: 0; width:1px; height: 12px; background-color: var(--gray30);}
.myPage .ticketOrder ul li:nth-child(2) span{ font-size: 20px; margin-right: 4px;}
.myPage .ticketOrder ul.price{ border: solid 1px var(--blue30); background-color: var(--blue10); margin-top: 16px; border-radius: 8px;}
.myPage .ticketOrder ul.price.order{ margin-bottom: 0; margin-top: 16px;}
.myPage .ticketOrder ul.price li:nth-child(1){ font-size: 18px; color: var(--gray120);}
.myPage .ticketOrder ul.price li:nth-child(2){ position: relative; font-size: 16px; color: var(--blue100);}
.myPage .ticketOrder ul.price li:nth-child(2) span{ font-size: 24px; color: var(--blue100);}
.myPage .ticketOrder ul.price li:nth-child(2)::before{ display: none;}
.myPage .ticketOrder .btn{ line-height: 56px; border-radius: 8px; background-color: var(--blue100); color: #fff; font-size: 16px; font-weight: 600; text-align: center; margin-top: 40px; cursor: pointer;}

.myPage .votedList{ position: relative;}
.myPage .votedList .tit{ font-size: 20px; line-height: 30px; font-weight: 600;}
.myPage .votedList .sortBox{ position: absolute; top:0; right: 0;}
.myPage .votedList .list{ margin-top: 16px; margin-bottom: 64px;}
.myPage .votedList .list ul{ padding: 16px 20px; background-color: var(--gray10); border-radius: 8px; margin-bottom: 12px;}
.myPage .votedList .list ul:last-child{ margin-bottom: 0;}
.myPage .votedList .list ul .date{ font-size: 12px; line-height: 18px; font-weight: 500; color: var(--gray70); margin-bottom: 4px;}
.myPage .votedList .list ul .cate{ font-size: 16px; line-height: 24px; font-weight: 600; color: var(--gray100); margin-bottom: 8px;}
.myPage .votedList .list ul .info{ display: flex; align-items: center;}
.myPage .votedList .list ul .info{ font-size: 14px; line-height: 21px; font-weight: 600; }
.myPage .votedList .list ul .info .voted{color: var(--blue100);}
.myPage .votedList .list ul .info .ticket{ position: relative; color: var(--gray70); margin-left: 8px; padding-left: 8px;}
.myPage .votedList .list ul .info .ticket span{ color: var(--gray100);}
.myPage .votedList .list ul .info .ticket:before{ content: ''; position: absolute; top:4px; left: 0; width:1px; height: 12px; background-color: var(--gray40);}

.myPage .orderList{ position: relative;}
.myPage .orderList .tit{ font-size: 20px; line-height: 30px; font-weight: 600;}
.myPage .orderList .list{ margin-top: 16px; margin-bottom: 56px;}
.myPage .orderList .list ul{ display: flex; align-items: center; padding: 16px 20px; background-color: var(--gray10); border-radius: 8px; margin-bottom: 12px;}
.myPage .orderList .list ul .date{ width:120px; color: var(--gray70); line-height: 21px;}
.myPage .orderList .list ul .date .ticket{ display: none;}
.myPage .orderList .list ul .item{ flex-grow: 1; font-size: 16px; font-weight: 600; color: var(--gray100);}
.myPage .orderList .list ul .item div{ font-size: 14px; font-weight: 600; color: var(--gray70); padding-top: 4px;}
.myPage .orderList .list ul .price{ width:200px; text-align: right; font-size: 16px; font-weight: 600; color: var(--blue100); padding-right: 20px;}
.myPage .orderList .list ul .state{ width:76px; text-align: center; font-weight: 600; color: var(--gray50);}
.myPage .orderList .list ul .state.cancel{border: solid 1px var(--gray10); border-radius: 8px; line-height: 38px; color: var(--red90);}
.myPage .orderList .list ul .state .btn{ border: solid 1px var(--gray30); border-radius: 8px; line-height: 38px; background-color: #fff; color: var(--gray90); cursor: pointer;}

.refundBox{ width:368px;}
.refundBox .refundInfo{ border: solid 1px var(--gray30); border-radius: 8px; padding-top: 16px;}
.refundBox .refundInfo ul{ display: flex; align-items: center; padding: 0 16px 0 16px;}
.refundBox .refundInfo ul li:nth-child(1){ line-height: 21px; font-weight: 600; color: var(--gray70);}
.refundBox .refundInfo ul li:nth-child(2){ margin-left: auto; line-height: 21px; color: var(--gray90);}
.refundBox .refundInfo ul:nth-child(2){ margin: 12px 0 16px 0;}
.refundBox .refundInfo ul .item{ display: inline-flex; font-weight: 600; color: var(--gray90);}
.refundBox .refundInfo ul .item .qty{ position: relative;}
.refundBox .refundInfo ul .item .qty::before{ content: ''; position: absolute; top:-2px; left: -30px; width:24px; height: 24px; background: url("https://cdn.trend.rankify.best/dctrend/front/images/ico_ticket.svg");}
.refundBox .refundInfo ul .item .price{ position: relative; padding-left: 8px; margin-left: 8px;}
.refundBox .refundInfo ul .item .price:before{ content: ''; position: absolute; top:4px; left: 0; width:1px; height: 12px; background-color: var(--gray40);}
.refundBox .refundInfo ul:nth-child(3){ background-color: var(--blue10); border-top: solid 1px var(--blue30); height: 64px; font-size: 16px; font-weight: 600;}
.refundBox .refundInfo ul:nth-child(3) li:nth-child(1){ color: var(--gray120);}
.refundBox .refundInfo ul:nth-child(3) li:nth-child(2){ color: var(--blue100); display: inline-flex; align-items: center;}
.refundBox .refundInfo ul:nth-child(3) li:nth-child(2) span{ font-size: 20px; margin-right: 4px;}
.refundBox .botInfo{ background-color: var(--gray10); border-radius: 8px; padding: 16px; margin-top: 16px;}
.refundBox .botInfo .tit{ font-weight: 600; line-height: 21px; color: var(--gray90);}
.refundBox .botInfo .txt ul{ position: relative; font-size: 12px; line-height: 18px; color: var(--gray70); margin-top: 4px; padding-left: 11px;}
.refundBox .botInfo .txt ul::before{ content: ''; position: absolute; top:8px; left: 0; width:3px; height: 3px; border-radius: 3px; background-color: var(--gray40);}

.withdrawBox .rBox{ background-color: #fff; border-radius: 16px; border: solid 1px var(--gray30); padding: 20px 20px 24px 20px; margin-bottom: 24px;}
.withdrawBox .rBox .title{ font-size: 20px; line-height: 30px; font-weight: 600; margin-bottom: 16px;}
.withdrawBox .rBox .title .req{ display: inline-table; font-size: 16px; line-height: 30px; color: var(--red90); margin-left: 8px;}
.withdrawBox .rBox.form ul{ margin-bottom: 16px;}
.withdrawBox .rBox.form ul:last-child{ margin-bottom: 0;}
.withdrawBox .rBox.form ul li:nth-child(1){ font-size: 16px; line-height: 24px; font-weight: 600; color: var(--gray100);}
.withdrawBox .rBox.form ul li:nth-child(2) dl{ margin-top: 16px; line-height: 24px;}
.withdrawBox .rBox.info .feedback{ display: flex; align-items: center; line-height: 21px; color: var(--gray70); padding: 16px 0 16px 0;}
.withdrawBox .rBox.info .feedback img{ margin-right: 4px;}
.withdrawBox .rBox.info .agree .req{ display: inline-table; font-size: 16px; line-height: 30px; color: var(--red90); margin-left: 8px;}
.withdrawBox input[type=checkbox]{ width:18px; height: 18px; border: solid 1px var(--gray40) !important; border-radius: 6px; vertical-align: middle; margin: 0 8px 2px 0;}
.withdrawBox label{ font-size: 16px; line-height: 24px; color: var(--gray90);}
.withdrawBox .btnBox{ padding-top: 16px; display: flex;}
.withdrawBox .btnBox a{ width:calc(50% - 6px); line-height: 56px; border-radius: 8px; text-align: center; background-color: var(--blue100); color: #fff; font-size: 16px; font-weight: 600; cursor: pointer;}
.withdrawBox .btnBox button{ width:calc(50% - 6px); line-height: 56px; border-radius: 8px; text-align: center; background-color: var(--blue100); color: #fff; font-size: 16px; font-weight: 600; cursor: pointer;}
.withdrawBox .btnBox button{ margin-right: 12px; background-color: var(--gray110); color: #fff; border: 0;}
.withdrawBox .btnBox button:disabled{background-color: var(--gray30); color: var(--gray50); cursor: default;}

@media(max-width:800px) {
  .myPage{ width:418px; margin: 0 auto; padding: 20px 0 0 0;}
  .myPage .orderList .list ul .date{ width:84px; color: var(--gray70);}
  .myPage .orderList .list ul .price{ width:100px; text-align: right; font-size: 16px; font-weight: 600; color: var(--blue100); padding-right: 20px;}
}

@media(max-width:480px) {
  .myPage{ width:calc(100% - 40px); margin: 0 auto; padding: 20px 0 0 0;}
  .myPage .topTitle{ font-size: 24px; line-height: 36px; font-weight: 700; margin-bottom: 24px;}
  .myPage .ticketInfo{ margin-bottom: 64px;}
  .myPage .orderTicket .list{ display: flex; flex-wrap: wrap;}
  .myPage .orderTicket .list ul{ display: flex; align-items: center; width:calc(100% - 2px); height: 54px; background-color: var(--gray10); border: solid 1px var(--gray30); border-radius: 12px; margin: 16px 0 0 0; cursor: pointer;}
  .myPage .orderTicket .list ul:nth-child(2n+1){ margin: 16px 0 0 0;}
  .myPage .orderTicket .list ul li:nth-child(1){ position: relative; padding: 0 0 0 44px; font-size: 16px; line-height: 24px; font-weight: 600; color: var(--gray100);}
  .myPage .orderTicket .list ul li:nth-child(2){ margin-left: auto; font-size: 16px; font-weight: 600; color: var(--blue100); padding-right: 16px;}
  .myPage .orderTicket .list ul li:nth-child(1)::before{ content: ''; position: absolute; top:0; left: 16px; width:24px; height: 24px; background: url("https://cdn.trend.rankify.best/dctrend/front/images/ico_ticket.svg");}
  .myPage .orderTicket .list ul:hover{ background-color: var(--gray30);}

  .myPage .orderList .list{ margin-top: 16px;}
  .myPage .orderList .list ul{ position: relative; display: flex; align-items: center; padding: 16px 20px 48px 20px; background-color: var(--gray10); border-radius: 8px; margin-bottom: 12px;}
  .myPage .orderList .list ul .date{ display: flex; position: absolute; bottom: 14px; left: 20px; width:calc(100% - 20px); color: var(--gray70);}
  .myPage .orderList .list ul .date .ticket{ position: relative; display: block; font-weight: 600; margin-left: 8px; padding-left: 8px;}
  .myPage .orderList .list ul .date .ticket:before{ content: ''; position: absolute; top:4px; left: 0; width:1px; height: 12px; background-color: var(--gray40);}
  .myPage .orderList .list ul .item{ flex-grow: 1; font-size: 16px; font-weight: 600; color: var(--gray100);}
  .myPage .orderList .list ul .item div{ font-size: 14px; font-weight: 600; color: var(--gray70); padding-top: 4px;}
  .myPage .orderList .list ul .item .ticket{ display: none;}
  .myPage .orderList .list ul .price{ width:100px; text-align: right; font-size: 16px; font-weight: 600; color: var(--blue100); padding-right: 10px;}
  .myPage .orderList .list ul .state{ width:76px; text-align: center; font-weight: 600; color: var(--gray50);}
  .myPage .orderList .list ul .state.cancel{border: solid 1px var(--gray10); border-radius: 8px; line-height: 38px; color: var(--red90);}
  .myPage .orderList .list ul .state .btn{ border: solid 1px var(--gray30); border-radius: 8px; line-height: 38px; background-color: #fff; color: var(--gray90); cursor: pointer;}

  .refundBox{ width:100%;}

}

.gatherVote .tab{ display: flex; margin: 16px 0 40px 0;}
.gatherVote .tab.ended{ margin: 24px 0 0 0; border-bottom: solid 1px var(--gray30);}
.gatherVote .tab ul{ position: relative; height: 36px; line-height:24px; margin-right: 32px; font-size: 16px; font-weight: 600; cursor: pointer;}
.gatherVote .tab ul.on{ color: var(--blue100);}
.gatherVote .tab ul.on::before{ content: ''; position: absolute; bottom: 0; left: 0; width:100%; height: 3px; background-color: var(--blue100);}
.gatherVote .divideLine{ margin: 40px 0 40px 0; width:100%; height: 1px; background-color: var(--gray20);}
.gatherVote .gatherList{ position: relative; padding: 56px 0 0 0;}

.gatherVote .gatherList .list{ display: flex; flex-wrap: wrap; margin-top: 24px; padding-bottom: 16px;}
.gatherVote .gatherList .list.ended ul{ cursor: pointer;}
.gatherVote .gatherList .list ul{ width:calc(33.333333333333% - 12.7px); border: solid 1px var(--gray30); border-radius: 8px; overflow: hidden; margin: 0 0 40px 16px;}
.gatherVote .gatherList .list ul:nth-child(3n+1){ margin: 0 0 40px 0;}
.gatherVote .gatherList .list ul .thumb{ position: relative; height:240px; overflow: hidden;}
.gatherVote .gatherList .list ul .thumb img{position: absolute; top:0; left: 0; width:100%; height: 100%; object-fit: cover;}
.gatherVote .gatherList .list ul .info{ position: relative; padding: 16px;}
.gatherVote .gatherList .list ul .info .date{ font-size: 12px; line-height: 18px; font-weight: 500; color: var(--gray70);}
.gatherVote .gatherList .list ul .info .title{ font-size: 16px; line-height: 24px; font-weight: 600; color: var(--gray110);}
.gatherVote .gatherList .list ul .info .btn{ position: absolute; top:calc(50% - 18px); right: 16px; border-radius: 8px; background-color: var(--gray20); width:49px; height: 36px; line-height: 36px; text-align: center; font-weight: 600; color: var(--gray90); cursor: pointer;}
.gatherVote .gatherList .list ul .result{ display: flex; align-items: center; margin: 0 16px 0 16px; padding: 16px 0 16px 0; border-top: solid 1px var(--gray20);}
.gatherVote .gatherList .list ul .result .rank{ font-size: 16px; font-weight: 700; color: var(--blue100); margin-right: 12px;}
.gatherVote .gatherList .list ul .result .rankThumb{ position: relative; width:24px; height: 24px; border-radius: 8px; overflow: hidden; margin-right: 8px;}
.gatherVote .gatherList .list ul .result .rankThumb img{position: absolute; top:0; left: 0; width:100%; height: 100%; object-fit: cover;}
.gatherVote .gatherList .list ul .result .rankThumb::before{ content: ''; position: absolute; top:0; left: 0; width:calc(100% - 2px); height:calc(100% - 2px); border-radius: 8px; border: solid 1px rgba(0,0,0,0.08); z-index: 100;}
.gatherVote .gatherList .list ul .result .name{ font-weight: 600;}
.gatherVote .gatherList .list ul .result .score{ margin-left: auto; font-weight: 600; color: var(--gray90);}

.awardsTop{ position: relative; margin: 40px 0 0 0; height: 295px; border-radius: 16px; overflow: hidden;}
.awardsTop .swiper{ height: 295px;}
.awardsTop .item{ position: relative; display: flex; height: 100%; color: #fff;}
.awardsTop .item .left{ width:50%; height: 100%; margin-right: 32px; z-index: 200; text-align: right;}
.awardsTop .item .left .box{ display: inline-flex; flex-wrap: wrap; width:160px; height: 160px; margin-top: 48px;}
.awardsTop .item .left .thumb{ position: relative; width:160px; height: 160px; border-radius: 16px; overflow: hidden; margin-bottom: 12px;}
.awardsTop .item .left .thumb img{position: absolute; top:0; left: 0; width:100%; height: 100%; object-fit: cover;}
.awardsTop .item .left .txt{ display:inline-flex; align-items: center; line-height: 27px; font-size: 18px; color: var(--gray10); margin: 0 auto;}
.awardsTop .item .left .txt div{ margin: 0 2px 0 2px;}
.awardsTop .item .left .txt div.b{ font-weight: 700;}
.awardsTop .item .right{ width:50%; height: 100%; margin-left: 32px; z-index: 200; margin-top: 48px;}
.awardsTop .item .right .date{ line-height: 21px; color: rgba(255,255,255,0.6);}
.awardsTop .item .right .title{ font-size: 32px; line-height: 48px; font-weight: 700; color: #fff; margin-bottom: 12px;}
.awardsTop .item .right .info{ display: flex; line-height: 24px;}
.awardsTop .item .right .info .rank{ border-radius: 24px; width:36px; text-align: center; background-color: #fff; color: var(--blue100); font-weight: 600; margin-right: 12px;}
.awardsTop .item .right .info .name{ font-size: 16px; font-weight: 700; margin-right: 12px;}
.awardsTop .item .right .info .score{ font-size: 16px;}
.awardsTop .item .right .btn{ color: #fff; width:130px; height: 48px; line-height: 48px; text-align: center; border-radius: 8px; background-color: var(--blue100); font-size: 16px; font-weight: 600; margin-top: 32px; cursor: pointer;}
.awardsTop .item .mask{ position: absolute; top:0; left: 0; width:100%; height: 100%; z-index: 110; background-color: rgba(0,0,0,0.6); margin-bottom: 12px;}
.awardsTop .item .bg{ position: absolute; top:0; left: 0; width:100%; height: 100%; z-index: 100; overflow: hidden;}
.awardsTop .item .bg img{ position: absolute; top:-5%; left: -5%; width:110%; height: 110%; filter: blur(20px); object-fit: cover;}
.awardsTop .swiper-button-next,.awardsTop .swiper-button-prev {
  position: absolute;
  top: 50%;
  width: 48px;
  height: 48px;
  margin-top: -24px;
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
}
.awardsTop .swiper-button-next:after,.awardsTop .swiper-button-prev:after {
  font-size: 24px;
}
.awardsTop .swiper-pagination-fraction {
  bottom: 24px;
  left: auto;
  right: 24px;
  width: 50px;
  border-radius: 28px;
  line-height: 28px;
  background-color: rgba(0,0,0,0.6);
  color: rgba(255,255,255,0.4);
  font-size: 12px;
}
.awardsTop .swiper-pagination-fraction span{ color: rgba(255,255,255,1);}

@media(max-width:800px) {
  .gatherVote .tab{ display: flex; margin: 16px 0 40px 20px;}
  .gatherVote .tab.ended{ margin: 24px 20px 0 20px; border-bottom: solid 1px var(--gray30);}
  .gatherVote .gatherList .list{ display: flex; flex-wrap: wrap; margin-top: 24px; padding: 0 20px 16px 20px;}
  .gatherVote .gatherList .list ul{ width:calc(50% - 10px); border: solid 1px var(--gray30); border-radius: 8px; overflow: hidden; margin: 0 0 32px 16px;}
  .gatherVote .gatherList .list ul:nth-child(3n+1){ margin: 0 0 32px 16px;}
  .gatherVote .gatherList .list ul:nth-child(2n+1){ margin: 0 0 32px 0;}
  .gatherVote .gatherList .list ul .thumb{ position: relative; height:192px; overflow: hidden;}

  .awardsTop{ margin: 20px 20px 0 20px; height: 280px; border-radius: 16px; overflow: hidden;}
  .awardsTop .swiper{ height: 280px;}
  .awardsTop .item{ position: relative; display: flex; height: 100%; color: #fff;}
  .awardsTop .item .left{ width:45%; height: 100%; margin-right: 16px; z-index: 200; text-align: right;}
  .awardsTop .item .left .box{ display: inline-flex; flex-wrap: wrap; width:160px; height: 160px; margin-top: 48px;}
  .awardsTop .item .left .thumb{ position: relative; width:160px; height: 160px; border-radius: 16px; overflow: hidden; margin-bottom: 12px;}
  .awardsTop .item .left .thumb img{position: absolute; top:0; left: 0; width:100%; height: 100%; object-fit: cover;}
  .awardsTop .item .left .txt{ display:inline-flex; align-items: center; line-height: 27px; font-size: 14px; color: var(--gray10); margin: 0 auto;}
  .awardsTop .item .right{ width:55%; height: 100%; margin-left: 16px; z-index: 200; margin-top: 48px;}
  .awardsTop .item .right .date{ line-height: 21px; color: rgba(255,255,255,0.6);}
  .awardsTop .item .right .title{ font-size: 20px; line-height: 30px; font-weight: 700; color: #fff; margin-bottom: 12px;}

}
@media(max-width:480px) {
  .gatherVote .divideLine{ margin: 20px 0 20px 0; width:100%; height: 1px; background-color: #fff;}
  .gatherVote .gatherList{ position: relative; padding: 32px 0 0 0;}
  .gatherVote .sortBox{ top:32px;}
  .gatherVote .gatherList .list{ display: flex; flex-wrap: wrap; margin-top: 24px; padding: 0 20px 16px 20px;}
  .gatherVote .gatherList .list ul{ width:calc(100% - 2px); border: solid 1px var(--gray30); border-radius: 8px; overflow: hidden; margin: 0 0 16px 0;}
  .gatherVote .gatherList .list ul:nth-child(3n+1){ margin: 0 0 16px 0;}
  .gatherVote .gatherList .list ul:nth-child(2n+1){ margin: 0 0 16px 0;}
  .gatherVote .gatherList .list ul .thumb{ position: relative; height:208px; overflow: hidden;}

  .awardsTop{ margin: 20px 20px 0 20px; height: 300px; border-radius: 16px; overflow: hidden;}
  .awardsTop .swiper{ height: 300px;}
  .awardsTop .item{ position: relative; display: flex; flex-wrap: wrap; height: 100%; color: #fff;}
  .awardsTop .item .left{ width:100%; height: 80px !important; margin-right: 0; z-index: 200; text-align: center;}
  .awardsTop .item .left .box{ display: inline-flex; flex-wrap: wrap; width:80px; height: 80px; margin-top: 20px;}
  .awardsTop .item .left .thumb{ position: relative; width:80px; height: 80px; border-radius: 16px; overflow: hidden; margin-bottom: 0;}
  .awardsTop .item .left .thumb img{position: absolute; top:0; left: 0; width:100%; height: 100%; object-fit: cover;}
  .awardsTop .item .left .txt{ display: none;}
  .awardsTop .item .right{ width:100%; height: auto; margin-left: 0; z-index: 200; margin-top: 0; text-align: center;}
  .awardsTop .item .right .date{ font-size: 12px; line-height: 21px; color: rgba(255,255,255,0.6);}
  .awardsTop .item .right .title{ font-size: 20px; line-height: 30px; font-weight: 700; color: #fff; margin-bottom: 12px;}
  .awardsTop .item .right .info{ display: inline-flex; line-height: 24px;}
  .awardsTop .item .right .info .rank{ border-radius: 24px; width:36px; text-align: center; background-color: #fff; color: var(--blue100); font-weight: 600; margin-right: 12px;}
  .awardsTop .item .right .info .name{ font-size: 16px; font-weight: 700; margin-right: 12px;}
  .awardsTop .item .right .info .score{ font-size: 16px;}
  .awardsTop .item .right .btn{ width:130px; height: 48px; line-height: 48px; text-align: center; border-radius: 8px; background-color: var(--blue100); font-size: 16px; font-weight: 600; margin: 0 auto; margin-top: 24px; cursor: pointer;}
  .awardsTop .swiper-pagination-fraction {
    bottom: auto;
    top:8px;
    left: auto;
    right: 8px;
    width: 50px;
    border-radius: 28px;
    line-height: 28px;
    background-color: rgba(0,0,0,0.6);
    color: rgba(255,255,255,0.4);
    font-size: 12px;
  }
}

.awardsDetail{ padding: 20px 0 0 0;}
.awardsDetail .top{ display: flex; flex-wrap: wrap; align-items: end;}
.awardsDetail .top .stit{ width:100%; font-size: 16px; font-weight: 700; color: var(--blue100); line-height: 24px; margin-bottom: 8px;}
.awardsDetail .top .title{ font-size: 32px; line-height: 48px; font-weight: 700;}
.awardsDetail .top .date{ display: inline-flex; padding: 0 0 8px 10px; color: var(--gray70);}
.awardsDetail .top .date ul:nth-child(1){ position: relative; font-weight: 600; margin-right: 8px; padding-right: 8px;}
.awardsDetail .top .date ul:nth-child(1)::before{ content: ''; position: absolute; top:4px; right:0; height: 10px; width:1px; background-color: var(--gray30);}

.awardsDetail .top10{ display: flex; flex-wrap: wrap; margin: 24px 0 0 0;}
.awardsDetail .top10 ul{ position: relative; background-color: #fff; width:calc(20% - 14.8px); border: solid 1px var(--gray30); border-radius: 16px; overflow: hidden; margin: 0 0 16px 16px;}
.awardsDetail .top10 ul:nth-child(5n+1){ margin: 0 0 16px 0;}
.awardsDetail .top10 ul .rank{ position: absolute; top:8px; left: 8px; width:32px; height: 32px; border-radius: 8px; background-color: var(--gray120); color: #fff; font-size: 16px; font-weight: 700; text-align: center; line-height: 32px; z-index: 100;}
.awardsDetail .top10 ul:nth-child(1) .rank{ background-color: var(--blue100);}
.awardsDetail .top10 ul:nth-child(2) .rank{ background-color: var(--blue100);}
.awardsDetail .top10 ul:nth-child(3) .rank{ background-color: var(--blue100);}
.awardsDetail .top10 ul .thumb{ position: relative; height:226px; overflow: hidden;}
.awardsDetail .top10 ul .thumb img{position: absolute; top:0; left: 0; width:100%; height: 100%; object-fit: cover;}
.awardsDetail .top10 ul .info{ padding: 16px;}
.awardsDetail .top10 ul .info .cate{ font-size: 11px; font-weight: 500; line-height: 16px; color: var(--gray60);}
.awardsDetail .top10 ul .info .name{ font-size: 16px; font-weight: 700; line-height: 24px;}

@media(max-width:800px) {
  .awardsDetail{ padding: 20px 0 0 0;}
  .awardsDetail .top{ display: flex; flex-wrap: wrap; align-items: end; padding: 0 20px 0 20px;}
  .awardsDetail .top .stit{ width:100%; font-size: 13px; font-weight: 700; color: var(--blue100); line-height: 24px; margin-bottom: 0;}
  .awardsDetail .top .title{ font-size: 20px; line-height: 30px; font-weight: 700;}
  .awardsDetail .top .date{ display: inline-flex; padding: 0 0 4px 10px; color: var(--gray70); font-size: 13px;}
  .awardsDetail .top .date ul:nth-child(1){ position: relative; font-weight: 600; margin-right: 8px; padding-right: 8px;}
  .awardsDetail .top .date ul:nth-child(1)::before{ content: ''; position: absolute; top:4px; right:0; height: 10px; width:1px; background-color: var(--gray30);}

  .awardsDetail .top10{ display: flex; flex-wrap: wrap; margin: 16px 20px 0 20px;}
  .awardsDetail .top10 ul{ position: relative; background-color: #fff; width:calc(25% - 8px); border: solid 1px var(--gray30); border-radius: 16px; overflow: hidden; margin: 0 0 16px 8px;}
  .awardsDetail .top10 ul:nth-child(5n+1){ margin: 0 0 16px 8px;}
  .awardsDetail .top10 ul:nth-child(4n+1){ margin: 0 0 16px 0;}
  .awardsDetail .top10 ul .rank{ position: absolute; top:8px; left: 8px; width:32px; height: 32px; border-radius: 8px; background-color: var(--gray120); color: #fff; font-size: 16px; font-weight: 700; text-align: center; line-height: 32px; z-index: 100;}
  .awardsDetail .top10 ul .thumb{ position: relative; height:152px; overflow: hidden;}
  .awardsDetail .top10 ul .thumb img{position: absolute; top:0; left: 0; width:100%; height: 100%; object-fit: cover;}
  .awardsDetail .top10 ul .info{ padding: 16px;}
  .awardsDetail .top10 ul .info .cate{ font-size: 11px; font-weight: 500; line-height: 16px; color: var(--gray60);}
  .awardsDetail .top10 ul .info .name{ font-size: 14px; font-weight: 700; line-height: 21px;}

  .awardsDetail .chartWrap{ margin-top: 8px !important;}

}

@media(max-width:480px) {
  .awardsDetail .top .date ul:nth-child(1){ display: none;}
  .awardsDetail .top10 ul{ position: relative; background-color: #fff; width:calc(50% - 6px); border: solid 1px var(--gray30); border-radius: 16px; overflow: hidden; margin: 0 0 16px 8px;}
  .awardsDetail .top10 ul:nth-child(5n+1){ margin: 0 0 16px 8px;}
  .awardsDetail .top10 ul:nth-child(4n+1){ margin: 0 0 16px 8px;}
  .awardsDetail .top10 ul:nth-child(2n+1){ margin: 0 0 16px 0;}
}


.policy{ padding: 40px 0 0 0;}
.policy .title{ font-size: 32px; line-height: 48px; font-weight: 700;}
.policy .box ul{ margin-top: 32px;}
.policy .box ul li:nth-child(1){  font-size: 18px; line-height: 27px; font-weight: 700; color: var(--gray120); margin-bottom: 8px;}
.policy .box ul li:nth-child(2) dl{ display: flex; font-size: 16px; line-height: 28px; margin-bottom: 16px;}
.policy .box ul li:nth-child(2) dl span{ font-size: 11px; margin-right: 8px;}
.policy .box ul li:nth-child(2) dl:last-child{ margin-bottom: 0;}
.policy .box ul li:nth-child(2) dl dd{ width:100%;}
.policy .index{ padding: 32px; border: solid 1px var(--gray30); background-color: var(--gray10); border-radius: 16px; margin: 32px 0 0 0;}
.policy .index ul:nth-child(1){ font-size: 18px; line-height: 27px; font-weight: 700; color: #000;}
.policy .index ul li{ margin: 16px 0 0 0; font-size: 16px; cursor: pointer;}
.policy .index ul li:hover{ font-weight: 700; color: var(--blue100);}
.policy .stit{ font-size: 16px; font-weight: 700; padding: 0 0 8px 0;}
.policy .subtit{font-size: 15px; font-weight: 700; padding: 8px 0 0 0; color: var(--gray70);}
.policy table{ width:100%; padding: 0; margin: 0; border-collapse: collapse; font-size: 15px;}
.policy table{ border-top: solid 1px var(--gray30); border-left: solid 1px var(--gray30); margin: 16px 0 16px 0;}
.policy table tr td{ border-bottom: solid 1px var(--gray30); border-right: solid 1px var(--gray30); padding: 8px; vertical-align: top;}
.policy table tr.header td{ background-color: var(--gray20); text-align: center; font-weight: 700;}
.policy a{ text-decoration: underline; color: #000;}

@media(max-width:800px) {
  .policy{ padding: 20px 0 0 0; width:418px; margin: 0 auto;}
  .policy .title{ font-size: 24px; line-height: 36px; font-weight: 700;}
  .policy .box ul{ margin-top: 16px;}
  .policy .box ul li:nth-child(1){  font-size: 16px; line-height: 24px; font-weight: 600; color: var(--gray100); margin-bottom: 8px;}
  .policy .box ul li:nth-child(2) dl{ display: flex; font-size: 14px; line-height: 24px; margin-bottom: 16px; color: var(--gray100);}
  .policy .box ul li:nth-child(2) dl span{ font-size: 11px; margin-right: 8px;}
  .policy .box ul li:nth-child(2) dl:last-child{ margin-bottom: 0;}
  .policy .box ul li:nth-child(2) dl dd{ width:100%;}
}

@media(max-width:480px) {
  .policy{ padding: 20px 0 0 0; width:calc(100% - 40px); margin: 0 auto;}
}

.layerDefault{ position:fixed; display: flex; align-items: center; justify-content: center; top:0; left: 0; width:100%; height: 100%; background-color: rgba(0,0,0,0.6); z-index: 700;}
.layerDefault .inbox{ position: relative; background-color: #fff; border-radius: 16px; padding: 16px;}
.layerDefault .inbox .top{ line-height: 30px; font-size: 20px; font-weight: 700; padding-bottom: 24px;}
.layerDefault .inbox .close{ position: absolute; top:16px; right: 16px; width:32px; height: 32px; border-radius: 32px; background-color: var(--gray30); display: flex; align-items: center; justify-content: center; cursor: pointer;}
.layerDefault .inbox .bot{ display: flex; padding-top: 24px; background-color: #fff;}
.layerDefault .inbox .bot ul{ width:calc(50% - 8px); line-height: 56px; border-radius: 8px; background-color: var(--gray20); color: var(--gray90); font-size: 16px; font-weight: 600; text-align: center; cursor: pointer;}
.layerDefault .inbox .bot ul.confirm{ background-color: var(--blue100); color: #fff; margin-left: 16px;}

@media(max-width:480px) {
  .layerDefault .inbox{ position: relative; background-color: #fff; border-radius: 16px; padding: 16px; width:calc(100% - 72px) !important;}
}


.commentWrap{ margin: 40px 0 0 0;}
.commentWrap .totalCnt{ line-height: 28px; font-size: 18px; font-weight: 600; color: var(--gray100); margin-bottom: 12px;}
.commentWrap .totalCnt span{ color: var(--gray120);}
.commentWrap .writeBox{ }
.commentWrap .writeBox .loginFalse{display: flex; margin-bottom: 12px;}
.commentWrap .writeBox .loginFalse input{ line-height: 46px; border: solid 1px var(--gray40); border-radius: 8px; width:calc(50% - 6px); text-indent: 16px; font-weight: 500; color: var(--gray100);}
.commentWrap .writeBox .loginFalse input:focus{ border: solid 1px var(--blue100);}
.commentWrap .writeBox .loginFalse input::placeholder{ font-weight: 400; color: var(--gray50);}
.commentWrap .writeBox .loginFalse input:nth-child(1){ margin: 0 12px 0 0;}
.commentWrap .textareaBox{ border: solid 1px var(--gray40); border-radius: 8px; padding: 16px; background-color: #fff;}
.commentWrap .textareaBox.active{ border: solid 1px var(--blue100);}
.commentWrap .textareaBox .loginTrue{ font-size: 16px; line-height: 24px; font-weight: 600; margin-bottom: 8px;}
.commentWrap .textareaBox textarea{ border: 0; width:100%; min-height: 80px; resize: none; margin-bottom: 8px; line-height: 24px; font-size: 16px; font-family: 'Pretendard', sans-serif; outline:none;}
.commentWrap .textareaBox textarea::placeholder{ color: var(--gray50); white-space: pre-line; font-weight: 400;}
.commentWrap .textareaBox .bot{ display: flex; height: 40px; align-items: center;}
.commentWrap .textareaBox .bot ul.limit{ flex-grow: 1; text-align: right; padding: 0 16px 0 0; color: var(--gray50); font-size: 12px; font-weight: 500;}
.commentWrap .textareaBox .bot button{ width:66px; line-height: 40px; text-align: center; border: 0; border-radius: 8px; background-color: var(--blue100); margin-left: auto; font-weight: 600; color: #fff; font-family: 'Pretendard', sans-serif; cursor: pointer;}
.commentWrap .textareaBox .bot button:disabled{ background-color: var(--gray30); color: var(--gray50); cursor:default;}
.commentWrap .tab{ margin: 32px 0 0 0; display: flex; padding-bottom: 16px; border-bottom: solid 1px var(--gray120);}
.commentWrap .tab ul{ line-height: 24px; margin: 0 24px 0 0; font-size: 16px; font-weight: 600; color: var(--gray70); cursor: pointer;}
.commentWrap .tab ul.on{ color: var(--gray120);}
.commentWrap .commentList{ border-bottom: solid 1px var(--gray30);}
.commentWrap .commentList .none{ text-align: center; padding: 48px 0 48px 0; line-height: 21px; color: var(--gray70);}
.commentWrap .commentList .none img{ margin-bottom: 8px;}
.commentWrap .commentList .list{ position: relative; padding: 24px 0 24px 0; border-bottom: solid 1px var(--gray30);}
.commentWrap .commentList .list:last-child{ border-bottom: 0;}
.commentWrap .commentList .list .dep1{ position: relative;}
.commentWrap .commentList .list .dep2{ position: relative; padding: 0 16px 16px 16px; background-color: var(--gray10); margin: 20px 0 0 0; border-top: solid 1px var(--gray30);}
.commentWrap .commentList .list .dep2 .writeBox{ margin: 24px 0 0 0;}
.commentWrap .commentList .list .dep2 .inList{ position: relative; padding: 24px 0 45px 0; border-bottom: solid 1px var(--gray30);}
.commentWrap .commentList .list .dep2 .inList:last-child{ border-bottom: 0;}
.commentWrap .commentList .list .info{ display: inline-flex; line-height: 24px; align-items: center;}
.commentWrap .commentList .list .info .name{ font-weight: 600; color: #222;}
.commentWrap .commentList .list .info .date{ position: relative; color: var(--gray70); padding: 0 0 0 8px; margin: 0 0 0 8px;}
.commentWrap .commentList .list .info .date::before{ content: ''; position: absolute; top:6px; left: 0; width:1px; height: 12px; background-color: var(--gray30);}
.commentWrap .commentList .list .menu{ position: absolute; top:0; right: 0; cursor: pointer;}
.commentWrap .commentList .list .dep2 .inList .menu{ top:24px;}
.commentWrap .commentList .list .menu .menuLayer{ position: absolute; top:28px; right: 0; background-color: #fff; border: solid 1px var(--gray30); border-radius: 8px; padding: 2px; z-index: 100; box-shadow: 0 4px 12px -4px #10182808, 0 12px 20px -8px #10182814;}
.commentWrap .commentList .list .menu .menuLayer .btn{ width:58px; line-height: 36px; text-align: center; border-radius: 8px; color: var(--gray90)}
.commentWrap .commentList .list .menu .menuLayer .btn:hover{ background-color: var(--gray10);}
.commentWrap .commentList .list .cont{ padding: 16px 0 16px 0; font-size: 16px; line-height: 24px;}
.commentWrap .commentList .list .cont.block{ position: relative; color: var(--gray70); padding: 16px 0 16px 32px;}
.commentWrap .commentList .list .cont.block::before{ content: ''; position: absolute; top:16px; left: 0; width:24px; height: 24px; background: url("https://cdn.trend.rankify.best/dctrend/front/images/ico_info.svg");}
.commentWrap .commentList .list .reply{ display: inline-flex; align-items: center; line-height: 21px; font-weight: 600; color: var(--gray70); cursor: pointer;}
.commentWrap .commentList .list .reply span{ color: var(--gray90); margin-left: 4px;}
.commentWrap .commentList .list .reply .arw{ transform: rotate(90deg); margin-left: 2px;}
.commentWrap .commentList .list .reply .arw.open{transform: rotate(-90deg);}
.commentWrap .commentList .list .vote{ position: absolute; bottom: 0; right: 0; line-height: 21px; display: inline-flex; align-items: center;}
.commentWrap .commentList .list .dep2 .inList .vote{ bottom: 24px;}
.commentWrap .commentList .list .dep2 .noReplies{ text-align: center; color: var(--gray70); padding: 32px 0 0 0;}
.commentWrap .commentList .list .vote dl img{ margin: 0 8px 0 0; cursor: pointer;}
.commentWrap .commentList .list .vote dl.good{ color: var(--blue100); margin-right: 20px;}
.commentWrap .commentList .list .vote dl.bad{ color: var(--red90);}
.commentWrap .btnMore{ margin: 24px 0 0 0; text-align: center;}
.commentWrap .btnMore .in{ display: inline-flex; height: 48px; line-height: 48px; align-items: center; font-size: 16px; font-weight: 600; color: var(--gray90); cursor: pointer;}
.commentWrap .btnMore .in .arw{ transform: rotate(90deg); margin-left: 2px;}
.commentWrap .replyClose{ text-align: center; padding: 8px 0 0 0;}
.commentWrap .replyClose .in{ display: inline-flex; align-items: center; line-height: 36px; cursor: pointer;}
.commentWrap .replyClose .in .arw{ transform: rotate(-90deg); margin-left: 2px;}

.commentPassword{ width:360px; text-align: center; padding: 24px 0 24px 0;}
.commentPassword .tit{ font-size: 16px; line-height: 24px; color: var(--blue100); font-weight: 600; margin-bottom: 8px;}
.commentPassword .txt{ font-size: 16px; line-height: 24px; color: var(--gray100); margin-bottom: 24px;}
.commentPassword .inp{ position: relative; margin: 0 auto; width:308px; height: 50px;}
.commentPassword .inp img{ position: absolute; top:10px; left: 16px; width:24px;}
.commentPassword .inp input{ line-height: 48px; width:calc(100% - 2px); border:solid 1px var(--gray40); border-radius: 8px; text-indent: 46px; font-size: 16px; font-weight: 600;}
.commentPassword .inp input:focus{ border-color: var(--blue100);}
.commentPassword .inp input::placeholder{ color: var(--gray50); font-weight: 500;}

.commentNotify{ width:360px;}
.commentNotify .tit{ font-size: 16px; line-height: 24px; font-weight: 600; margin-bottom: 16px;}
.commentNotify .list textarea{ margin: 16px 0 0 0; padding: 16px; border: solid 1px var(--gray40); border-radius: 8px; resize: none; width:calc(100% - 34px); height: 100px; font-family: 'Pretendard', sans-serif; font-size: 16px; font-weight: 500; outline: none;}
.commentNotify .list textarea:focus{ border: solid 1px var(--blue100);}
.commentNotify .list textarea::placeholder{ color: var(--gray50); font-weight: 400;}
.commentNotify .list .limit{ text-align: right; font-size: 12px; line-height: 18px; color: var(--gray70); font-weight: 600; padding: 8px 0 0 0;}

.commentModifyIn{ width:360px;}
.commentModifyIn textarea{ margin: 0 0 0 0; padding: 16px; border: solid 1px var(--gray40); border-radius: 8px; resize: none; width:calc(100% - 34px); height: 100px; font-family: 'Pretendard', sans-serif; font-size: 16px; font-weight: 500; outline: none;}
.commentModifyIn textarea:focus{ border: solid 1px var(--blue100);}
.commentModifyIn textarea::placeholder{ color: var(--gray50); font-weight: 400;}
.commentModifyIn .limit{ text-align: right; font-size: 12px; line-height: 18px; color: var(--gray70); font-weight: 600; padding: 8px 0 0 0;}

@media(max-width:800px) {
  .commentWrap { margin: 40px 20px 0 20px;}
}
@media(max-width:480px) {
  .commentWrap .textareaBox textarea{ min-height: 120px;}
  .commentPassword{ width:100%;}
  .commentPassword .inp{ margin: 0 auto; width:calc(100% - 30px);}
  .commentNotify{ width:100%;}
}

.feedBack{ width:590px; margin: 0 auto; padding: 40px 0 0 0;}
.feedBack .title{ font-size: 32px; line-height: 48px; font-weight: 700;}
.feedBack .box{ background-color: #fff; border-radius: 16px; border: solid 1px var(--gray30); padding: 20px; margin: 24px 0 0 0;}
.feedBack .box.noTop{ margin: 16px 0 0 0;}
.feedBack .box .boxTitle{ font-size: 20px; line-height: 30px; font-weight: 600; margin-bottom: 16px;}
.feedBack .box .boxTitle.noBOt{ margin-bottom: 4px;}
.feedBack .box .formBd{}
.feedBack .box .formBd ul{ margin-bottom: 24px;}
.feedBack .box .formBd ul:last-child{ margin-bottom: 0;}
.feedBack .box .formBd ul .stit{ font-weight: 600; line-height: 21px; color: var(--gray70); margin-bottom: 8px;}
.feedBack .box .formBd ul input[type=text]{ width:calc(100% - 2px); line-height: 48px; border: solid 1px var(--gray40); border-radius: 8px; text-indent: 16px; font-size: 16px; font-weight: 500; color: var(--gray90);}
.feedBack .box .formBd ul input[type=text]::placeholder{ color: var(--gray50);}
.feedBack .box .formBd ul input[type=text]:focus{ border: solid 1px var(--blue100);}
.feedBack .box .formBd ul input[type=text]:disabled{ background-color: #fff;}
.feedBack .box .formBd .inpFile{ display: flex;}
.feedBack .box .formBd .inpFile .btnFile{ width:92px; line-height: 48px; border-radius: 8px; text-align: center; background-color: var(--gray20); color: var(--gray90); font-size: 16px; font-weight: 600; cursor: pointer; margin: 0 0 0 10px;}
.feedBack .box .formBd .inpFile input{ width:calc(100% - 106px) !important;}
.feedBack .box .tab{ display: flex; margin-bottom: 16px;}
.feedBack .box .tab ul{ width:calc(50% - 8px);}
.feedBack .box .tab ul:nth-child(1){ margin-right: 12px;}
.feedBack .box .tab ul input[type=radio]{ display: none;}
.feedBack .box .tab ul label{ display: flex; align-items: center; justify-content: center; height: 56px; text-align: center; border: solid 1px var(--gray40); width:calc(100% - 2px); border-radius: 8px; background-color: #fff; font-size: 16px; color: var(--gray70) !important; font-weight: 600; cursor: pointer;}
.feedBack .box .tab ul [type="radio"]:checked ~ label {background:var(--gray100); border: solid 1px var(--gray100); color: #fff !important;}
.feedBack .box .copyInfo{ text-align: right;}
.feedBack .box .infoText{ position: relative; font-size: 12px; font-weight: 500; color: var(--gray70); line-height: 18px; padding: 8px 0 0 20px;}
.feedBack .box .infoText::before{ content: ''; position: absolute; top:9px; left: 0; width:16px; height: 16px; background: url("https://cdn.trend.rankify.best/dctrend/front/images/ico_info.svg"); background-size: contain;}
.feedBack .box .addFileText{ line-height: 21px; color: var(--gray70);}
.feedBack .box .addFileArea{ background-color: var(--gray10); border: dashed 2px var(--gray30); border-radius: 8px; padding: 24px 0 24px 0; margin-top: 24px; cursor: pointer;}
.feedBack .box .addFileArea ul{ display: flex; align-items: center; width:100%; justify-content: center;}
.feedBack .box .addFileArea ul div{ margin-left: 4px;}
.feedBack .box .addFileArea ul:nth-child(1){ font-weight: 600; line-height: 24px; color: var(--gray90); font-size: 16px;}
.feedBack .box .addFileArea ul:nth-child(1) img{ margin-right: 8px;}
.feedBack .box .addFileArea ul:nth-child(2){ line-height: 21px; color: var(--gray70);}
.feedBack .box .addFileList{ margin: 16px 0 0 0;}
.feedBack .box .addFileList ul{ position:relative; line-height: 56px; border: solid 1px var(--gray30); border-radius: 8px; padding: 0 0 0 16px; margin-bottom: 8px; font-size: 16px; color: var(--gray70);}
.feedBack .box .addFileList ul:last-child{ margin-bottom: 0;}
.feedBack .box .addFileList ul li:nth-child(2){ position: absolute; top:16px; right: 16px; opacity: 0.5; cursor: pointer;}
.feedBack .termsTitle{ font-size: 20px; line-height: 30px; font-weight: 600; margin: 24px 0 0 0;}
.feedBack .box .terms{ font-size: 16px; line-height: 24px; color: var(--gray100);}
.feedBack .box .terms ul{ margin-bottom: 20px;}
.feedBack .box .terms ul:last-child{ margin-bottom: 0;}
.feedBack .box .terms .part li:nth-child(1){ font-size: 16px; line-height: 24px; font-weight: 600; color: var(--gray100); margin-bottom: 4px;}
.feedBack .box .terms .part li:nth-child(2) dl{ position: relative; margin-bottom: 8px; font-size: 14px; font-weight: 400; line-height: 21px; color: var(--gray90); padding: 0 0 0 11px;}
.feedBack .box .terms .part li:nth-child(2) dl::before{ content: ''; position: absolute; top:9px; left: 0; width:3px; height: 3px; border-radius: 3px; background-color: var(--gray40);}
.feedBack .box .terms .part li:nth-child(2) span{ font-weight: 600; margin-right: 8px;}
.feedBack .agree{ margin: 16px 0 64px 0;}
.feedBack .agree .req{ display: inline-table; font-size: 16px; line-height: 30px; color: var(--red90); margin-left: 8px;}
.feedBack .agree input[type=checkbox]{ width:18px; height: 18px; border: solid 1px var(--gray40) !important; border-radius: 6px; vertical-align: middle; margin: 0 8px 2px 0;}
.feedBack .agree label{ font-size: 16px; line-height: 24px; color: var(--gray90);}
.feedBack button{ height: 56px; border: 0; border-radius: 8px; background-color: var(--blue100); color: #fff; width:100%; font-family: 'Pretendard', sans-serif; font-weight: 600; font-size: 16px; cursor: pointer;}
.feedBack button:disabled{ background-color: var(--gray30); color: var(--gray50); cursor: default;}

@media(max-width:480px) {
  .feedBack{ width:calc(100% - 40px); margin: 0 auto; padding: 20px 20px 0 20px;}
  .feedBack .title{ font-size: 20px; line-height: 30px; font-weight: 700;}
  .feedBack .box{ background-color: #fff; border-radius: 16px; border: solid 1px var(--gray30); padding: 20px; margin: 16px 0 0 0;}
  .feedBack .box .boxTitle{ font-size: 18px; line-height: 27px; font-weight: 600; margin-bottom: 16px;}
  .feedBack .box .tab{ display: flex; margin-bottom: 16px;}
  .feedBack .box .tab ul{ width:calc(50% - 8px);}
  .feedBack .box .tab ul:nth-child(1){ margin-right: 12px;}
  .feedBack .box .tab ul input[type=radio]{ display: none;}
  .feedBack .box .tab ul label{ display: flex; flex-wrap: wrap; align-items: center; justify-content: center; height: 56px; text-align: center; border: solid 1px var(--gray40); width:calc(100% - 2px); border-radius: 8px; background-color: #fff; font-size: 16px; color: var(--gray70) !important; font-weight: 600; cursor: pointer;}
  .feedBack .box .tab ul:nth-child(2) label{ padding-top: 10px; height: 46px;}
  .feedBack .box .tab ul label div{ width:100%; font-size: 12px; margin-bottom: 10px;}
  .feedBack .box .addFileArea{ background-color: var(--gray10); border: dashed 2px var(--gray30); border-radius: 8px; padding: 24px 0 24px 0; margin-top: 24px; cursor: pointer;}
  .feedBack .box .addFileArea ul{ display: flex; flex-wrap: wrap; align-items: center; width:100%; justify-content: center;}
  .feedBack .box .addFileArea ul div{ margin-left: 4px;}
  .feedBack .box .addFileArea ul:nth-child(1){ font-weight: 600; line-height: 24px; color: var(--gray90); font-size: 16px;}
  .feedBack .box .addFileArea ul:nth-child(1) img{ margin-right: 8px;}
  .feedBack .box .addFileArea ul:nth-child(2){ font-size: 12px; line-height: 18px; color: var(--gray70); padding: 8px 0 0 0;}
  .feedBack .agree{ margin: 16px 0 40px 0;}
}

.errNewsDetail{ width:300px; margin: 0 auto; padding: 80px 0 0 0; text-align: center;}
.errNewsDetail .title{ font-size: 24px; font-weight: 700; padding: 0 0 8px 0; color: var(--gray100);}
.errNewsDetail .txt{ font-size: 16px; line-height: 21px; color: var(--gray70); margin-bottom: 24px}
.errNewsDetail .btn{ width:100px; line-height: 46px; border-radius: 8px; background-color: var(--blue100); color: #fff; margin: 0 auto; cursor: pointer;}

@media(max-width:480px) {
  .errNewsDetail .img{ width:200px; margin: 0 auto;}
  .errNewsDetail .title{ font-size: 20px; font-weight: 700; padding: 0 0 8px 0; color: var(--gray100);}
  .errNewsDetail .txt{ font-size: 14px; line-height: 16px; color: var(--gray70); margin-bottom: 24px}
}






























